export const APPLICATION_USER_ROLES = {
  REQUESTER: "Requestor",
  INSURER: "Insured",
  AGENT: "Agent",
  CLIENT: "Client",
  APP: "App"
};
export const MULTI_AGENTS = {
  API_TYPES: {
    REQUEST_URL: "REQUEST_URL",
    REQUEST_DATA: "REQUEST_DATA",
    REQUEST_PARAMS: "REQUEST_PARAMS",
    REQUEST_QUERY: "REQUEST_QUERY",
    REQUEST_URL_DELETE_GROUP: "REQUEST_URL_DELETE_GROUP"
  }
};

export const APPLICATION_MODULES = {
  MY_POLICIES: {
    TITLE: "My Policies"
  },
  TEMPLATES: {
    TITLE: "Policy Templates"
  },
  MY_PENDING_USERS: {
    TITLE: "My Pending Users"
  }
};
export const ACTIVITY_LOG = {
  modalTitle: 'Activity Log'
}

export const DISABLE_LIMIT_TYPES_IN_ADD_EDIT = ['SF/RC']

export const CERTIFICIAL = {
  DEFAULT_STRING_PROJECT: " (Default)",
  IDLE_SESSION_TIMEOUT: 1000 * 60,
  MAX_DATE: "12-31-9999",
  ADDRESS_SEARCH_API: "https://us-autocomplete-pro.api.smarty.com/lookup",
  DEBOUNCE_DELAY: 750,
  MAX_YEAR: 9999,
  MAX_AGENT_SUPPORT: 5,
  EXTENDED_DRAWER_SIZE: "95%",
  PRIMARY_DRAWER_SIZE: "70%",
  SECONDARY_DRAWER_SIZE: "50%",
  MIDDLE_DRAWER_SIZE: "45%",
  SMALL_DRAWER_SIZE: "35%",
  MCS90_COVERAGE_TYPE: "MCS-90",
  STOP_GAP_COVERAGE_TYPE: "stop_gap",
  COVERAGE_CATEGORY: {
    GENERAL_AGGREGATE_LIMIT: ["applies_per_policy", "applies_per_project", "applies_per_location", "applies_per_other"],
    COVERAGE_TYPE: ["stop_gap", "occurrence", "other", "other 2", "claims_made"]
  },
  EXCESS_COVERAGE_TYPE: "Excess Coverage",
  MULTI_AGENTS: {
    ONLY_AGENT: "ONLY_AGENT",
    ALL_ATTACHEMENTS: "ENABLE_GROUPING_BY_NAME"
  },
  CLIENT_TYPES: {
    COMPANY: "COMPANY",
    AGENT: "AGENT",
    AGENCY: "AGENCY"
  },
  AUTO_TRANSFER: {
    MY_CLINETS: "My Clients",
    AGENCYS_CLIENT: "Agency’s Clients",
    MY_OPEN_REQUESTS: "My Open Requests",
    AGENCYS_OPEN_REQUESTS: "Agency’s Open Requests"
  },
  AGENT_VIEW: {
    _DASHBOARD: "DASHBOARD",
    CLIENTS: "CLIENTS",
    DASHBOARD: "AgentDashboard",
    AGENT_POLICY_LIST: "AgentPolicyList",
    AGENT_ISSUE_COI: "AgentIssueCOI",
    AGENT_PENDING_POLICY_SHARE: "AgentPendingPolicyShare",
    AGENT_PENDING_POLICY_REQUEST: "AgentPendingPolicyRequest"
  },
  SHARE_VIEW: {
    DASHBOARD: "ShareDashboard",
    MY_POLICY_LIST: "MyPolicyList",
    POLICY_RESPOND_SUMMARY: "PolicyRespondSummary",
    TEMPLATE_POLICY_LIST: "TemplatePolicyList",
    POLICY_SHARE_SUMMARY: "PolicyShareSummary",
    SMART_COI: "SmartCoi",
    RESPOND: "respond",
    RERESPOND: "rerespond"
  },
  REQUESTOR_VIEW: {
    DASHBOARD: "RequestorDashboard",
    TRACKED_COMPANY: "company",
    REQUEST_COVERAGE:"requestCoverage"
  },

  COVERAGE_SYMBOL_OTHER_TYPES: [
    "other",
    "applies_per_other",
    "other_ 2",
    "other_ 1",
    "option_#",
    "other 2"
  ],

  AUTOCOMPLETES: {
    EMAIL: "EMAIL",
    COMPANY: "COMPANY",
    INSURERS: "INSURERS",
    AGENCIES: "AGENCIES",
    EMAIL_SHARE: "EMAIL_SHARE",
    POLICY_TYPES: "POLICY_TYPES",
    COMPANY_SHARE: "COMPANY_SHARE",
    COVERAGE_TYPES: "COVERAGE_TYPES",
    ENROLLMENT_COMPANY: "ENROLLMENT_COMPANY",
    SEARCH_IN_ADDRESSBOOK: "SEARCH_IN_ADDRESSBOOK"
  },
  APP_FORMAT: {
    DATE: "l",
    DATE_LONG: "lll",
    CURRENCY: "$",
    CURRENCY_NAME: "USD",
    COUNTRY: "US",
    MM_DD_YYYY: "MM-DD-YYYY",
    MOMENT_MMDDYYYY: "MM[-]DD[-]YYYY",
    TIME_STAMP: "YYYY-MM-DD HH:mm:ss",
    M_D_YYYY: "M/D/YYYY",
    DATE_THRESHOLD: "01-01-2000"
  },
  FORMATS: [
    {
      DATE: "l",
      CURRENCY: "$",
      CURRENCY_NAME: "USD",
      COUNTRY: "US"
    },
    {
      DATE: "l",
      CURRENCY: "$",
      CURRENCY_NAME: "CAD",
      COUNTRY: "CA"
    }
  ],
  EXPORT_EXCEL: {
    FILE_TYPE:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    FILE_NAME_SUFFIX: {
      AGENT: "_Agents in Certificial",
      USER: "_Users in Certificial"
    },
    EXTENSION: ".xlsx",
    SHEET_NAME: { AGENT: "Agent list", USER: "User List" }
  },
  UPLOAD_FILES: {
    ALL_SUPPORTED_FILE_EXTENTIONS: ".jpg, .jpeg, .png, .pdf ",
    ONLY_SIGN_SUPPORTED_FILE_EXTENTIONS: ".jpg, .jpeg, .png",
    ONLY_XLSX_SUPPORTED_FILE_EXTENSIONS: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ".xlsx",
      ".xls"
    ],
    ONLY_CSV_SUPPORTED_FILE_EXTENSIONS: ["text/csv", ".csv", "application/vnd.ms-excel"],
    ONLY_IMAGE_SUPPORTED_FILE_EXTENTIONS: "image/*",
    ONLY_PDF_SUPPORTED_FILE_EXTENTIONS: ".pdf ",
    UPLOAD_FILES_PDF_CHECK: [".pdf", "application/pdf"],
    UPLOAD_FILES_SUPPORT_CHECK: ["image/png", "image/jpeg", "application/pdf"],
    UPLOAD_SIGN_SUPPORT_CHECK: ["image/png", "image/jpeg", "image/jpg"],
    MAX_SIZE: 20480000,
    MAX_SIZE_MESSAGE: " 20 MB"
  },
  FAX_NO_MAX_LENGTH: 15,
  FAX_NUMBER_VALIDATION: [
    {
      required: false,
      whitespace: true,
      message: "Please enter Fax Number"
    },
    { max: 15, message: 'Fax Number cannot be longer than 15 characters!' },
  ],
  ADDRESS_BOOK_AMS_TYPES: {
    APPLIED_EPIC: {
      TEXT: "Applied Epic",
      DISABLED: false,
      VALUE: "Applied Epic"
    },
    HAWKSOFT: { TEXT: "HawkSoft", DISABLED: false, VALUE: "HawkSoft" },
    NOVIDEA: { TEXT: "Novidea", DISABLED: false, VALUE: "Novidea" },
    VERTAFOREAMS: {
      TEXT: "Vertafore AMS360",
      DISABLED: false,
      VALUE: "Vertafore AMS360"
    },
    SAGITTA: {
      TEXT: "Vertafore Sagitta",
      DISABLED: false,
      VALUE: "Vertafore Sagitta"
    },
    X_DIMENSIONAL: {
      TEXT: "XDimensional",
      DISABLED: false,
      VALUE: "XDimensional"
    },
    OTHER: { TEXT: "Other", DISABLED: false, VALUE: "Other" }
  },
  PROCESS_STEPS: {
    SMART_COI: {
      PROCESS_COI: "processCoi",
      EXTRACTION_STATUS: "extractionStatus",
      VERIFY_POLICIES: "verifyPolicies",
      UPLOAD_SIGNATURE: "uploadSignature"
    },
    BULK_SHARE: [
      {
        title: "Select Policy Details",
        description: "",
        key: "selectPolicyDetails"
      },
      {
        title: "Select Templates / Holders",
        description: "",
        key: "selectCertHolder"
      }
    ],
    SELECT_LOCATION: {
      title: "Select Locations",
      key: "selectLocations"
    },

    SELECT_VEHICLE: {
      title: "Select Vehicles",
      key: "selectVehicles"
    },
    CREATE_SIGNATURE: {
      title: "Create Signature",
      key: "esign",
    },
    ADD_NEW_POLICY: Object.freeze([
      {
        title: "Select Policy Type",
        key: "selectPolicyType"
      },
      {
        title: "Enter Policy Details",
        key: "enterPolicyData"
      }
    ]),
    ADD_NEW_PROPERTY_POLICY: Object.freeze([
      {
        title: "Select Policy Type",
        key: "selectPolicyType"
      },
      {
        title: "Enter Policy Details",
        key: "enterPolicyData"
      },
      {
        title: "Location Limits",
        key: "locationLimit"
      }
    ]),
    SHARE_MY_POLICY: [
      {
        title: "Selection",
        description: ""
      },
      {
        title: "My Policies",
        description: ""
      },
      {
        title: "Remarks",
        description: ""
      },
      {
        title: "Cert Holder Info",
        description: ""
      },
      {
        title: "Summary",
        description: ""
      }
    ],
    AGENT_SHARE_MY_POLICY: [
      {
        title: "Selection",
        description: ""
      },
      {
        title: "Policies",
        description: ""
      },
      {
        title: "Remarks",
        description: ""
      },
      {
        title: "Cert Holder Info",
        description: ""
      },
      {
        title: "Summary",
        description: ""
      }
    ],
    VERIFICATION_REQUEST: [
      {
        title: "Policy Request",
        description: ""
      },
      {
        title: "Summary",
        description: ""
      }
    ],


    RESPOND_OPT_OUT: {
      END_DOC_UTILITY: {
        title: "Endorsement Document Utility",
        description: "",
        key: "endorsementDocumentUtility"
      },
      UPLOAD_COI: {
        title: "Certificate Upload",
        description: "",
        key: "certificateUpload"
      }
    },

    SMART_COI_STEP: {
      title: "Smart COI",
      description: "",
      key: "smartCoi"
    },

    RESPOND_POLICY: [
      {
        title: "Policy Selection",
        description: "",
        key: "policySelection"
      },
      {
        title: "Remarks",
        description: "",
        key: "remarks"
      },
      {
        title: "Additional Documents",
        description: "",
        key: "additionalDocuments"
      },
      {
        title: "Cert Holder Info",
        description: "",
        key: "certHolderInfo"
      },
      {
        title: "Summary",
        description: "",
        key: "summary"
      }
    ],
    REPLACE_POLICY: [
      {
        title: "Replace Policy",
        key: "selectSubstitutePolicy"
      }
    ]
  },

  LOCATION_ACTIONS: {
    VIEW: {
      ACTION: "view",
      TITLE: "View",
      ICON: "export",
      BACKGROUND: "#3596db"
    },
    EDIT: {
      ACTION: "edit",
      TITLE: "Edit",
      ICON: "form",
      BACKGROUND: "purple"
    },
    DELETE: {
      ACTION: "delete",
      TITLE: "Delete",
      ICON: "delete",
      BACKGROUND: "red"
    }
  },
  POLICY_ACTIONS: {
    VIEW: {
      ACTION: "view",
      TITLE: "View",
      ICON: "export",
      BACKGROUND: "#3596db"
    },
    EDIT: {
      ACTION: "edit",
      TITLE: "Edit",
      ICON: "form",
      BACKGROUND: "purple"
    },
    DELETE: {
      ACTION: "delete",
      TITLE: "Delete",
      ICON: "delete",
      BACKGROUND: "red"
    },
    CANCEL: {
      ACTION: "cancel",
      TITLE: "Cancel",
      ICON: "close-circle",
      BACKGROUND: "red"
    },
    REPLACE: {
      ACTION: "replace",
      TITLE: "Replace",
      ICON: "sync",
      BACKGROUND: "purple",
      REPLACE_IMMEDIATELY: "Immediately",
      REPLACE_UPON_EXPIRATION: "Upon Expiration"
    },
    BULK_SHARE: {
      ACTION: "bulk_share",
      TITLE: "Bulk Share",
      ICON: "share-alt",
      BACKGROUND: "purple"
    },
    APPROVE: {
      ACTION: "approve",
      TITLE: "Verify",
      ICON: "check-circle",
      BACKGROUND: "#49ad17"
    },
    REJECT: {
      ACTION: "reject",
      TITLE: "Reject",
      ICON: "close-circle",
      BACKGROUND: "red"
    },
    CANCEL_TRACKING: {
      ACTION: "cancel_tracking",
      TITLE: "Cancel Tracking",
      ICON: "close-circle",
      BACKGROUND: "red"
    },
    HANDLE_EXCEPTION: {
      ACTION: "handle_exception",
      TITLE: "Exception Handling",
      ICON: "handle-exception",
      BACKGROUND: "blue"
    },
    DOC_UPLOAD_HISTORY: {
      ACTION: "doc_upload_history",
      TITLE: "Manage My Documents",
      ICON: "share-alt",
      BACKGROUND: "blue"
    },
    ALL_INSURED: {
      ACTION: "all_insured",
      TITLE: "View Insureds",
      ICON: "note",
      BACKGROUND: "blue"
    },
    DELETE_SHARE: {
      ACTION: "delete_share",
      TITLE: "Delete Share",
      ICON: "delete",
      BACKGROUND: "red"
    },
    ACTIVE: {
      ACTION: "approve",
      TITLE: "Activate",
      ICON: "check-circle",
      BACKGROUND: "#49ad17"
    },
    INACTIVE: {
      ACTION: "reject",
      TITLE: "Inactive",
      ICON: "close-circle",
      BACKGROUND: "red"
    }
  },
  MAINTAINANCE: {
    FROM: "2020-07-19 15:00",
    TO: "2020-07-20 08:30"
  },
  PAGINATION: {
    PAGE_SIZE: 50,
    PAGE_SIZE_20: 20,
    PAGE_SIZE_10: 10,
    PAGE_SIZE_5: 5
  },
  ADD_CLIENT_SEARCH_CRITERIAS: [
    {
      key: "requestId",
      label: "Request ID",
      placeHolder: "Policy Request ID",
      rules: [
        {
          pattern: new RegExp(/^[0-9]*$/g),
          message: "Policy Request ID must be a number"
        },
        {
          required: true,
          whitespace: true,
          message: "Please enter Policy Request ID to search"
        }
      ]
    },
    {
      key: "taxId",
      label: "Tax ID",
      placeHolder: "Tax ID (Sample: XX-XXXXXXX)",
      rules: [
        {
          required: true,
          whitespace: true,
          message: "Please enter Tax ID."
        }
      ]
    },
    {
      key: "email",
      label: "Email",
      placeHolder: "Email",
      rules: [
        {
          required: true,
          whitespace: true,
          message: "Please enter Email."
        }
      ]
    },

    {
      key: "companyName",
      label: "Company Name",
      placeHolder: "Company Name",
      rules: null
    }
  ],
  COI_ID_SEARCH_CRITERIAS: [
    {
      key: "coiID",
      label: "COI ID",
      placeHolder: "COI ID",
      rules: [
        {
          required: true,
          whitespace: true,
          message: "Please enter COI ID."
        }
      ]
    },
  ],
  REGEX_PATTERNS: {
    EMAIL: new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
    STANDARD_EMAIL_FOR_ZOHO: new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  },
  SUPPORT_EMAIL_ADDRESS: "support@certificial.com",
  MAILTO_SUPPORT_EMAIL_ADDRESS: "mailto:support@certificial.com",
  UPGRADE_PLANS: {
    COMPANY_PLANS: [],
    REQUESTOR_VERIFICATION_SUBSCRIPTION_DETAILS: [
      {
        id: 1,
        name: "Enroll in Vendor Management Program",
        value: "For 12 Months"
      },
      {
        id: 4,
        name: "Continuous Monitoring Eligible",
        value: "true"
      }
    ]
  }
};

export const GENERATE_COI_CONST = {
  DASHBOARD_TYPE: {
    REQUESTER: "requestor",
    INSURER: "insured",
    AGENT: 'agent'
  },
  SHARE_TYPE: {
    POLICY: "Policies",
    POLICY_SHARE: "PolicyShare",
    POLICY_REQUEST: "PolicyRequest"
  }
};

export const DOCUMENTS_TYPE = {
  COI_DOC: 1,
  ISO_DOC: 2,
  ENDORSMENT_DOC: 3,
  ADDITONAL_DOC: 4,
  POLICY_DOC: 5
};

export const CERTIFICIAL_STATUS = {
  USER_STATUS: {
    ACTIVE: "Active",
    PENDING: "Pending Approval",
    INVITED: "Invited"
  },
  VERIFICATION_STATUS: {
    ACCEPTED: "Accepted",
    REQUESTED: "Requested",
    APPROVED: "Approved",
    PROCESSING: "processing",
    REVIEWED: "Reviewed"
  },
  VERIFICATION_STATUS_BLOCK: {
    ACCEPTED: "ACCEPTED",
    REQUESTED: "REQUESTED",
    APPROVED: "APPROVED",
    PROCESSING: "PROCESSING"
  },
  LIST_ITEM_STATUS: {
    AGENT_APPROVAL_PENDING: "Agent Approval Pending",
    INSURED_APPROVAL_PENDING: "Insured Approval Pending",
    ACTION_REQUIRED: "Action Required",
    DECLINED: "Declined",
    UPGRADE: "Upgrade",
    REQUESTED: "Requested",
    PENDING: "Pending",
    REJECTED: "Rejected",
    COMPLIANCE_ISSUE: "Compliance Issue",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    UNVERIFIED: "Unverified",
    IN_PROCESS: "In Process",
    REQUIRES_REVIEW: "Requires Review",
    PROCESSED: "Processed"
  },
  POLICY_STATUS: {
    VERIFIED: "Verified",
    IN_REVIEW: "In Review",
    EXPIRED: "Expired",
    EXPIRING: "Expiring",
    CANCELED: "Canceled",
    COMPLIANCE: "Compliance",
    IN_PROCESS: "In Process",
    IN_PROGRESS: "In Progress",
    PROCESSING: "Processing",
    REVIEWED: "Reviewed",
    REQUIRES_REVIEW: "Requires Review",
    COMPLIANT: "Compliant",
    NON_COMPLIANT: "Non Compliant",
    HYPHEN_NON_COMPLIANT: "Non-Compliant",
    AWAITING_ENROLLEMENT_FEES: "Awaiting Enrollment Fees",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    UNVERIFIED: "Unverified",
    PENDING_CANCELLATION: "Pending Cancellation"
  },
  COMPANY_STATUS: {
    INACTIVE: "Inactive",
    CANCELED: "Canceled",
    UNVERIFIED: "Unverified",
    ACTIVE: "Active",
    EXPIRED: "Expired",
    IN_REVIEW: "In Review",
    EXPIRING: "Expiring",
    PROCESSING: "Processing",
    REQUIRES_REVIEW: "Requires Review",
    COMPLIANT: "Compliant",
    HYPHEN_NON_COMPLIANT: "Non-Compliant",
    AWAITING_ENROLLEMENT_FEES: "Awaiting Enrollment Fees",
    PENDING_ACTIVATION: "Pending Activation"
  },
  CLIENT_STATUS: {
    EXPIRED: "Expired",
    CANCELED: "Canceled",
    NON_COMPLIANT: "Non-Compliant",
    UNVERIFIED: "Unverified",
    INACTIVE: "Inactive",
    EXPIRING: "Expiring",
    ARCHIVED: "Archived"
  },
  CLIENT_STATUS_VALUE: {
    EXPIRED: 2,
    CANCELED: 1,
    NON_COMPLIANT: 3,
    UNVERIFIED: 4,
    INACTIVE: 5,
    EXPIRING: 6,
    ARCHIVED: 7
  },
  SHARE_TYPE_VALUE: {
    PRINT_ONLY: 1,
    NON_PRINT_ONLY: 0
  },
  SHARE_TYPE: [
    { label: "Contains Email Address", value: 2 },
    { label: "Missing Email Address", value: 1 }
  ],
  TRANSACTION_STATUS: {
    RESPONDED: 'Responded',
    CANCELLED: 'Canceled',
    EMPTY_POLICY_RESPONDED: 'Empty policy responded'
  },

  MERGE_CLIENTS: {
    SUCCESS: 'COMPLETED',
    FAILED: 'FAILED'
  }

};

export const FILTERS_TYPE = {
  LABELS: {
    AGENT: "Agent",
    POLICIES_EXPIRING: "Policies Expiring",
    POLICIES_BY_STATUS: "Policies by Status",
    SHARE_TYPE: "Requestor Email Address",
    POLICIES_BY_COMPANY_RECORD: "Policies by Company Record",
    POLICIES_BY_COVERAGE_TEMPLATE: "Coverage Template",
    POLICIES_BY_DOO: "Description of Operations",
    NAMED_INSURED: "Named Insured",
    POLICIES_BY_ENDORSEMENTS: "Policies by Endorsements",
    COMPANY_STATUS: "Company Status",
    COMPLIANCE_REVIEW: "Compliance Review",
    POLICY_TYPE: "Policy Type",
    TEMPLATE: "Template",
    CLIENT_STATUS: "Client Status",
    PROJECT_NAME: "Project Name",
    AGENT_ASSIGNED:"Agent Assigned",
    AGENT_COMMUNICATION: "Agent Communications"
  },
  BTN: {
    RESET_ALL_FILTERS: "Reset All Filters"
  },
  POLICIES_BY_ENDORSEMENTS: {
    ADDITIONAL_INSURED: "Additional Insured",
    WAIVER_OF_SUBROGATION: "Waiver of Subrogation",
    LOSS_PAYEE: "Loss Payee",
    NON_CONTRIBUTORY: "Non-Contributory"
  },
  COMPLIANCE_REVIEW: {
    COMPLETE: "Complete",
    PENDING: "Pending"
  },

  AGENT_ASSIGNED : {
    AGENT_ASSIGED:"Agent Assigned",
    NO_AGENT_ASSIGNED:"No Agent Assigned"
  },
  POLICIES_EXPIRY_DURATION: {
    _30: "30",
    _60: "60",
    _90: "90"
  },
  POLICIES_BY_DOO_OPTIONS: [{
    label: "Shares with custom DOO",
    value: "CUSTOM"
  }, {
    label: "Share with Cov Template DOO",
    value: "COVERAGE_TEMPLATE"
  }]
};

export const INERCEPT_QUERY_PARAM_LIST = [
  "name",
  "query",
  "companyName",
  "keyword"
];

export const VALIDATE_FIELDS = {
  AGENCY_NUMBER: [{
    max: 50,
    message:
      "Agent License number should not be greater than 50 char."
  }],
  VEHICLE_VIN: {
    min: 17,
    max: 30
  },
  FIRST_NAME: [
    {
      pattern: /^.{1,30}$/,
      message: "First name should be max 30 chars long."
    },
    {
      pattern: /^[^<>`]*$/,
      message: "Please enter a valid name."
    }
  ],
  LAST_NAME: [
    {
      pattern: /^.{1,30}$/,
      message: "Last name should be max 30 chars long."
    },
    {
      pattern: /^[^<>`]*$/,
      message: "Please enter a valid name."
    }
  ]
}

export const CERTIFICIAL_COLOR_SCHEME = {
  DEFAULT: {
    PRIMARY: "#3596db",
    SECONDARY: "#46b6f2",
    SUCCESS: "#52c41a",
    DANGER: "#f5222d",
    ORANGE_RED: "#fa541c",
    SALMON: "#da0a15",
    FIREBRICK: "#f5222d",
    WARNING: "#faad14",
    DODGER_BLUE: "#096dd9",
    HEADING_COLOR: "rgba(0, 0, 0, 0.85)",
    TEXT_COLOR: "rgba(0, 0, 0, 0.65)",
    TEXT_COLOR_SECONDARY: "rgba(0, 0, 0, 0.45)"
  }
};

export const CERTIFICIAL_POLICY_TYPES = {
  AUTOMOBILE_LIABILITY: "Automobile Liability",
  WORKERS_COMPENSATION_AND_EMPLOYEES_LIABILITY:
    "Workers Compensation and Employers' Liability",
  PROPERTY_POLICY: "Property", // For property
  INLAND_MARINE: "Inland Marine",
  BOILER: "Boiler & Machinery / Equipment Breakdown",
  CRIME: "Crime",
  INSTALLATION: "Installation / Builder's Risk",
  COMMERCIAL_GENERAL_LIABILITY: "Commercial General Liability"
};

export const CERTIFICIAL_POLICY_CODE = {
  PROPERTY: "PROP",
  INSTALLATION: "BLDRK",
  AUTOMOBILE_LIABILITY: "AUTOB",
  WORKERS_COMPENSATION_AND_EMPLOYEES_LIABILITY: "WORK"
}
export const CERTIFICIAL_COUNTRY_CODES = {
  US: "US"
};

export const COUNTRY_NAME = {
  USA: "United States of America"
};

export const PROPERTY_LIMITS_KINDS = {
  BLANKET: "BLANKETLIMIT",
  POLICY_LIMIT: "POLICYLIMIT",
  LOCATION: "LOCATION"
};

export const LOCATION_ACTIONS = {
  ADD_BUILDING: {
    ACTION: "Add Building",
    TITLE: "Add Building",
    ICON: "form",
    BACKGROUND: "purple"
  },
  REMOVE: {
    ACTION: "Remove",
    TITLE: "Remove",
    ICON: "delete",
    BACKGROUND: "purple"
  }
};

export const PROPERTY_INITIAL_STATUS = {
  CREATE: { STEP_ONE: "Incomplete", STEP_TWO: "finalSubmit" },
  EDIT: { STEP_ONE: "Incomplete", STEP_TWO: "finalSubmit" }
};

export const SELECT_ALL_CHECKED_BY = {
  USER: "USER",
  SYSTEM: "SYSTEM",
  EMPTY_STRING: "",
};

export const FUTURE_PROPERTY_LOCATION_MESSAGE = "Automatically add future locations";
export const FUTURE_VEHICLES_CHECKBOX_MESSAGE = "Automatically add future vehicles";


export const ACCORD = {
  ACCORD24: "ACORD 24",
  ACCORD25: "ACORD 25",
  ACCORD27: "ACORD 27",
  ACCORD28: "ACORD 28",
};

export const ACCORD_MAPPING = {
  'Accord24': 'ACORD 24',
  'Accord25': 'ACORD 25',
  'Accord27': 'ACORD 27',
  'Accord28': 'ACORD 28'
}

export const NO_PREFERENCE_ACORD_FORM = "No Preference";
export const PREVIEW_COI_LABEL = {
  LABEL_24: "Other LOBs - ACORD 24",
  LABEL_PROPERTY: "Commercial Property",
  LABEL_25: "Liability Insurance - ACORD 25",
  LABEL_INSTALLATION: "Installation / Builder's Risk"
};

export const PREVIEW_COI_CANADA_CLICK_TYPE = {
  CANADA_24_PROPERTY: "CANADA_24_PROPERTY",
  CANADA_ALL_OTHERS: "CANADA_ALL_OTHERS",
};

export const REGION_ID = {
  CANADA: 2,
  US: 1,
};

export const PREVIEW_COI_LABEL_CANADA = {
  LABEL_24_PROPERTY: "CSIO - Property Insurance",
  LABEL_ALL_OTHERS: "CSIO - Liability Insurance",
};

export const CLICKED_TYPE = {
  TYPE_24: "TYPE_24",
  TYPE_25: "TYPE_25",
  TYPE_PROPERTY: "TYPE_PROPERTY",
  TYPE_INSTALLATION: "TYPE_INSTALLATION",
};

export const CLICK_TYPE_MAPPING = {
  'ACORD 24':'TYPE_24',
  'ACORD 25':'TYPE_25',
  'ACORD 27': 'TYPE_PROPERTY',
  'ACORD 28': 'TYPE_PROPERTY'

}
export const DISPLAY_LIMIT_DISABLED = ['SIR', 'Deductible', 'Retention', 'Collision Deductible', 'Comprehensive Deductible', 'Reefer Breakdown Deductible', 'Forms Deductible1'];

export const DEFAULT_VALUE_FOR_ACCORD = {
  DROPDOWN_FORM_NUMBER_24: "24",
  DROPDOWN_FORM_NUMBER_25: "25",
  DROPDOWN_FORM_NUMBER_27: "27",
  DROPDOWN_FORM_NUMBER_28: "28",
};

export const POLICY_TYPES = {
  PROPERTY: 'Property',
  AUTO: 'Automobile Liability'
};


export const CERT_REQUEST_PRIORITY = {
  STANDARD:'Standard',
  RUSH:'Rush',
  PRIORITY:'Priority'
}


export const EVENT_SOURCE_EVNAME = {
  GUI_ID: "GUI_ID"
}

export const MANAGE_PROJECT = {
  PAGE_SIZE: 10
}

export const ATTACHMENT_LABEL = {
  REQUESTED_DOC: {
    TOOLTIP_TEXT: "Requested Documents",
    EMPTY_DATA_TEXT: "No documents requested"
  }
}

export const INSURED_TYPE = {
  NAMED:'Named Insured',
  ADDITINAL:'Additional Insured'
}

export const SANITIZATION_FIELDS = {
  addPolicy: {
    "removeHTML": ['doo'],
    "encodeHTML": []
  },
  updateProfile: {
    "removeHTML": ["firstName", "lastName", "email", "phone", "address", "address2", "userCountry", "userState", "city", "postalCode", "companyName", "companyCountry", "userId"],
    "encodeHTML": []
  },
}

export const AUTO_CSL_LIMIT = ["Combined Single Limit"];
export const AUTO_SPLIT_LIMITS = ["Bodily Injury (Per person)", "Bodily Injury (Per accident)", "Property Damage"];

export const DEDUCTIBLE_TYPE_WITHOUT_SYMBOL = ["Hours", "Number of Days", "Number of Hours"];
export const PERCENT_DEDUCTIBLE_TYPE = ["Coinsurance as a Percentage", "Percent"];

export const CHARTS = {
  COLOR_GRADIENT: ["#89CFF0", "#6495ED", "#0047AB", "#000080", "#89CFF0", "#0047AB"],
  STATUS_KEY: {
    COMPLIANT: "COMPLIANT",
    NON_COMPLIANT: "NON_COMPLIANT",
    EXPIRED_CANCELED: "EXPIRED_CANCELED"
  },
  DRILLDOWN_PARAMS: {
      'RESPONDED': {
        navigationUrl: "/app/suppliers"
      },
      'OPEN_REQUESTS': {
        navigationUrl: "/app/request-coverage",
      },
      'COMPLIANT': {
        filter: {
          companyStatus: ['12', '7', '11']
        }
      },
      'NON_COMPLIANT': {
        filter: {
          companyStatus: ['13']
        }
      },
      'EXPIRED_CANCELED': {
        filter: {
          companyStatus: ['2', '5']
        }
      },
      'PENDING_COMPLIANCE_REVIEW':{
        filter: {
          isReviewed: ['Pending']
        }
      }
  },
  TYPE:{
    RESPONSE_RATE:'RESPONSE_RATE',
    COMPLIANCE_RATE:'COMPLIANCE_RATE',
    COMPLIANCE_BY_TEMPLATE:'COMPLIANCE_BY_TEMPLATE',
    COMPLIANCE_BY_COVERAGE_TYPE:'COMPLIANCE_BY_COVERAGE_TYPE',
    COMPLIANCE_TREND:'COMPLIANCE_TREND'

  }
}