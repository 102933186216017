import { ACTION_TYPES } from "./Application.types";
import { APPLICATION_USER_ROLES } from "../../../Utils/Constants/Application.constants";
import _ from "lodash";
import { AGENTS_DASHBOARD_LIST_TYPE } from "../AgentPendingVerification/AgentPendingVerification.types";
const AppInitialState = {
  clientPaginationFilterObject : {},
  currentSession: {
    isAuthenticated: false,
    role: "",
    error: { message: "" }
  },
  applicationReference: {
    coverageTypes: [],
    policyTypes: [],
    endorsementTypes: [],
    limitTypes: [],
    policyAgencies: [],
    companies: [],
    agencies: []
  },
  regionWiseReferenceData:{},
  isApplicationReferenceAvailable: false,
  applicationReferenceError: false,
  storeTutorialFlag: false,
  isListLoading: false,
  isListDetailsLoading: false,
  isCoiDownload: false,
  selectedNotification: {},
  isWelcomeDisplayed: false,
  openRequestCount: 0,
  pendingApprovalCount: {
    countPolicyVerfication: 0,
    countPolicyShare: 0,
    countPolicyRequest: 0,
    totalPendingApproval: 0
  },
  sharedCompanyDetailsLoading: false,
  clientAllPoliciesLoading: false,
  clientProjectTemplates: [],
  clientProjectTemplatePolicyList: [],
  shareAddressBookDetails: null,
  ssoCompanyName: "",
  socket:null
};

const Base_AppInitialState = { ...AppInitialState };

const ApplicationReducer = (state = AppInitialState, action) => {
  switch (action.type) {
    /**
     * APPLICATION
     */

    
    case ACTION_TYPES.APPLICATION.SET_SOCKET:
      return {
        ...state,
        socket:action.payload
      };

    case ACTION_TYPES.APPLICATION.CHANGE_USER_ROLE:
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          ...{ role: action.payload }
        }
      };

    /**
     * APPLICATION REFERENCE DATA HANDLE
     */

    case ACTION_TYPES.APPLICATION.REFERENCE_DATA_SUCCESS:
      let tempRefData = {};
      _.forEach(_.keys(action.payload), (key) => {
        if(key != 'regionId'){
          tempRefData = {
            ...tempRefData,
            [key]: _.sortBy(action.payload[key], (item) => item.name)
          };
        }
      });
      let regionWisePolicyTypeDataClone = {...state?.regionWisePolicyTypeData}
      if(action.payload.regionId){
        let regionId = action.payload.regionId+''
        regionWisePolicyTypeDataClone[[regionId]] = tempRefData?.policyTypes;
      }
      return {
        ...state,
        isApplicationReferenceAvailable: true,
        applicationReference: tempRefData,
        regionWisePolicyTypeData:regionWisePolicyTypeDataClone
      };

    case ACTION_TYPES.APPLICATION.REFERENCE_DATA_POLICY_TYPE:{
      let regionWisePolicyTypeDataClone = {...state?.regionWisePolicyTypeData}
      regionWisePolicyTypeDataClone ={
        ...regionWisePolicyTypeDataClone,
        ...action?.payload
      }
      return {
        ...state,
        regionWisePolicyTypeData:regionWisePolicyTypeDataClone
      };
    }

    case ACTION_TYPES.APPLICATION.REFERENCE_DATA_ERROR:
      return {
        ...state,
        isApplicationReferenceAvailable: false,
        applicationReferenceError: action.payload
      };

    /**
     * AUTH TOKEN
     */

    case ACTION_TYPES.AUTH.SET_USER_TOKEN_INFORMATION:
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          role:
            action.payload.role === "Client"
              ? APPLICATION_USER_ROLES.INSURER
              : "",
          ...{ isAuthenticated: true, ...action.payload }
        }
      };

    case ACTION_TYPES.AUTH.ERROR_ON_USER_INFORMATION:
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          ...{ error: action.payload }
        }
      };

    /**
     * AUTH USER INFORMATION
     */

    case ACTION_TYPES.AUTH.SET_USER_INFORMATION:
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          ...action.payload
        }
      };

    case ACTION_TYPES.AUTH.SET_TUTORIAL_POPUP:
      return {
        ...state,
        storeTutorialFlag: action.payload
      };

    case ACTION_TYPES.AUTH.SET_FIRST_LOGIN_FLAG:
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          userFirstLogin: action.payload
        }
      };

    case ACTION_TYPES.LIST_LOADING: {
      return {
        ...state,
        isListLoading: true
      };
    }

    case ACTION_TYPES.CLEAR_LIST_LOADING: {
      return {
        ...state,
        isListLoading: false
      };
    }
    case ACTION_TYPES.CUSTOM_LABEL_LOADING: {
      const { payload } = action;
      return {
        ...state,
        [payload]: true
      };
    }

    case ACTION_TYPES.CLEAR_CUSTOM_LABEL_LOADING: {
      const { payload } = action;
      return {
        ...state,
        [payload]: false
      };
    }
    case ACTION_TYPES.SHARED_COMPANY_DETAILS_LOADING: {
      return {
        ...state,
        sharedCompanyDetailsLoading: true
      };
    }
    case ACTION_TYPES.CLEAR_SHARED_COMPANY_DETAILS_LOADING: {
      return {
        ...state,
        sharedCompanyDetailsLoading: false
      };
    }

    case ACTION_TYPES.LIST_DETAILS_LOADING: {
      return {
        ...state,
        isListDetailsLoading: true
      };
    }

    case ACTION_TYPES.CLEAR_LIST_DETAILS_LOADING: {
      return {
        ...state,
        isListDetailsLoading: false
      };
    }

    case ACTION_TYPES.APPLICATION.CLEAR_APPLICATION_DATA:
      return {
        ...Base_AppInitialState
      };
    case ACTION_TYPES.GET_DOWNLOAD_COI_SUCCESS: {
      return {
        ...state,
        isCoiDownload: true
      };
    }
    case ACTION_TYPES.GET_DOWNLOAD_COI_ERROR: {
      return {
        ...state,
        isCoiDownload: !state.isCoiDownload
      };
    }
    case ACTION_TYPES.CLEAR_DOWNLOAD_COI: {
      return {
        ...state,
        isCoiDownload: false
      };
    }
    case ACTION_TYPES.SELECTED_NOTIFICATION: {
      return {
        ...state,
        selectedNotification: action.payload
      };
    }
    case ACTION_TYPES.DISABLE_WELCOME: {
      return { ...state, isWelcomeDisplayed: true };
    }
    case ACTION_TYPES.SET_OPEN_REQUESTS: {
      return { ...state, openRequestCount: action.payload };
    }
    case ACTION_TYPES.SET_PENDING_APPROVAL_COUNT: {
      return { ...state, pendingApprovalCount: action.payload };
    }
    case ACTION_TYPES.CLIENT_PROJECT_TEMPLATES_FOR_AGENT_SUCESS: {
      return {
        ...state,
        clientProjectTemplates: action.payload
      };
    }

    case ACTION_TYPES.CLEAR_CLIENT_PROJECT_TEMPLATES_FOR_AGENT: {
      return { ...state, clientProjectTemplates: [] };
    }

    case ACTION_TYPES.CLIENT_ADDRESS_BOOK_DETAILS_FOR_AGENT_SUCESS: {
      return {
        ...state,
        shareAddressBookDetails: action.payload
      };
    }

    case ACTION_TYPES.CLEAR_CLIENT_ADDRESS_BOOK_DETAILS_FOR_AGENT: {
      return { ...state, shareAddressBookDetails: null };
    }
    case ACTION_TYPES.CLIENT_PROJECT_TEMPLATES_POLICY_SHARE_LIST_AGENT_SUCESS: {
      return {
        ...state,
        clientProjectTemplatePolicyList: action.payload
      };
    }

    case ACTION_TYPES.CLEAR_CLIENT_PROJECT_TEMPLATES_POLICY_SHARE_LISTPOLICY_SHARE_LIST_AGENT: {
      return { ...state, clientProjectTemplatePolicyList: [] };
    }
    case ACTION_TYPES.SET_SSO_COMPANYNAME: {
      return {
        ...state,
        ssoCompanyName: action.payload.companyName || "",
      };
    }
    case ACTION_TYPES.CLEAR_SSO_COMPANYNAME: {
      return {
        ...state,
        ssoCompanyName: "",
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.SET_PAGINATION_FILTER: {
      return {
        ...state,
        clientPaginationFilterObject : action.payload
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.CLEAR_PAGINATION_FILTER: {
      return {
        ...state,
        clientPaginationFilterObject : {}
      };
    }


    default:
      return state;
  }
};

export default ApplicationReducer;
