import { Icon, Spin, Button, Input } from "antd";
import _, { first } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./App.less";
import NetworkConnectivity from "./Components/NetworkConnectivity/NetworkConnectivity";
import AppContainer from "./Layout/AppContainer/AppContainer";
import ForgotPassword from "./Modules/Authorization/ForgotPassword/ForgotPassword";
import ForgotPasswordSuccess from "./Modules/Authorization/ForgotPasswordSuccess/ForgotPasswordSuccess";
import Login from "./Modules/Authorization/Login/Login";
import RegistrationSuccess from "./Modules/Authorization/RegistrationSuccess/RegistrationSuccess";
import ResetPassword from "./Modules/Authorization/ResetPassword/ResetPassword";
import ResetPasswordSuccess from "./Modules/Authorization/ResetPasswordSuccess/ResetPasswordSuccess";
import SignUp from "./Modules/Authorization/SignUp/SignUp";
import UpdatePassword from "./Modules/Authorization/UpdatePassword/UpdatePassword";
import UpdatePasswordSuccess from "./Modules/Authorization/UpdatePasswordSuccess/UpdatePasswordSuccess";
import Maintainance from "./Modules/Exceptions/Maintainance/Maintainance";
import { PrivateRoute } from "./Routes/PrivateRoute";

import {
  LogoutComponent,
  NetworkErrorComponent,
  PageNotFoundComponent,
  RequestorComplianceComponent,
  ServerErrorComponent,
  UnAuthorizedErrorComponent,
  VerificationUserComponent
} from "./Routes/Route";
import { getMaintainanceStatus } from "./Store/Modules/Application/Application.asyncRequest";
import { CertificialThemeContext } from "./theme-context";
import {
  APPLICATION_USER_ROLES,
  CERTIFICIAL_COLOR_SCHEME
} from "./Utils/Constants/Application.constants";
import { appHistory } from "./Utils/Helpers/History";
import { Detector } from "./Utils/utility/appConnectivity";
import { disable_app_loader } from "./Utils/utility/appLoader.utility";
import { SSOCheckPage } from "./Modules/Authorization/SSOCheckPage/SSOCheckPage";
import SSO from "./Modules/Exceptions/SSO/SSO";
import SSORedirect from "./Modules/Exceptions/SSO/SSORedirect";
import LoadSeoData from './SeoData';
import SEODataObj from './seoDetails';
import ChatBot from './ChatBot';
import { CoiUploadPage } from "./Modules/PendingInsuredRequests/CertificateUploadContainer/CoiUploadPage";
import { AutoResponse } from "./Modules/Agent/AutoResponse";

import {RemoveClient} from './Modules/Agent/Client/RemoveClient/RemoveClient.js'
import LearnMore from "./Modules/Agent/AutoResponse/LearnMoreButton.js";
import LearnMoreDetails from "./Modules/Agent/AutoResponse/LearnMoreDetails.js";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { _modalService } from "./Utils/wrappers/Modal/ModalWrapper.js";
import { setSocket } from "./Store/Modules/MyProfile/MyProfile.action.js";

Spin.setDefaultIndicator(
  <Icon type="loading-3-quarters" className="color-primary fs-36x" spin />
);

const App = (props) => {
  const { pathname } = useLocation();
  const [maintainance, setMaintainance] = useState({
    fromTime: new Date(),
    toTime: new Date()
  });
  const { id, firstName, lastName, userFirstLogin } = useSelector(
    (state) => state.myProfile.userData
  );

  const { socket } = useSelector(
    (state) => state.app
  );
  const [value, setValue] = useState("");
  const [activeMaintainance, setActiveMaintainance] = useState(false);
  const [certificial_theme, setCertificial_theme] = useState(
    CERTIFICIAL_COLOR_SCHEME.DEFAULT
  );
  const dispatch = useDispatch();

  const clientCompanyList = useSelector((state) =>
      state.AgentDashboard.clientFilterCompanyList.length
        ? state.AgentDashboard.clientFilterCompanyList
        : state.AgentDashboard.clientCompanyList);
  // const [timeout, setTimValue] = useState(null);
  // const socket = useRef();
  // const [socket, setSocket] = useState(null);
  const timeout = useRef();

  useEffect(() => {
    if(!firstName){
      return;
    }
    let socket = io("ws://dev.certificial.com:8085/agent?name="+firstName, { transports: [ 'websocket' ],  secure: false  });
    socket.on('error', (error) => {
      console.error('Socket.IO error:', error);
      // You can handle the error further here
    });

    socket.on('connect', () => {
      console.log('Successfully connected to the server.');
      // You can perform additional actions upon successful connection here
    });
    dispatch(setSocket(socket));

  }, [firstName]);

  useEffect(()=>{
    return ()=>{
      socket.disconnect();
      socket.off('connect');
       socket.off('disconnect');
       socket.close();
    }
  },[])



  useEffect(() => {
    getMaintainanceStatus().then(
      (response) => {
        if (response && response.flag) {
          setMaintainance({
            fromTime: response.statTime,
            toTime: response.endTime
          });
          if (pathname !== "/maintenance-activity-login") {
            setActiveMaintainance(true);
            appHistory.push("/maintenance");
          } else {
            setActiveMaintainance(false);
          }
        } else {
          if (pathname === "/maintenance") {
            setActiveMaintainance(false);
            appHistory.push("/login");
          }
        }
        disable_app_loader();
      },
      (error) => {
        disable_app_loader();
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Detector
        render={({ online }) => (
          <div>
            <ChatBot/>
            {/* <Input value={value} onChange={e=>setValue(e.target.value)} />
            <Button onClick={()=>handleClick()}>send message</Button> */}
            <CertificialThemeContext.Provider value={certificial_theme}>
              <div>
                <Switch>
                  <PrivateRoute
                    path="/app"
                    Component={AppContainer}
                  ></PrivateRoute>
                </Switch>
                {(!_.includes(pathname, "app") && (
                  <div className="app-outer-container">
                    <div
                      className={
                        !_.includes(["/maintenance", "/logout"], pathname)
                          ? "outer-layer"
                          : ""
                      }
                    >
                      <Switch>
                        <Route path="/sso-error">
                          <LoadSeoData />
                          <SSO />
                        </Route>
                        <Route path="/sso-redirect">
                          <LoadSeoData />
                          <SSORedirect />
                        </Route>
                        <Route path="/sso">
                          <LoadSeoData />
                          <SSOCheckPage />
                        </Route>
                        <Route exact path="/login/:name?">
                          <LoadSeoData seoContent={SEODataObj.login}/>
                          <Login />
                        </Route>
                        <Route exact path="/maintenance-activity-login">
                          <LoadSeoData seoContent={SEODataObj.login}/>
                          <Login activeMaintainance={activeMaintainance} />
                        </Route>
                        <Route exact path="/logout">
                          <LoadSeoData />
                          <LogoutComponent />
                        </Route>
                        <Route exact path="/coi-upload">
                          <LoadSeoData />
                          <CoiUploadPage/>
                        </Route>
                        {/* <Route exact path="/auto-response">
                          <LoadSeoData />
                          <AutoResponse/>
                        </Route>
                        <Route exact path="/auto-response/learn-more">
                              <LoadSeoData />
                              <LearnMoreDetails emailPage={true}/>
                        </Route> */}
                    
                        <Route exact path="/remove-client">
                          <LoadSeoData />
                          <RemoveClient/>
                        </Route>
                        <Route path="/page-not-found">
                          <LoadSeoData />
                          <PageNotFoundComponent />
                        </Route>
                        <Route path="/network-error">
                          <LoadSeoData />
                          <NetworkErrorComponent />
                        </Route>
                        <Route path="/unauthorized-access">
                          <LoadSeoData />
                          <UnAuthorizedErrorComponent />
                        </Route>
                        <Route path="/server-internal-error">
                        <LoadSeoData />
                          <ServerErrorComponent />
                        </Route>
                        <Route path="/sign-up/:name?">
                          <LoadSeoData />
                          <SignUp data={APPLICATION_USER_ROLES.CLIENT} />
                        </Route>
                        <Route path="/sign-up-agent/:agentId/:name?">
                          <LoadSeoData />
                          <SignUp data={APPLICATION_USER_ROLES.AGENT} />
                        </Route>
                        <Route path="/external-ams-signup">
                          <LoadSeoData />
                          <SignUp data={APPLICATION_USER_ROLES.AGENT} />
                        </Route>
                        <Route path="/forgot-password">
                          <LoadSeoData />
                          <ForgotPassword />
                        </Route>
                        <Route path="/registration-success">
                          <LoadSeoData />
                          <RegistrationSuccess />
                        </Route>
                        <Route path="/registration-success-agentverify">
                          <LoadSeoData />
                          <RegistrationSuccess data={"EULA"} />
                        </Route>
                        <Route path="/reset-password/:token">
                          <LoadSeoData />
                          <ResetPassword />
                        </Route>
                        <Route path="/verify-user/:token">
                          <LoadSeoData />
                          <UpdatePassword />
                        </Route>
                        <Route path="/forgot-password-success">
                          <LoadSeoData />
                          <ForgotPasswordSuccess />
                        </Route>
                        <Route path="/reset-password-success">
                          <LoadSeoData />
                          <ResetPasswordSuccess />
                        </Route>
                        <Route path="/verify-user-success">
                          <LoadSeoData />
                          <UpdatePasswordSuccess />
                        </Route>
                        <Route path="/requestor-compliance">
                          <LoadSeoData />
                          <RequestorComplianceComponent />
                        </Route>
                        <Route path="/verification-user/email/:token">
                          <LoadSeoData />
                          <VerificationUserComponent />
                        </Route>
                        <Route path="/maintenance">
                          <LoadSeoData />
                          <Maintainance
                            fromTime={maintainance.fromTime}
                            toTime={maintainance.toTime}
                          />
                        </Route>
                        <Redirect path="*" to="/sso" />
                      </Switch>
                    </div>
                  </div>
                )) ||
                  null}
              </div>
            </CertificialThemeContext.Provider>
            <NetworkConnectivity offline={!online} />
          </div>
        )}
      />
    </div>
  );
};

export default App;
