import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchActiveCompany,
  onSuccessLogin,
  sso_Authentication_action,
} from "../../../Store/Modules/Application/Application.asyncRequest";
import { appHistory } from "../../../Utils/Helpers/History";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import CompanySelection from "../CompanySelection/CompanySelection";
import { setSSOCompanyName } from "../../../Store/Modules/Application/Application.actions";

export const SSOCheckPage = ({ props }) => {
  const dispatch = useDispatch();
  const [ssoToken, setSSOToken] = useState("");
  const [urlCheckedForToken, seturlCheckedForToken] = useState(false);
  const [companySelectionModalVisible, setCompanySelectionModalVisible] =
    useState(false);
  const [availableCompany, setAvailableCompany] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);

  const useQueryString = () => {
    const location = useLocation();
    let res = location.search.slice(1);
    return res;
  };

  const queryString = useQueryString();
  const [companyName, setCompanyName] = useState("");
  const [companyPolicyNumber, setCompanyPolicyNumber] = useState("");
  const [companyUserDashboard, setCompanyUserDashboard] = useState("")


  useEffect(() => {
    if (queryString && queryString.length) {
      if (queryString && queryString.slice(0, 5) === "auth=") {
        const allParams = new URLSearchParams(queryString);
        const querytoken = allParams.get("auth");
        const querycompanyName = allParams.get("companyName");
        const querypolicyNumber = allParams.get("policyNumber");
        const queryuserDashboard = allParams.get("userDashboard");
        
        setCompanyPolicyNumber(querypolicyNumber)
        setCompanyName(querycompanyName);
        setSSOToken(querytoken);
        seturlCheckedForToken(true);
        setCompanyUserDashboard(queryuserDashboard||"")
      }
    } else {
      seturlCheckedForToken(true);
    }
  }, [queryString]);

  useEffect(() => {
    if (urlCheckedForToken) {
      if (ssoToken) {
        //console.log("============= sso token found =============");
        sso_Authentication_action({
          token: ssoToken,
        }).then(
          (res) => {
            let payload = {
              companyName: companyName,
            };
            dispatch(setSSOCompanyName(payload));
            if (res && res.length && res[0].multiemailUser) {
              const loggedInUser = res[0];
              _authService.setUserToken(loggedInUser.jwt);
              dispatch(
                fetchActiveCompany(
                  loggedInUser.email,
                  loggedInUser.dummySessionId,
                  (comp) => {
                    const allComp = comp.map((o) => o.company);
                    const allUser = comp.map((o) => {
                      return {
                        ...loggedInUser,
                        ...o,
                      };
                    });
                    setAvailableUsers(allUser);
                    setAvailableCompany(allComp);
                    setCompanySelectionModalVisible(true);
                  }
                )
              );
            } else {
              if (res === null) {
                appHistory.push("/sso-error");
              } else {
                let loginData = Array.isArray(res) ? _.head(res) : res;
                loginData = { ...loginData, 
                  ssoRedirect: true, 
                  companyName: companyName || "", 
                  policyNumber: companyPolicyNumber || "",
                  userDashboard: companyUserDashboard
                }
                dispatch(onSuccessLogin(loginData));
              }
            }
          },
          (err) => {
            //console.log("err ====", err, err && err.message);
            appHistory.push("/sso-error");
          }
        );
      } else {
        //console.log("============= sso token not found =============");
        appHistory.push("/login");
      }
    }
  }, [ssoToken, urlCheckedForToken]);

  return (
    <div>
      {companySelectionModalVisible && (
        <CompanySelection
          companySelectionModalVisible={companySelectionModalVisible}
          availableUsers={availableUsers}
          availableCompany={availableCompany}
        />
      )}
    </div>
  );
};
