import { message } from "antd";
import _ from "lodash";
import {
  approvalIcon,
  deleteIcon,
  editIcon,
  handleExceptionIcon,
  rejectIcon,
  viewDetailsIcon,
  replaceIcon,
  shareIcon,
  noteIcon
} from "../../Assets/assets";
import {
  CERTIFICIAL,
  CERTIFICIAL_COLOR_SCHEME,
  CERTIFICIAL_STATUS,
  SELECT_ALL_CHECKED_BY,
  SANITIZATION_FIELDS,
  APPLICATION_USER_ROLES,
  INSURED_TYPE
} from "../Constants/Application.constants";
import { FUTURE_PROPERTY_LOCATION_MESSAGE, FUTURE_VEHICLES_CHECKBOX_MESSAGE, DEDUCTIBLE_TYPE_WITHOUT_SYMBOL, PERCENT_DEDUCTIBLE_TYPE } from "../Constants/Application.constants";

import moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import DOMPurify from "dompurify";


export const showFile = (blob, name) => {
  const format = _.last(_.split(name, "."));
  let type = "";
  switch (_.toLower(format)) {
    case "png":
      type = "image/png";
      break;
    case "jpeg":
      type = "image/jpeg";
      break;
    case "jpg":
      type = "image/jpg";
      break;
    case "pdf":
      type = "application/pdf";
      break;
    case "xlsx":
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      break;
    case "xls":
      type = "application/vnd.ms-excel";
      break;
    case "csv":
      type = "text/csv;charset=utf-8'";
      break;
    case "zip":
      type = "application/zip";
      break;
    default:
      break;
  }
  if (!!type) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should

    var newBlob = new Blob([blob], { type });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob, name);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.target = "_blank";
    link.href = data;
    if (format === "csv" || format === "xlsx" || format === "zip") {
      link.download = name;
    }
    link.click();
    // window.open(data);
    // setTimeout(function () {
    //   // For Firefox it is necessary to delay revoking the ObjectURL
    //   window.URL.revokeObjectURL(data);
    // }, 100);
  } else {
    message.warn("File format is invalid.");
  }
};

export const showPropertyLimits = (policyTypes = []) => {
  return policyTypes?.showPropertyLimits && policyTypes?.showPropertyLimits === true? true: false;
}

export const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
};

export const validateUserPermission = (permission, userRole, module) => {
  if (permission?.requestor === null || permission?.insured === null) {
    return true;
  }
  if (userRole === APPLICATION_USER_ROLES.REQUESTER) {
    if (permission?.requestor[module] === undefined) {
      return true;
    }
    return permission.requestor[module] === true;
  }

  if (userRole === APPLICATION_USER_ROLES.INSURER) {
    if (permission?.insured[module] === undefined) {
      return true;
    }
    return permission?.insured[module] === true
  }
  return true;
}

export const getRoleDescription = (roleID, roles) => {
  const rolesData = (roles || []).filter(item => item.roleId === roleID);
  return rolesData.length ? rolesData[0].roleDescription : null;
}

export const validateUploadingFile = (file, fileType) => {
  if (_.isEmpty(file)) {
    return true;
  }
  const isValid = _.includes(
    fileType || CERTIFICIAL.UPLOAD_FILES.UPLOAD_FILES_SUPPORT_CHECK,
    file.type
  );
  if (!isValid) {
    message.warn(
      fileType
        ? "File not supported. Please upload " + fileType + " only."
        : " File not supported. Please upload image or pdf only"
    );
  }
  const isLtSize = file.size < CERTIFICIAL.UPLOAD_FILES.MAX_SIZE;
  if (!isLtSize && isValid) {
    message.warn(
      "The uploading document should be smaller than" +
        CERTIFICIAL.UPLOAD_FILES.MAX_SIZE_MESSAGE +
        "!"
    );
  }
  return isValid && isLtSize;
};
export const convertBrowserSpecificFile = (file, name, type) => {
  if (!navigator.msSaveBlob) {
    file = new File([file.slice(0, file.size, file.type)], name, {
      type: type
    });
  } else {
    file = new Blob([file.slice(0, file.size, file.type)], { type: type });
    file = blobToFile(file, name);
  }
  return file;
};
export const blobToFile = (theBlob, fileName) => {
  const b = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return b;
};

export const shouldShowEndorsementsOnSpecialEndorsements = (
  specialEndorsements
) => {
  if (!specialEndorsements) {
    return true;
  }
  const requestedSpecialEndorsements =
    specialEndorsements &&
    _.filter(specialEndorsements, (s) => !!s.comments || !!s.value);
  return requestedSpecialEndorsements && !requestedSpecialEndorsements.length;
};

export const strongPasswordVerification = (value) => {
  const strongRegex = new RegExp(
    "^(?=.{8,12})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$",
    "g"
  );

  if (value.length === 0) {
    return "Password is required";
  } else if (strongRegex.test(value)) {
    return false;
  } else {
    return "Password is not matching the criteria";
  }
};

export const generateLimitColumns = (columnArray, regionId) => {
  // return columnArray;
  if (columnArray && columnArray.length === 2 && regionId === 2) {
    let newRegionObj = { ...columnArray[1] };
    newRegionObj["title"] = "Deductible";
    newRegionObj["dataIndex"] = "deductible";
    columnArray.splice(1, 0, newRegionObj);

    // columnArray[0].render =  rederer();
    columnArray[1].title = `${columnArray[1].title} \r\n (${
      regionId === 2 ? "CAD" : "USD"
    })`;
    columnArray[2].title = `${columnArray[2].title} \r\n (${
      regionId === 2 ? "CAD" : "USD"
    })`;
  } else if (
    columnArray &&
    columnArray.length === 3 &&
    regionId === 2 &&
    columnArray[1].title.indexOf("CAD") === -1
  ) {
    columnArray[1].title = `${columnArray[1].title} \r\n (${
      regionId === 2 ? "CAD" : "USD"
    })`;
    columnArray[2].title = `${columnArray[2].title} \r\n (${
      regionId === 2 ? "CAD" : "USD"
    })`;
  } else if (columnArray && columnArray.length === 3 && regionId !== 2 && columnArray[1]?.dataIndex == 'deductible') {
    columnArray.splice(1, 1);
  }
  return columnArray;
};

export const getActionIcon = (text) => {
  switch (text) {
    case CERTIFICIAL.POLICY_ACTIONS.VIEW.ICON:
      return viewDetailsIcon;
    case CERTIFICIAL.POLICY_ACTIONS.EDIT.ICON:
      return editIcon;
    case CERTIFICIAL.POLICY_ACTIONS.DELETE.ICON:
      return deleteIcon;
    case CERTIFICIAL.POLICY_ACTIONS.APPROVE.ICON:
      return approvalIcon;
    case CERTIFICIAL.POLICY_ACTIONS.REJECT.ICON:
      return rejectIcon;
    case CERTIFICIAL.POLICY_ACTIONS.HANDLE_EXCEPTION.ICON:
      return handleExceptionIcon;
    case CERTIFICIAL.POLICY_ACTIONS.DELETE_SHARE.ICON:
      return deleteIcon;
    case CERTIFICIAL.POLICY_ACTIONS.REPLACE.ICON:
      return replaceIcon;
    case CERTIFICIAL.POLICY_ACTIONS.BULK_SHARE.ICON:
      return shareIcon;
    case CERTIFICIAL.POLICY_ACTIONS.ALL_INSURED.ICON:
      return noteIcon;
    default:
      return "";
  }
};

export const titleMaintainer = (value, length) =>
  value && value.length > length ? value.slice(0, length) + " ..." : value;

export const calculatePolicyStatus = (
  policy,
  source,
  activeProject,
  statusKey,
  isEvidenceOnly
  ) => {
  const { requestId } = activeProject || {};
  const status = statusKey ? statusKey : "verificationStatus";
  const policyStatus = policy && policy[status];

  const isPolicyStatusActive = [CERTIFICIAL_STATUS.POLICY_STATUS.VERIFIED, CERTIFICIAL_STATUS.POLICY_STATUS.REVIEWED].includes(policyStatus);
  
  switch (source) {
    case CERTIFICIAL.SHARE_VIEW.DASHBOARD:
      if(isPolicyStatusActive && isEvidenceOnly){
        return CERTIFICIAL_STATUS.POLICY_STATUS.ACTIVE;
      }
      if (
        !requestId &&
        policyStatus === CERTIFICIAL_STATUS.POLICY_STATUS.VERIFIED
      ) {
        return CERTIFICIAL_STATUS.POLICY_STATUS.ACTIVE;
      } else if (
        requestId &&
        policyStatus === CERTIFICIAL_STATUS.POLICY_STATUS.VERIFIED ||
        policyStatus === CERTIFICIAL_STATUS.POLICY_STATUS.REVIEWED
      ) {
        return CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANT;
      } else if (
        policy.verificationStatus &&
        (policy.verificationStatus.toLowerCase() === "processing" ||
          policy.verificationStatus.toLowerCase() === "expired" ||
          policy.verificationStatus.toLowerCase() === "canceled" ||
          policy.verificationStatus.toLowerCase() === "pending cancellation" ||
          policy.verificationStatus.toLowerCase() === "rejected")
      ) {
        return policy.verificationStatus;
      } else {
        const compliance = policy.compliance
          ? JSON.parse(policy.compliance)
          : null;
        return compliance && compliance.isComplianceIssue
          ? "Compliance"
          : compliance && compliance.isRequireReview
          ? CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW
          : policy.verificationStatus;
      }
    case CERTIFICIAL.REQUESTOR_VIEW.DASHBOARD:
      if(isPolicyStatusActive && isEvidenceOnly){
        return CERTIFICIAL_STATUS.POLICY_STATUS.ACTIVE;
      }
      if (
        !requestId &&
        policyStatus === CERTIFICIAL_STATUS.POLICY_STATUS.VERIFIED
      ) {
        return CERTIFICIAL_STATUS.POLICY_STATUS.ACTIVE;
      } else if (
        requestId &&
        (policyStatus === CERTIFICIAL_STATUS.POLICY_STATUS.VERIFIED ||
          policyStatus === CERTIFICIAL_STATUS.POLICY_STATUS.REVIEWED)
      ) {
        return policyStatus.toLowerCase() === "processing" ||
          policyStatus.toLowerCase() === "expired" ||
          policyStatus.toLowerCase() === "canceled" ||
          policyStatus.toLowerCase() === "rejected"
          ? policyStatus
          : policy &&
            policy.compliance &&
            typeof policy.compliance === "string" &&
            JSON.parse(policy.compliance) &&
            JSON.parse(policy.compliance).isRequireReview
          ? JSON.parse(policy.compliance).verificationStatus
          : CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANT;
      }
      return policyStatus;

    case CERTIFICIAL.AGENT_VIEW.DASHBOARD:
    case CERTIFICIAL.SHARE_VIEW.MY_POLICY_LIST:
    case CERTIFICIAL.AGENT_VIEW.AGENT_PENDING_POLICY_REQUEST:
    case CERTIFICIAL.AGENT_VIEW.AGENT_PENDING_POLICY_SHARE:
      return policyStatus;

    default:
      return policyStatus;
  }
};

export const getBase64 = (file) => {
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
};

export const getBrowserName = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode == true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
};

export const getStringDifference = (str1, str2) => {
  const compareString = function (str1, str2) {
    let i = 0;
    let j = 0;
    let result = "";

    while (j < str2.length) {
      if (str1[i] != str2[j] || i === str1.length) result += str2[j];
      else i++;
      j++;
    }
    return result;
  };

  if (str1.length < str2.length) {
    return compareString(str1, str2);
  } else {
    return compareString(str2, str1);
  }
};

export const checkIfCompanyIsPaidFees = (company, statusKey) => {
  const isPaid =
    company && company[statusKey]
      ? _.includes(
          company[statusKey],
          CERTIFICIAL_STATUS.COMPANY_STATUS.AWAITING_ENROLLEMENT_FEES
        ) ||
        company[statusKey] ===
          CERTIFICIAL_STATUS.COMPANY_STATUS.AWAITING_ENROLLEMENT_FEES
      : false;

  return isPaid;
};

export const serializeURLParams = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export const validateEmailAddressForPayment = (emailAddress) => {
  if (!emailAddress) return false;
  const mailformat = CERTIFICIAL.REGEX_PATTERNS.STANDARD_EMAIL_FOR_ZOHO;
  if (emailAddress.match(mailformat)) {
    return true;
  } else {
    message.error(
      "Email address for the user is invalid. Please contact administrator."
    );
    return false;
  }
};

export const _getStatusColor = (_status) => {
  const status = _.startCase(_.toLower(_status));
  const CERTIFICIAL_THEME = CERTIFICIAL_COLOR_SCHEME.DEFAULT;
  switch (status) {
    case CERTIFICIAL_STATUS.VERIFICATION_STATUS.APPROVED:
    case CERTIFICIAL_STATUS.VERIFICATION_STATUS.REVIEWED:
    case CERTIFICIAL_STATUS.VERIFICATION_STATUS.ACCEPTED:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.ACTIVE:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.PROCESSED:
    case CERTIFICIAL_STATUS.POLICY_STATUS.VERIFIED:
    case CERTIFICIAL_STATUS.POLICY_STATUS.REVIEWED:
    case CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANT:
      return CERTIFICIAL_THEME.SUCCESS;

    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.COMPLIANCE_ISSUE:
      return "#f5222d";

    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.UNVERIFIED:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.PENDING:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.REQUESTED:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.AGENT_APPROVAL_PENDING:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.INSURED_APPROVAL_PENDING:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.IN_PROCESS:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.ACTION_REQUIRED:
    case CERTIFICIAL_STATUS.POLICY_STATUS.EXPIRING:
    case CERTIFICIAL_STATUS.POLICY_STATUS.IN_REVIEW:
    case CERTIFICIAL_STATUS.POLICY_STATUS.IN_PROCESS:
    case CERTIFICIAL_STATUS.POLICY_STATUS.IN_PROGRESS:
    case CERTIFICIAL_STATUS.POLICY_STATUS.PROCESSING:
    case CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW:
    case CERTIFICIAL_STATUS.POLICY_STATUS.PENDING_CANCELLATION:
    case CERTIFICIAL_STATUS.VERIFICATION_STATUS.REQUESTED:
    case CERTIFICIAL_STATUS.USER_STATUS.PENDING:
    case CERTIFICIAL_STATUS.COMPANY_STATUS.PENDING_ACTIVATION:
      return CERTIFICIAL_THEME.WARNING;

    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.DECLINED:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.REJECTED:
    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.INACTIVE:
      return CERTIFICIAL_THEME.SALMON;

    case CERTIFICIAL_STATUS.POLICY_STATUS.CANCELED:
    case CERTIFICIAL_STATUS.POLICY_STATUS.EXPIRED:
    case CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANCE:
    case CERTIFICIAL_STATUS.POLICY_STATUS.NON_COMPLIANT:
      return CERTIFICIAL_THEME.FIREBRICK;

    case CERTIFICIAL_STATUS.LIST_ITEM_STATUS.UPGRADE:
    case CERTIFICIAL_STATUS.USER_STATUS.INVITED:
      return CERTIFICIAL_THEME.SALMON;

    default:
      return "grey";
  }
};

export const getQueryVariable = (paramsUrl, variable) => {
  var query = paramsUrl.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

export const fetchExternalCompany = (selection) => {
  return selection.companies && selection.companies.length
    ? selection.companies.find((c) => c.externalCertId)
    : selection.externalCertId
    ? selection
    : {};
};

export const _fetchAutoCompleteURL = (type, source, isRequestFromAgent) => {
  if (source === "request") {
    switch (type) {
      case "email":
        return CERTIFICIAL.AUTOCOMPLETES.EMAIL;
      case "company":
        return CERTIFICIAL.AUTOCOMPLETES.COMPANY;
      default:
        break;
    }
  } else if (isRequestFromAgent) {
    switch (type) {
      case "email":
        return CERTIFICIAL.AUTOCOMPLETES.EMAIL_SHARE;
      case "company":
        return CERTIFICIAL.AUTOCOMPLETES.COMPANY_SHARE;
      default:
        break;
    }
  } else {
    switch (type) {
      case "email":
        return CERTIFICIAL.AUTOCOMPLETES.EMAIL;
      case "company":
        return CERTIFICIAL.AUTOCOMPLETES.COMPANY;
      default:
        break;
    }
  }
};

export const deepTrim = (obj) => {
  if (obj && typeof obj === "object") {
    Object.keys(obj).map((key) => {
      if (typeof obj[key] === "object") {
        deepTrim(obj[key]);
      } else if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      }
    });
  }
  return obj;
};

export const exportAndDownloadToExcel = (dataArr, agencyName, isAgent) => {
  const ws = XLSX.utils.json_to_sheet(dataArr, { skipHeader: true });
  var wscols = [{ width: 20 }, { width: 25 }, { width: 20 }, { width: 20 }];

  ws["!cols"] = wscols;
  const wb = isAgent
    ? {
        Sheets: { [CERTIFICIAL.EXPORT_EXCEL.SHEET_NAME.AGENT]: ws },
        SheetNames: [CERTIFICIAL.EXPORT_EXCEL.SHEET_NAME.AGENT]
      }
    : {
        Sheets: { [CERTIFICIAL.EXPORT_EXCEL.SHEET_NAME.USER]: ws },
        SheetNames: [CERTIFICIAL.EXPORT_EXCEL.SHEET_NAME.USER]
      };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], {
    type: CERTIFICIAL.EXPORT_EXCEL.FILE_TYPE
  });
  FileSaver.saveAs(
    data,
    agencyName +
      (isAgent
        ? CERTIFICIAL.EXPORT_EXCEL.FILE_NAME_SUFFIX.AGENT
        : CERTIFICIAL.EXPORT_EXCEL.FILE_NAME_SUFFIX.USER) +
      CERTIFICIAL.EXPORT_EXCEL.EXTENSION
  );
};

export const getBadgeOffset = (count, overflow) => {
  if (overflow == 9999) {
    return count > 9999
      ? [25, 0]
      : count > 999
      ? [20, 0]
      : count > 99
      ? [18, 0]
      : [13, 0];
  } else {
    return count > 999 ? [22, 0] : count > 99 ? [18, 0] : [15, 0];
  }
};
export const isArraySame = (a, b) => {
  return _.isEmpty(_.xor(a, b));
};
export const getNamedInsuredArray = (inputObject, showInsuredStep, isDuplicateShare) => {
  if (!showInsuredStep) {
    return null;
  } else {
    let obj = inputObject;
    if (Object.keys(obj).length !== 0) {
      const getIdFromArrayOfObject = (inputArray) => {
        if (Array.isArray(inputArray) && inputArray.length > 0) {
          let arr = [];
          inputArray.forEach((i) => arr.push(i.id));
          return arr;
        } else {
          return [];
        }
      };
      let selectAllValue = false;
      if (!obj.selectAllFlag && obj.selectAllCheckedBy === SELECT_ALL_CHECKED_BY.EMPTY_STRING) {
        selectAllValue = false;
      }
      if (!obj.selectAllFlag && obj.selectAllCheckedBy === SELECT_ALL_CHECKED_BY.SYSTEM) {
        selectAllValue = true;
      }
      if (!obj.selectAllFlag && obj.selectAllCheckedBy === SELECT_ALL_CHECKED_BY.USER) {
        selectAllValue = false;
      }
      if (obj.selectAllFlag && obj.selectAllCheckedBy === SELECT_ALL_CHECKED_BY.USER) {
        selectAllValue = true;
      }

      let res = {
        selectAll: selectAllValue,
        primaryInsured: obj.primaryInsuredId,
        removeList: getIdFromArrayOfObject(obj.removeList),
        selectedList: getIdFromArrayOfObject(obj.selectedList),
        insuredCompanyId: obj.insuredCompanyId,
        reshare: isDuplicateShare ? false : obj.isPolicyReShareConst,
        includeNotesAndAddressOnCoi:!!obj.includeNotesAndAddressOnCoi
      };
      if (obj.isPolicyReShareConst && obj.selectAllCheckedBy === "" && !isDuplicateShare) {
        res.removeList = [
          ...res.removeList,
          ...getIdFromArrayOfObject(obj.reshareRemovedList)
        ];
        res.selectedList = getIdFromArrayOfObject(obj.reshareAddedList);
      }
      if (
        obj.isPolicyReShareConst &&
        !isDuplicateShare &&
        obj.selectAllCheckedBy === SELECT_ALL_CHECKED_BY.USER &&
        !obj.selectAllFlag
      ) {
        res.clearPrevious = true;
      }
      return res;
    } else {
      return null;
    }
  }
};

export const isCompanyArchived = (insuredCompanyId, statusArray) => {
  const STATUS_ARCHIVED = "Archived";
  let result = false;
  if (insuredCompanyId && statusArray && statusArray.length) {
    const res = statusArray.filter((i) => i.companyId === insuredCompanyId);
    if (res && res.length) {
      const arr = res[0];
      result = arr.complianceStatus === STATUS_ARCHIVED;
    }
  }
  return result;
};

export const getIdFromArrayOfObject = (inputArray) => {
  if (Array.isArray(inputArray) && inputArray.length > 0) {
    let arr = [];
    inputArray.forEach((i) => arr.push(i.id));
    return arr;
  } else {
    return [];
  }
};

export const getCommaSeperatedStr = (arr) => {
  return arr.filter((el) => el).join(", ");
};
export const xnor = (inp1, inp2) => {
  return (inp1 && inp2) || (!inp1 && !inp2);
};

export const getFutureLocationAndVehiclesMessage = (policyType) => {
  if(policyType == "Automobile Liability") {
    return FUTURE_VEHICLES_CHECKBOX_MESSAGE;
  }
  if(policyType == "Property")  {
    return FUTURE_PROPERTY_LOCATION_MESSAGE;
  }

  return "Automatically adds new locations going forward";
}

export const validatePropertyLimit = (row) => {
  if(row.countryId === 1) {
    if (
      row.limitTypeId &&
      row.limitCategoryId &&
      parseInt(row.limits || row.limitValue, 10) &&
      xnor(parseInt(row.deductible, 10), row.deductableTypeId) &&
      xnor(parseInt(row.valuationAmt, 10), row.valuationTypeId)
    ) {
      return true;
    } else {
      return false;
    }
  } else if(row.countryId === 2) {
    if (
      row.limitTypeId &&
      row.limitCategoryId
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    if (
      row.limitTypeId &&
      row.limitCategoryId &&
      parseInt(row.limits || row.limitValue, 10) &&
      xnor(parseInt(row.deductible, 10), row.deductableTypeId) &&
      xnor(parseInt(row.valuationAmt, 10), row.valuationTypeId)
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const getArrayofIdFromArrayOfObject = (inputArray) => {
  if (Array.isArray(inputArray) && inputArray.length > 0) {
    let arr = [];
    inputArray.forEach((i) => {
      if (typeof (i) === "number" && Math.floor(i) === i) {
        arr.push(i)
      } else {
        arr.push(i.id);
      }
    });
    return arr;
  } else {
    return [];
  }
};
export const sortAnArrayOfObjByName = (inputArray) => {
  if (Array.isArray(inputArray) && inputArray.length > 0) {
    return _.sortBy(inputArray, [function(o) { return o.name; }]);
  }
};

export const isDeductibleWithoutSymbol = (value)=>{
    return DEDUCTIBLE_TYPE_WITHOUT_SYMBOL.includes(value);
}

export const isDeductibleWithPercentSymbol = (value)=>{
  return PERCENT_DEDUCTIBLE_TYPE.includes(value);
}

export const getDeductibleValueForRequestorView = (row, value)=>{
  if(isDeductibleWithoutSymbol(row.deductableType)){
    return value;
  }else if(isDeductibleWithPercentSymbol(row.deductableType)){
    const currencySymbol = value === 0 ? "%" : ""
    return value+currencySymbol
  }else{
    const currencySymbol = value === 0 ? row.currencySymbol : "";
    return currencySymbol+value;
  }
}

export const getAvailableDeductibleValue = (row, value)=>{
  value = value ? value : "-"
  if(isDeductibleWithoutSymbol(row.requestedDeductibleType)){
    return value;
  }else if(isDeductibleWithPercentSymbol(row.requestedDeductibleType)){
    const currencySymbol = value ? "%" : "";
    return value + currencySymbol;
  }else{
    const currencySymbol =value ? row.currencySymbol : "";
    return currencySymbol + value;
  }
}



export const formatToUSDCommaSeparated = (amount) => {
  
  const returnTypeNumber = (amount) => {
    return amount;
  };

  const returnTypeString = (amount, shouldSplice) => {
    let updatedInput = amount;
    if (shouldSplice) {
      updatedInput = updatedInput.slice(1);
    }

    updatedInput = Number(updatedInput);

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    return formatter.format(updatedInput);
  };

  if (typeof amount === "number") {
    if (amount >= 0) {
      let aaa = amount;
      aaa = aaa.toString();
      return returnTypeString(aaa, false);
    } else return returnTypeNumber(amount);
  } else if (typeof amount === "string") {
    return returnTypeString(amount, true);
  } else return amount;
};

export const getAddress = (item) => {
  if (item && Object.keys(item).length) {
    if (
      item.address1 ||
      item.address2 ||
      item.city ||
      item.stateCode ||
      item.zipCode || 
      item.countryCode
    ) {
      let res = "";
      res = [
        item.address1,
        item.address2,
        item.city,
        item.stateCode,
        item.zipCode,
        item.countryCode
      ]
        .filter(Boolean)
        .join(", ");
      return res;
    } else {
      return "";
    }
  } else {
    return "";
  }
};


export const getSortedProjects = (projects, projectNameField)=>{
  if(_.isEmpty(projects)){
    return projects
  }
  projects.sort((a,b)=>{
    if(b.requestorDefault || a.requestorDefault){
      return b.requestorDefault-a.requestorDefault;
    }

    if(b.agentDefault || a.agentDefault){
      return b.agentDefault-a.agentDefault;
    }
      
    return a[[projectNameField]].localeCompare(b[[projectNameField]]);
  })
  return projects
}

export const getCountByPolicyStatus = (policies, statusKey, source, activeProject, isEvidenceOnly) => {
  try {
    if (policies && policies.length) {
      const status = statusKey ? statusKey : "policyStatus";
      const updatedPolicies = policies.map((p) => ({
        ...p,
        [status]: _.startCase(
          _.toLower(calculatePolicyStatus(p, source, activeProject, status, isEvidenceOnly))
        )
      }));
      const sortedPolicies = _.sortBy(
        _.orderBy(updatedPolicies, status, "asc"),
        status
      );
      return _.groupBy(sortedPolicies, status);
    }
    return {};
  } catch (error) {
    return {};
  }
}

export const isUndefined = (value)=>{
  return value === undefined
}

export const enableUploadCOIButton = (files = [], uploadCoiAgencyDetails = []) => {
  if (uploadCoiAgencyDetails !== undefined) {
    const filteredFiles = _.filter(files, (d) => d.id || d.name);
    const showButton = filteredFiles.some((f) => {
      return (uploadCoiAgencyDetails || []).some(({ agentId, agencyName, toShowCertUploadButton }) => {
        return (agencyName === f.agencyName || agentId === f.agentId) && !!toShowCertUploadButton
      })
    });
    return showButton;
  } else {
    return false;
  }
}

export const getUniqId = ()=>{
  return Math.floor( Math.random() * Math.random() * Date.now())
}

export const isValidNumberForLimits = (data) => {
  if (parseInt(data) === NaN || parseInt(data) === 0 || data === null || data === undefined) {
    return false;
  }
  return true;
}

export const getPolicyStatus = (expiresOn)=>{

  var copiedExpiredDate = new Date(expiresOn);
  let currentDate = new Date();
  copiedExpiredDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const dateDifference = copiedExpiredDate - currentDate
 
  if(dateDifference < 0 ){
    return CERTIFICIAL_STATUS.POLICY_STATUS.EXPIRED
  }

  let dateAfter30Days = new Date(); // Now
  dateAfter30Days.setDate(dateAfter30Days.getDate() + 30); // Set now + 30 days as the new date

  if(copiedExpiredDate > dateAfter30Days){
    return ''
  }

  if(copiedExpiredDate<dateAfter30Days && (dateDifference >= 0)){
    return CERTIFICIAL_STATUS.POLICY_STATUS.EXPIRING
  }
}

export const isFutureDate = (expiresOn)=>{
  let currentDate = new Date()
  let expiredDate = new Date(expiresOn)

  currentDate.setHours(0, 0, 0, 0);
  expiredDate.setHours(0, 0, 0, 0);
  var difference = expiredDate.getTime() - currentDate.getTime();
  return difference >0
}

export const isValidDate = (date)=>{
  let isValid = false
  try{
    const date = moment(date);
    isValid = date.isValid()
  }catch(err){

  }
  return isValid;
}


export const isValidPolicyDate = (date)=>{

    try{
        const dateObject = moment(date, CERTIFICIAL.APP_FORMAT.MM_DD_YYYY, true);
        const thresholdDate = moment(CERTIFICIAL.APP_FORMAT.DATE_THRESHOLD, CERTIFICIAL.APP_FORMAT.MM_DD_YYYY, true);
        return dateObject.isSameOrAfter(thresholdDate);
    }catch(err){
        return false;
    }
}

export const escapeHtmlTags = (payload, formName = '') => {
  try {
    let clonedPayload = {};
    clonedPayload = { ...payload };
    const mapping = SANITIZATION_FIELDS[formName];
    if (mapping?.encodeHTML && mapping?.encodeHTML.length > 0) {
      mapping.encodeHTML.map((key, index) => {
        if (clonedPayload[key]) {
          clonedPayload[key] = escapeOutput(clonedPayload[key]);
        }
      })
    }

    if (mapping?.removeHTML && mapping?.removeHTML.length > 0) {
      mapping.removeHTML.map((key, index) => {
        if (clonedPayload[key]) {
          clonedPayload[key] = removeHTMLTagsFromString(clonedPayload[key]);
        }
      })
    }

    return clonedPayload;
  } catch (error) {
  }
}

const escapeOutput = (toOutput) => {
  const encrypted = toOutput.replace(/\&/g, '&amp;')
    .replace(/\</g, '&lt;')
    .replace(/\>/g, '&gt;')
    .replace(/\"/g, '&quot;')
    .replace(/\'/g, '&#x27;')
    .replace(/\//g, '&#x2F;');
    //console.log('print encrypted--->', encrypted);
    return encrypted;
}

const removeHTMLTagsFromString = (dirtyString = '') => {
  let clean = DOMPurify.sanitize(dirtyString, { USE_PROFILES: { html: false } });
  return clean;
}


export const sortAndFilterCoverageType = (array, userRole, source)=>{
  const excludeOther = array.filter((item) => {
    if(source!=='supplier' && [CERTIFICIAL.STOP_GAP_COVERAGE_TYPE, CERTIFICIAL.EXCESS_COVERAGE_TYPE].includes(item.coverageType) && userRole === APPLICATION_USER_ROLES.REQUESTER){
      return false;
    }
    return (item.coverageType !== "other") && (item.coverageType !==  CERTIFICIAL.MCS90_COVERAGE_TYPE);
  });
  const mcs90 = _.find(array, function (item) {
    return item.coverageType === CERTIFICIAL.MCS90_COVERAGE_TYPE;
  });
  const other = _.find(array, function (item) {
    return item.coverageType === "other";
  });
  const arrayList = _.sortBy(excludeOther, (c) => c.coverageType);
  if (mcs90) {
    arrayList.push(mcs90);
  }

  if (other) {
    arrayList.push(other);
  }
  
  return arrayList;
}



export const checkIfAtLeastOneLimitExists = (policy)=>{
  if(policy.showPropertyLimits){
    return [policy.policyLimitCount, policy.blanketLimitCount, policy.locationLimitCount].some(limitCnt=>parseInt(limitCnt)>0)
  }
  return (policy.limits || []).some(item=>parseInt(item.value) > 0 || parseInt(item.deductible) > 0);

}

export const capitaliseString = (str)=>{
  return str.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
}

export const prepareCopyLinkForComplianceSummary = (projectId, insuredCompanyId, shareId, agentId)=>{
  const path = `${window.location.origin}/requestor-compliance`;
  const parameters = `token=""&projectId=${projectId}&insuredCompanyId=${insuredCompanyId}&shareId=${shareId}&agentId=${agentId}`
  return `${path}?${parameters}`;
}

export const copyTextOnClipBoard = async(copyText)=>{
  if (navigator.clipboard) {
      await navigator.clipboard.writeText(copyText)
  } else if (window.clipboardData) {
      window.clipboardData.setData("Text", copyText);
  }
}

export const validateEmails = (selectedAgents, primaryContactEmail) => {
  let isValidEmails = false;
  let validPrimaryContact = false;
  let validAgents = (selectedAgents || []).map((agentInfo, key) => {
    if (CERTIFICIAL.REGEX_PATTERNS.EMAIL.test(agentInfo.agentEmailId) === false) {
      return false;
    } else {
      return true;
    }
  });

  isValidEmails = !validAgents.some(item => item === false);

  if (!_.isEmpty(primaryContactEmail)) {
    validPrimaryContact = CERTIFICIAL.REGEX_PATTERNS.EMAIL.test(primaryContactEmail)
  }
  return isValidEmails && validPrimaryContact;
}

export const getEarliestDate = (dates = [])=>{
  if(!dates?.length){
    return null
  }

  const earliestDate = (dates.filter(date=>Date.parse(date)>new Date()) || []).sort(function(prevDate, nextDate){
    return new Date(prevDate) - new Date(nextDate);
  });
  return earliestDate?.[0] || null;
}

export const iconSettings = {
  save: { fontSize: 18, color: "#3596db", marginRight: 5, marginLeft: 5 },
  cancel: { fontSize: 18, color: "#d9232c", marginRight: 0 },
  delete: { cursor: 'pointer', fontSize: 18, color: "#d9232c", marginRight: 1, marginLeft: 5 },
  edit: { fontSize: 18, color: "#3596db", marginRight: 3, marginLeft: 5 },
  inputStyle: { width: '88.7%' },
  error: {border: "1px solid red", width: '88%'}
}


export const getEndorsementSentence = (endoName) => {
  const ADDITIONAL_INSURED =
    "Blanket Additional Insured coverage requested for ";
  const WAIVER_OF_SUBROGATION =
    "Blanket Waiver of Subrogation requested for ";
  const NON_CONTRIBUTING =
    "Blanket Primary and Non-Contributory coverage requested for ";
  const LOSS_PAYEE = " Blanket Loss Payee coverage requested for ";
  const LENDER_LOSS_PAYEE = " Blanket Lender’s Loss Payable coverage requested for ";
  const MORTGAGE = " Blanket Mortgagee coverage requested for ";
  const CONTRACT_OF_SALE = " Blanket Contract of Sale coverage requested for "
  switch (endoName) {
    case "additional insured":
      return ADDITIONAL_INSURED;
    case "waiver of subrogation":
      return WAIVER_OF_SUBROGATION;
    case "non-contributory":
      return NON_CONTRIBUTING;
    case "loss payee":
      return LOSS_PAYEE;
    case "lender’s loss payable":
      return LENDER_LOSS_PAYEE;
    case "mortgagee":
      return MORTGAGE;
    case "contract of sale":
      return CONTRACT_OF_SALE;
    default:
      return " "+endoName+" ";
  }
};

export const limitsSortAlphabetically = (data=[], key) => {
  let sorted = data.sort((a, b) => {
    if (a[key].toLowerCase() < b[key].toLowerCase()) {
      return -1;
    }
    if (a[key].toLowerCase() > b[key].toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return sorted || data;
}

export const mergeReferenceDataToPolicy = (policy, policyTypes)=>{
  const applicationRefData = policy.policyTypeId ?  policyTypes.find(_policy=>_policy.id === policy.policyTypeId) :  policyTypes.find(_policy=>_policy.name === policy.policyType) ;
  policy.acordFormDetails = applicationRefData?.acordFormDetails;
  policy.showLenderService = applicationRefData?.showLenderService;
  policy.showPropertyLimits = applicationRefData?.showPropertyLimits; 
}


export const getInsuredType = (insured)=>{
  return insured?.additionalInsured ? INSURED_TYPE.ADDITINAL : INSURED_TYPE.NAMED;
}