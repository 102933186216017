export const MY_ADRESSSBOOK_TYPES = {
  GET_ADDRESSBOOK: {
    SUCCESS: "GET_ADDRESSBOOK_SUCCESS",
    ERROR: "GET_ADDRESSBOOK_ERROR"
  },
  SET_INCLUDE_NOTES_AND_ADDRESS_ON_COI:"SET_INCLUDE_NOTES_AND_ADDRESS_ON_COI",
  POST_ADDRESSBOOK: {
    SUCCESS: "POST_ADDRESSBOOK_SUCCESS",
    ERROR: "POST_ADDRESSBOOK_ERROR"
  },
  GET_ADDRESSES: {
    SUCCESS: "GET_ADDRESSES_SUCCESS",
    ERROR: "GET_ADDRESSES_ERROR"
  },
  CLEAR_ADDRESSBOOK: "CLEAR_ADDRESSBOOK",
  CLEAR_ADDRESSES: "CLEAR_ADDRESSES",
  SET_LOADING: "SET_LOADING",
  SET_NAMED_INSURED: "SET_NAMED_INSURED",
  SET_NAMED_INSURED_PRIMARY_ID: "SET_NAMED_INSURED_PRIMARY_ID",
  SET_NAMED_INSURED_TOTAL_COUNT: "SET_NAMED_INSURED_TOTAL_COUNT",
  CLEAR_DATA_NAMED_INSURED: "CLEAR_DATA_NAMED_INSURED"
};
