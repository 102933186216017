export const ACTION_TYPES = {
  APPLICATION: {
    CLEAR_APPLICATION_DATA: "CLEAR_APPLICATION_DATA",
    CHANGE_USER_ROLE: "CHANGE_USER_ROLE",
    SET_SOCKET:"SET_SOCKET",
    /**
     * Application Reference Data
     */
    REFERENCE_DATA_SUCCESS: "REFERENCE_DATA_SUCCESS",
    REFERENCE_DATA_ERROR: "REFERENCE_DATA_ERROR",
    REFERENCE_DATA_POLICY_TYPE:"REFERENCE_DATA_POLICY_TYPE"
  },
  AUTH: {
    /**
     * User Authentication with token
     */
    REQUEST_FOR_LOGIN: "REQUEST_FOR_LOGIN",
    SET_USER_TOKEN_INFORMATION: "SET_USER_TOKEN_INFORMATION",
    ERROR_ON_USER_INFORMATION: "ERROR_ON_USER_INFORMATION",
    CLEAR_USER_TOKEN_INFORMATION: "CLEAR_USER_TOKEN_INFORMATION",
    REQUEST_FOR_LOGOUT: "REQUEST_FOR_LOGOUT",
    SET_TUTORIAL_POPUP: "HANDLE_TUTORIAL_POPUP",
    SET_FIRST_LOGIN_FLAG: "HANDLE_FIRST_LOGIN_FLAG"
  },
  LIST_LOADING: "LIST_LOADING",
  CLEAR_LIST_LOADING: "RESET_LIST_LOADING",
  SHARED_COMPANY_DETAILS_LOADING: "SHARED_COMPANY_DETAILS_LOADING",
  CLEAR_SHARED_COMPANY_DETAILS_LOADING: "RESET_SHARED_COMPANY_DETAILS_LOADING",
  LIST_DETAILS_LOADING: "LIST_DETAILS_LOADING",
  CLEAR_LIST_DETAILS_LOADING: "RESET_LIST_DETAILS_LOADING",
  CUSTOM_LABEL_LOADING: "CUSTOM_LABEL_LOADING",
  CLEAR_CUSTOM_LABEL_LOADING: "RESET_CUSTOM_LABEL_LOADING",
  GET_DOWNLOAD_COI_SUCCESS: "GET_DOWNLOAD_COI_SUCCESS",
  GET_DOWNLOAD_COI_ERROR: "GET_DOWNLOAD_COI_ERROR",
  CLEAR_DOWNLOAD_COI: "CLEAR_DOWNLOAD_COI",
  SELECTED_NOTIFICATION: "SELECTED_NOTIFICATION",
  DISABLE_WELCOME: "DISABLE_WELCOME",
  SET_OPEN_REQUESTS: "SET_OPEN_REQUESTS",
  SET_PENDING_APPROVAL_COUNT: "SET_PENDING_APPROVAL_COUNT",
  CLIENT_PROJECT_TEMPLATES_FOR_AGENT_SUCESS:
    "CLIENT_PROJECT_TEMPLATES_FOR_AGENT_SUCESS",
  CLEAR_CLIENT_PROJECT_TEMPLATES_FOR_AGENT:
    "CLEAR_CLIENT_PROJECT_TEMPLATES_FOR_AGENT",
  CLIENT_ADDRESS_BOOK_DETAILS_FOR_AGENT_SUCESS:
    "CLIENT_ADDRESS_BOOK_DETAILS_FOR_AGENT_SUCESS",
  CLEAR_CLIENT_ADDRESS_BOOK_DETAILS_FOR_AGENT:
    "CLEAR_CLIENT_ADDRESS_BOOK_DETAILS_FOR_AGENT",
  CLIENT_PROJECT_TEMPLATES_POLICY_SHARE_LIST_AGENT_SUCESS:
    "CLIENT_PROJECT_TEMPLATES_POLICY_SHARE_LIST_AGENT_SUCESS",
  CLEAR_CLIENT_PROJECT_TEMPLATES_POLICY_SHARE_LISTPOLICY_SHARE_LIST_AGENT:
    "CLEAR_CLIENT_PROJECT_TEMPLATES_POLICY_SHARE_LISTPOLICY_SHARE_LIST_AGENT",
  SET_SSO_COMPANYNAME: "SET_SSO_COMPANYNAME_POLICYNUMBER",
  CLEAR_SSO_COMPANYNAME: "CLEAR_SSO_COMPANYNAME",
};
