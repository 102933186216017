import { Button, message, Table, Tabs, Input, Modal, Row, Col, Icon, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react"
import { _modalService } from "../../Utils/wrappers/Modal/ModalWrapper";
import { getActivityNotes, deleteNote, addNote, updateNote, getComplianceReviewData } from "../../Store/Modules/ActivityLog/ActivityLog.asyncRequest";
import { titleMaintainer } from "../../Utils/utility/app.utility";
import InfiniteScroll from "react-infinite-scroller";
import { CERTIFICIAL } from "../../Utils/Constants/Application.constants";
import { CertificialResultsEmpty } from "../..";
import moment from "moment";
import { render } from "less";
const ActivityLog = (props) => {
	const [loading, setLoading] = useState(false);
	const [noteError, setNoteError] = useState([])
	const [activeTab, setActiveTab] = useState("attachments");
	const [allNotes, setAllNotes] = useState([]);
	const [showAddNoteModal, setShowAddNote] = useState(false);
	const [note, setNote] = useState("");
	const [setEditNoteData, setEditedNote] = useState(null);
	const [totalNotes, setTotalNotes] = useState(0)
	const [currentPage, setPage] = useState(1);
	const [complianceReviewData, setComplianceData] = useState([])
	const noteRef = useRef(note);
	const setEditNoteDataRef = useRef(setEditNoteData);
	const notesTableColumns = [
		{
			title: 'Notes',
			dataIndex: 'noteText',
			render: (note) => (
				<>
					<Tooltip placement = {"top"} title={note} >
					{titleMaintainer(note, 100)}
					</Tooltip>
				</>
			),
			width: 300

		},
		{
			title: 'Created Date',
			dataIndex: 'createdOn',
			align: "center",
			render: (data) => {
				let actionDate = moment(data).format("MM-DD-YYYY");
				return (
					<>{actionDate}</>
				)
			}
		},
		{
			title: 'Modified Date',
			dataIndex: 'modifiedOn',
			align: "center",
			render: (data) => {
				let actionDate = moment(data).format("MM-DD-YYYY");
				return (
					<>{actionDate}</>
				)
			}
		},
		{
			title: 'Added By',
			dataIndex: 'addedby',
			render: (item, row, index) => (
				<>
					{row.firstName + " " + row.lastName}
				</>
			),
			align: "center"

		},
		{
			title: (<><Button size={"small"} onClick={() => {
				setShowAddNote(true); noteRef.current = '';
			}} type="primary">Add Note</Button></>),
			dataIndex: 'noteId',
			width: 100,
			render: (id, record, index) => (
				record.userId === props.userId && (<div style={{ fontSize: '18px' }}>

					<span style={{ margin: "0 15px 0 0" }}>
						<Icon
							className="color-primary"
							onClick={() => {
								editNote(id);
								setEditedNote(id);
							}} type="edit"
							theme={"outlined"}
						/>
					</span>

					<span>
						<Icon
							className="color-danger"
							onClick={() => {
								deleteNoteAction(id)
							}}
							type="delete" theme="outlined"
						/>
					</span>

				</div>
				)
			),
			align: "center"

		}
	];

	// useEffect(() => {
	// 	setLoading(true)
	// 	getAllNotes(page);
	// 	setLoading(false); 
	// }, [page]);
	const complianceReviewColumns = [
		{
			title: 'Item Reviewed',
			dataIndex: 'complianceName',
			width: 300
		},
		{
			title: 'Accepted/Denied',
			dataIndex: 'isReviewAccepted',
			render: (data) => {
				return (
					data ? <>Accepted</> : <>Denied</>
				)
			}
		},
		{
			title: 'Effective Through',
			dataIndex: 'expiresDate',
			render: (data) => {
				let actionDate = moment(data).format("MM/DD/YYYY");
				return (
					<>{actionDate}</>
				)
			}
		},
		{
			title: 'Notes',
			dataIndex: 'exceptionComment'
		},
		{
			title: 'Reviewed By',
			dataIndex: 'createdBy'
		},
		{
			title: 'Reviewed On',
			dataIndex: 'createdOn',
			render: (data) => {
				let actionDate = moment(data).format("MM/DD/YYYY");
				return (
					<>{actionDate}</>
				)
			}
		}
	]
	const validateNote = (note) => {
		return !note.trim().length <= 0;
	}

	const getComplianceReviewList = async () => {
		setLoading(true);
		try {
			const complianceReviewData = await getComplianceReviewData({ requestId: props.requestId });
			setComplianceData(complianceReviewData || []);
		} catch (error) {
			message.error('Error occurred while fetching compliance data')
		}
		setLoading(false);
	}

	const getAllNotes = async (pageNo = 1, isPagination = false) => {
		setLoading(true);
		try {
			const notes = await getActivityNotes({
				//insuredCompanyId: props.insuredCompanyId,
				//requestId: props.requestId,
				projectId: props.projectId,
				userId: props.userId,
				currentPage: pageNo,
				rowlimit: CERTIFICIAL.PAGINATION.PAGE_SIZE_10
			});
			//setHasMore(notes?.notesDetails?.length > 0);
			if (isPagination === true) {
				let allNoteClone = [...allNotes];
				let allNotesData = [...allNoteClone, ...notes.notesDetails];
				allNotesData = removeDuplicates(allNotesData, 'noteId');
				setAllNotes(allNotesData);
			} else {
				setAllNotes(notes.notesDetails);
			}
			setTotalNotes(notes.totalCount)

		} catch (err) {
			message.error("Unknown error occurred while fetching notes");
		}
		setLoading(false);
	}

	function removeDuplicates(array, key) {
		const unique = array.filter((item, index, self) =>
			index === self.findIndex((t) => (
				t[key] === item[key]
			))
		);
		return unique;
	}

	const addNoteHandler = async (action = "add", noteId = undefined) => {
		setLoading(true);
		if (validateNote(noteRef.current)) {
			try {
				if (action === 'add') {

					const addNoteRes = await addNote({
						//insuredCompanyId: props.insuredCompanyId,
						projectId: props.projectId,
						//projectId: props.projectId,
						userId: props.userId,
						noteText: noteRef.current
					});
					setAllNotes([]);
					await getAllNotes(1)
					message.success({
						content: 'Note added successfully',
						duration: 3
					});
					setShowAddNote(false)
				}

				if (action === 'update') {
					const updatedRes = await updateNote({
						//insuredCompanyId: props.insuredCompanyId,
						noteId: setEditNoteDataRef.current,
						projectId: props.projectId,
						userId: props.userId,
						noteText: noteRef.current
					});
					setAllNotes([]);
					await getAllNotes(1);
					message.success({
						content: 'Note updated successfully',
						duration: 3
					});
					setShowAddNote(false)
				}
				resetNotes();
			} catch (error) {
				message.error({
					content: 'Unknow Error occurred while doing process',
					duration: 3
				})
			}
			setLoading(false);
		} else {
			setNoteError([noteError, 'Note is required']);
			setLoading(false);
		}

	}

	useEffect(() => {
		setActiveTab('attachments');
		setAllNotes([]);
		getAllNotes(1);
	}, []);

	const onTabChange = async (item) => {
		setLoading(true);
		if (item === 'attachments') {
			setAllNotes([]);
			await getAllNotes(1);
		}

		if (item === 'complianceReview' && props.requestId !== null) {
			await getComplianceReviewList()
		}
		setActiveTab(item);
		setLoading(false);
		setPage(1);
	}
	const deleteNoteHandler = async (id) => {
		setLoading(true);
		try {
			const resp = await deleteNote({ userId: props.userId, noteId: id });
			setAllNotes([]);
			await getAllNotes(1);
			message.success({ duration: 3, content: "Note deleted successfully, refreshing list" });
		} catch (error) {
			message.error({ duration: 3, content: 'Unknown Error occurred while fetching notes.' });
		}
		setLoading(false);
	}

	const deleteNoteAction = (id) => {
		_modalService.confirm({
			title: "Delete Note",
			content: "Would you like to permanently remove this note?",
			onOk: () => {
				deleteNoteHandler(id)
			},
			onCancel: () => {

			},
			okText: "Delete",
			cancelText: 'Cancel'
		})
	}

	const handleInfiniteOnNotesLoad = async () => {
		setLoading(true);
		let pageNo = Math.floor(
			allNotes.length / CERTIFICIAL.PAGINATION.PAGE_SIZE_10
		);
		
		pageNo = pageNo + 1;
		await getAllNotes(pageNo, true);
		setLoading(false);
	}
	const resetNotes = () => {
		setNote(null);
		setEditedNote(null)
		setShowAddNote(false);
		noteRef.current = '';
		setEditNoteDataRef.current = null;
	}

	const editNote = (noteID = undefined) => {
		setEditedNote(noteID);
		setEditNoteDataRef.current = noteID;
		setShowAddNote(true);
	}

	useEffect(() => {
		if (setEditNoteData !== null) {
			const noteData = allNotes.filter(item => item.noteId === setEditNoteData);
			noteRef.current = noteData[0].noteText;
			setNote(noteRef.current);
		}
	}, [setEditNoteData]);

	return (
		<>
			<Tabs 
			size={"small"} 
			activeKey={activeTab} 
			onChange={(e) => { onTabChange(e) }} 
			defaultActiveKey="attachments"
			
			>
				<Tabs.TabPane tab="Notes" key="attachments">
					<div style={{ overflow: 'auto', height: "350px"}}> 
						<InfiniteScroll
							id="scrollableDiv"
							dataLength={allNotes.length}
							initialLoad={false}
							loader = {loading}
							pageStart={0}
							loadMore={handleInfiniteOnNotesLoad}
							//style={{ display: 'flex', flexDirection: 'column-reverse' }}
							hasMore={
								allNotes &&
								allNotes.length < totalNotes
							}
							useWindow={false}
							scrollableTarget="scrollableDiv"
						>
							<Table

								pagination={false}
								size="small"
								//scroll={{ x: 800 }}
								columns={notesTableColumns}
								dataSource={
									allNotes || []
								}
								loading={loading}
								style={{ margin: "0 0 10px 0" }}
								locale = {{emptyText: <><CertificialResultsEmpty text={"No Activities Logged"}/></>}}
							/>
						</InfiniteScroll>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Compliance Review" key="complianceReview">
					<div style={{ overflow: 'auto', height: "360px" }}>
						<Table
							pagination={false}
							size="small"
							//scroll={{ x: 800 }}
							columns={complianceReviewColumns}
							dataSource={
								props.requestId===null? [] : (complianceReviewData || [])
							}
							loading={loading}
							style={{ margin: "0 0 10px 0" }}
						/>
					</div>
				</Tabs.TabPane>
			</Tabs>
			<Modal
				title="Add Note"
				onOk={() => {
					const actionType = setEditNoteDataRef.current !== null ? "update" : "add"
					addNoteHandler(actionType);
				}}
				okText="Submit"
				onCancel={() => { resetNotes() }}
				cancelText='Cancel'
				closable={true}
				visible={showAddNoteModal}
				footer={[
					<Button loading={loading} key="back" onClick={() => { resetNotes() }}>
						Cancel
					</Button>,
					<Button key="submit" type="primary" loading={loading} onClick={() => {
						const actionType = setEditNoteDataRef.current !== null ? "update" : "add"
						addNoteHandler(actionType);
					}}>
						Submit
					</Button>,
				]}
			>
				<>
					<Input.TextArea
						onChange={(e) => {
							setNoteError([]);
							noteRef.current = e.target.value;
						}}
						placeholder="Enter note"
						defaultValue={noteRef.current || null}
						value={noteRef.current || null}
						style={{ height: '250px' }}
					/>
					{noteError.length > 0 && (noteError.map(item => {
						return (<div style={{ color: 'red' }}>
							{item}
						</div>)
					}))}
				</>
			</Modal>
		</>
	)
}

export default ActivityLog;