import { API_PATH } from "./API.paths";

export const BASE_PATH = process.env.REACT_APP_BASE_URL;
const PREFIX = !!process.env.REACT_APP_CONNECTION_TYPE ? "API_LOCAL" : "";
const SUFFIX = !!process.env.REACT_APP_CONNECTION_TYPE ? ".json" : "";
const ENVIRONMENT = !!process.env.REACT_APP_CONNECTION_TYPE
  ? process.env.REACT_APP_CONNECTION_TYPE
  : "deployment";

const WALKME_ENVIRONMENT = !!process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : "dev";

const generateEndPointURL = (END_POINT_URL) => {
  return (
    (!!PREFIX && [BASE_PATH, PREFIX, END_POINT_URL + SUFFIX].join("/")) ||
    [BASE_PATH, END_POINT_URL + SUFFIX].join("/")
  );
};

(function () {
  var walkme = document.createElement("script");
  walkme.type = "text/javascript";
walkme.async = true;
  walkme.src =
    WALKME_ENVIRONMENT === "qa"
      ? "https://cdn.walkme.com/users/3be7b86056a1409ab8207e63a7c316d3/test/walkme_3be7b86056a1409ab8207e63a7c316d3_https.js"
      : "https://cdn.walkme.com/users/3be7b86056a1409ab8207e63a7c316d3/walkme_3be7b86056a1409ab8207e63a7c316d3_https.js";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(walkme, s);
  window._walkmeConfig = { smartLoad: true };
})();

export const ENDPOINTS = {
  AUTHENTICATION: {
    LOGIN: generateEndPointURL(API_PATH[ENVIRONMENT].LOGIN),
    SSO_AUTHENTICATION: generateEndPointURL(API_PATH[ENVIRONMENT].SSO_AUTHENTICATION),
    LOGOUT: generateEndPointURL(API_PATH[ENVIRONMENT].LOGOUT),
    USER: generateEndPointURL(API_PATH[ENVIRONMENT].USER),
    REGISTRATION: generateEndPointURL(API_PATH[ENVIRONMENT].REGISTRATION),
    FORGOT_PASSWORD: generateEndPointURL(API_PATH[ENVIRONMENT].FORGOT_PASSWORD),
    RESET_PASSWORD: generateEndPointURL(API_PATH[ENVIRONMENT].RESET_PASSWORD),
    VERIFY_PASSWORD: generateEndPointURL(API_PATH[ENVIRONMENT].VERIFY_PASSWORD),
    TOGGLE_TUTORIAL_POPUP: generateEndPointURL(
      API_PATH[ENVIRONMENT].TOGGLE_TUTORIAL_POPUP
    ),
    USER_FIRST_LOGIN: generateEndPointURL(
      API_PATH[ENVIRONMENT].USER_FIRST_LOGIN
    ),
    VERIFICARTION_EMAIL_TOKEN: generateEndPointURL(
      API_PATH[ENVIRONMENT].VERIFICARTION_EMAIL_TOKEN
    ),
    REGISTRATION_GRMS_AGENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].REGISTRATION_GRMS_AGENT
    ),
    REFERENCE_COUNTRY: generateEndPointURL(
      API_PATH[ENVIRONMENT].REFERENCE_COUNTRY
    ),
    REFERENCE_REGION: generateEndPointURL(
      API_PATH[ENVIRONMENT].REFERENCE_REGION
    ),
    RESEND_ACTIVATION_LINK: generateEndPointURL(
      API_PATH[ENVIRONMENT].RESEND_ACTIVATION_LINK
    ),
    FETCH_ACTIVE_COMPANY: generateEndPointURL(
      API_PATH[ENVIRONMENT].FETCH_ACTIVE_COMPANY
    ),
    GET_ADDRESS_SEARCH_API_KEY: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ADDRESS_SEARCH_API_KEY
    ),
    GET_ADDRESS_SEARCH_API_KEY_OPEN: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ADDRESS_SEARCH_API_KEY_OPEN
    )
  },
  AGENT_DASHBOARD_INSURED: {
    UPLOAD_NAMED_INSURED_DOC: generateEndPointURL(
      API_PATH[ENVIRONMENT].UPLOAD_NAMED_INSURED_DOC
    ),
    NAMED_INSURED: generateEndPointURL(API_PATH[ENVIRONMENT].NAMED_INSURED),
    DELETE_NAMED_INSURED: generateEndPointURL(API_PATH[ENVIRONMENT].DELETE_NAMED_INSURED),
    NAMED_INSURED_AND_CLIENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].NAMED_INSURED_AND_CLIENT
    ),
    NAMED_INSURED_AVAILABLE: generateEndPointURL(
      API_PATH[ENVIRONMENT].NAMED_INSURED_AVAILABLE
    )
  },

  EMAIL_UPLOAD_COI:{
    VALIDATE:generateEndPointURL(API_PATH[ENVIRONMENT].EMAIL_UPLOAD_COI.VALIDATE),
    UPLOAD:generateEndPointURL(API_PATH[ENVIRONMENT].EMAIL_UPLOAD_COI.UPLOAD),
  },
  ADDRESS_BOOK: {
    AGENT :{
      UPLOAD : generateEndPointURL(API_PATH[ENVIRONMENT].AEGNT_ADDRESS_BOOK.UPLOAD),
      FETCH_GROUPS : generateEndPointURL(API_PATH[ENVIRONMENT].AEGNT_ADDRESS_BOOK.FETCH_GROUPS),
      FETCH_GROUP_DETAILS : generateEndPointURL(API_PATH[ENVIRONMENT].AEGNT_ADDRESS_BOOK.FETCH_GROUP_DETAILS),
      DELETE_GROUP : generateEndPointURL(API_PATH[ENVIRONMENT].AEGNT_ADDRESS_BOOK.DELETE)

    },
    ADDRESS_BOOK: generateEndPointURL(API_PATH[ENVIRONMENT].ADDRESS_BOOK),
    CLIENT_ADDRESS_BOOK: generateEndPointURL(
      API_PATH[ENVIRONMENT].CLIENT_ADDRESS_BOOK
    ),
    GET_ADDRESSES: generateEndPointURL(API_PATH[ENVIRONMENT].GET_ADDRESSES),
    GET_GROUP_ADDRESSES_FOR_A_CLIENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_GROUP_ADDRESSES_FOR_A_CLIENT
    ),
    SEARCH_IN_ADDRESSBOOK: generateEndPointURL(
      API_PATH[ENVIRONMENT].SEARCH_IN_ADDRESSBOOK
    ),
    GET_ADDRESS_SAMPLE_PATH: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ADDRESS_SAMPLE_PATH
    ),
    GET_ADDRESS_SHARE_DETAILS_FOR_AGENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ADDRESS_SHARE_DETAILS_FOR_AGENT
    ),
	NAMED_INSURED_MAP_CLIENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].NAMED_INSURED_MAP_CLIENT
    ),
    NAMED_INSURED_MAPPED_CLIENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].NAMED_INSURED_MAPPED_CLIENT
    )
  },
  MY_TEAM: {
    ADDRESS_BOOK: generateEndPointURL(API_PATH[ENVIRONMENT].ADDRESS_BOOK),
    GET_CONTACTS: generateEndPointURL(API_PATH[ENVIRONMENT].GET_CONTACTS),
    SEARCH_IN_ADDRESSBOOK: generateEndPointURL(
      API_PATH[ENVIRONMENT].SEARCH_IN_ADDRESSBOOK
    ),
    GET_MANAGE_TEAM_TOGGLE: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_MANAGE_TEAM_TOGGLE
    ),
    UPDATE_USER_ROLE: generateEndPointURL(
      API_PATH[ENVIRONMENT].UPDATE_USER_ROLE
    ),

    UPDATE_TEAM_EMAIL:generateEndPointURL(
      API_PATH[ENVIRONMENT].UPDATE_TEAM_EMAIL
    ),

    FETCH_ASSOCIATED_COMPANY: generateEndPointURL(
      API_PATH[ENVIRONMENT].FETCH_ASSOCIATED_COMPANY
    ),
    REGISTRATION_POST_LOGIN: generateEndPointURL(
      API_PATH[ENVIRONMENT].REGISTRATION_POST_LOGIN
    ),
    GET_ALL_USERS: generateEndPointURL(API_PATH[ENVIRONMENT].GET_ALL_USERS)
  },
  AMS_MANAGEMENT: {
    POST_SAGITTA_CLIENT_CONFIG: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_SAGITTA_CLIENT_CONFIG
    ),
    GET_SAMPLETEMPLATE_COMPANYCSV: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_SAMPLETEMPLATE_COMPANYCSV
    )
  },
  APPLICATION: {
    GET_PAUSE_NOTIFICATIONS_REASON: generateEndPointURL(API_PATH[ENVIRONMENT].GET_PAUSE_NOTIFICATIONS_REASON),
    POST_NOTIFICATIONS_REASON: generateEndPointURL(API_PATH[ENVIRONMENT].POST_NOTIFICATIONS_REASON),
    EMAIL_REMOVE_CLENT:{
      REMOVE_CLENT: generateEndPointURL(API_PATH[ENVIRONMENT].EMAIL_REMOVE_CLIENT.REMOVE_CLIENT),
      VALIDATE: generateEndPointURL(API_PATH[ENVIRONMENT].EMAIL_REMOVE_CLIENT.VALIDATE),
    },

    REGENERATE_CAPTCHA: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_REGENERATE_CAPTCHA
    ),
    REFERENCE_DATA: generateEndPointURL(API_PATH[ENVIRONMENT].REFERENCE_DATA),
    GET_NOTIFICATION: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_NOTIFICATION
    ),
    MARK_NOTIFICATION_AS_READ: generateEndPointURL(
      API_PATH[ENVIRONMENT].MARK_NOTIFICATION_AS_READ
    ),
    GET_STATE: generateEndPointURL(API_PATH[ENVIRONMENT].GET_STATE),
    CLIENT_FOR_AGENT_FROM_REQUESTID: generateEndPointURL(
      API_PATH[ENVIRONMENT].CLIENT_FOR_AGENT_FROM_REQUESTID
    ),
    CREATE_CLIENT_FROM_AGENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].CREATE_CLIENT_FROM_AGENT
    ),
    ADD_NEW_SUPPLIER: generateEndPointURL(
      API_PATH[ENVIRONMENT].ADD_NEW_SUPPLIER
    ),
    CHECK_COMPANY_CREATION: generateEndPointURL(
      API_PATH[ENVIRONMENT].CHECK_COMPANY_CREATION
    ),
    EXISTING_USER_VERFICATION: generateEndPointURL(
      API_PATH[ENVIRONMENT].EXISTING_USER_VERFICATION
    ),
    COMPANY_HISTORY: generateEndPointURL(API_PATH[ENVIRONMENT].COMPANY_HISTORY),
    GENERATE_COMPANY_COI: generateEndPointURL(
      API_PATH[ENVIRONMENT].GENERATE_COMPANY_COI
    ),
    POST_GENERATE_COI: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_GENERATE_COI
    ),
    POST_PREVIEW_COI: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_PREVIEW_COI
    ),
    POST_INSURED_AGENT_PREVIEW_COI: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_INSURED_AGENT_PREVIEW_COI
    ),
    DOWNLOAD_COI: generateEndPointURL(API_PATH[ENVIRONMENT].DOWNLOAD_COI),
    UPGRADE_PLAN: generateEndPointURL(API_PATH[ENVIRONMENT].UPGRADE_PLAN),
    GET_ENROLLMENT_TRANSACTION_ID: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ENROLLMENT_TRANSACTION_ID
    ),
    UPGRADE_PROFESSIONAL: generateEndPointURL(
      API_PATH[ENVIRONMENT].UPGRADE_PROFFESSIONAL
    ),
    CONTACT_US: generateEndPointURL(API_PATH[ENVIRONMENT].CONTACT_US),
    DOWNLOAD_UPLOADED_FILE: generateEndPointURL(
      API_PATH[ENVIRONMENT].DOWNLOAD_UPLOADED_FILE
    ),
    GET_POLICY_TYPES_BY_COUNTRY: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_POLICY_TYPES_BY_COUNTRY
    ),
    QUICK_REF_GUIDE: API_PATH[ENVIRONMENT].QUICK_REF_GUIDE,
    QUICK_REF_GUIDE_NAME: API_PATH[ENVIRONMENT].QUICK_REF_GUIDE_NAME,
    MAINTAINANCE: API_PATH[ENVIRONMENT].MAINTAINANCE,
    UPLOAD_SIGNATURE: generateEndPointURL(
      API_PATH[ENVIRONMENT].UPLOAD_SIGNATURE
    ),
    OPEN_REQUEST_COUNT: generateEndPointURL(
      API_PATH[ENVIRONMENT].OPEN_REQUEST_COUNT
    ),
    SUPPLIER_DATA_CHECK: generateEndPointURL(
      API_PATH[ENVIRONMENT].SUPPLIER_DATA_CHECK
    ),
    PENDING_APPROVAL_COUNT: generateEndPointURL(
      API_PATH[ENVIRONMENT].PENDING_APPROVAL_COUNT
    ),
    REQUESTOR_VERIFICATION_PROGRAME: generateEndPointURL(
      API_PATH[ENVIRONMENT].REQUESTOR_VERIFICATION_PROGRAME
    ),
    REQUESTOR_VERIFICATION_COMPANIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].REQUESTOR_VERIFICATION_COMPANIES
    ),
    CREATE_TRANSACTION_FOR_PAYMENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].CREATE_TRANSACTION_FOR_PAYMENT
    ),
    GET_COMPLIANCE_FOR_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_COMPLIANCE_FOR_REQUEST
    ),
    GET_ALL_ADDRESS_BOOKS_FOR_CLIENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ALL_ADDRESS_BOOKS_FOR_CLIENT
    ),
    GET_DOWNLOAD_ALL_COIS: generateEndPointURL(
      API_PATH[ENVIRONMENT].DOWNLOAD_ALL_COIS
    )
  },
  SUPPLIER_ADDRESS_BOOK: {
    SUPPLIER_ADDRESS_BOOK_UPLOAD: generateEndPointURL(
      API_PATH[ENVIRONMENT].SUPPLIER_ADDRESS_BOOK_UPLOAD
    ),
    GET_SUPPLIER_ADDRESS_BOOK_GROUPS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_SUPPLIER_ADDRESS_BOOK_GROUPS
    ),
    GET_GROUP_ADDRESSES_FOR_REQUESTOR: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_GROUP_ADDRESSES_FOR_REQUESTOR
    ),
    DELETE_GROUP_REQUESTOR: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_GROUP_REQUESTOR
    ),
    DELETE_ADDRESS_REQUESTOR: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_ADDRESS_REQUESTOR
    )
  },
  SEARCH: {
    INSURER: generateEndPointURL(API_PATH[ENVIRONMENT].SEARCH.INSURER),
    AGENCIES: generateEndPointURL(API_PATH[ENVIRONMENT].SEARCH.AGENCIES),
    AGENTS: generateEndPointURL(API_PATH[ENVIRONMENT].SEARCH.AGENTS),
    COMPANIES: generateEndPointURL(API_PATH[ENVIRONMENT].SEARCH.COMPANIES),
    ENROLLMENT_COMPANY: generateEndPointURL(
      API_PATH[ENVIRONMENT].SEARCH.ENROLLMENT_COMPANY
    )

  },
  ACTIVITY_LOG: {
    GET_ALL_NOTES: generateEndPointURL(API_PATH[ENVIRONMENT].GET_ALL_ACTIVITY_LOG_NOTES),
    UPDATE_NOTES: generateEndPointURL(API_PATH[ENVIRONMENT].UPDATE_ACTIVITY_LOG_NOTES_DETAILS),
    ADD_NOTES: generateEndPointURL(API_PATH[ENVIRONMENT].ADD_ACTIVITY_LOG_NOTES_DETAILS),
    GET_ALL_COMPLIANCE: generateEndPointURL(API_PATH[ENVIRONMENT].GET_ALL_ACTIVITY_LOG_COMPLIANCE_DETAILS),
    DELETE_NOTES: generateEndPointURL(API_PATH[ENVIRONMENT].DELETE_ACTIVITY_LOG_NOTES_DETAILS),
  },
  MY_POLICIES: {
    GET_ALL_POLICIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ALL_POLICIES
    ),
    GET_POLICY: generateEndPointURL(API_PATH[ENVIRONMENT].GET_POLICY),
    ADD_POLICY: generateEndPointURL(API_PATH[ENVIRONMENT].ADD_POLICY),
    UPDATE_POLICY: generateEndPointURL(API_PATH[ENVIRONMENT].UPDATE_POLICY),
    DELETE_POLICY: generateEndPointURL(API_PATH[ENVIRONMENT].DELETE_POLICY),
    GET_PROJECT_TEMPLATES: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_PROJECT_TEMPLATES
    ),
    DELETE_PROJECT_TEMPLATE: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_PROJECT_TEMPLATE
    ),
    CREATE_PROJECT_TEMPLATE: generateEndPointURL(
      API_PATH[ENVIRONMENT].CREATE_PROJECT_TEMPLATE
    ),
    GET_USER_COMPANY_PROJECTS_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_USER_COMPANY_PROJECTS_LIST
    ),
    CREATE_USER_COMPANY_PROJECT: generateEndPointURL(
      API_PATH[ENVIRONMENT].CREATE_USER_COMPANY_PROJECT
    ),
    UPDATE_USER_COMPANY_PROJECT: generateEndPointURL(
      API_PATH[ENVIRONMENT].UPDATE_USER_COMPANY_PROJECT
    ),
    DELETE_USER_COMPANY_PROJECT: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_USER_COMPANY_PROJECT
    ),
    VERIFY_INSURER: generateEndPointURL(API_PATH[ENVIRONMENT].VERIFY_INSURER),
	DOWNLOAD_COIS_ZIP: generateEndPointURL(API_PATH[ENVIRONMENT].DOWNLOAD_COIS_ZIP),
    DELETE_CLIENT_POLICY: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_CLIENT_POLICY
    ),
    ADD_LOCATION_LIMIT: generateEndPointURL(
      API_PATH[ENVIRONMENT].ADD_LOCATION_LIMIT
    ),
    GET_LOCATIONS: generateEndPointURL(API_PATH[ENVIRONMENT].GET_LOCATIONS),
    GET_COMMON_LOCATION:generateEndPointURL(API_PATH[ENVIRONMENT].GET_COMMON_LOCATION),
    GET_SELECTED_LOCATIONS_POLICY: generateEndPointURL(API_PATH[ENVIRONMENT].GET_SELECTED_LOCATIONS_POLICY),
    GET_LOCATION_BUILDINGS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_LOCATION_BUILDINGS
    ),
    POST_LOCATION: generateEndPointURL(API_PATH[ENVIRONMENT].POST_LOCATION),
    PROPERTY_LIMITS_BY_KIND: generateEndPointURL(
      API_PATH[ENVIRONMENT].PROPERTY_LIMITS_BY_KIND
    )
  },
  USER: {
    PROFILE: generateEndPointURL(API_PATH[ENVIRONMENT].PROFILE),
    UPDATE_USER_PROFILE: generateEndPointURL(
      API_PATH[ENVIRONMENT].UPDATE_USER_PROFILE
    ),
    UPDATE_PASSWORD: generateEndPointURL(API_PATH[ENVIRONMENT].UPDATE_PASSWORD),
    DELETE_AGENT_SIGNTURE: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_AGENT_SIGNTURE
    ),
    EDIT_COMPANY_PROFILE: generateEndPointURL(
      API_PATH[ENVIRONMENT].EDIT_COMPANY_PROFILE
    ),
    DELETE_COMPANY_LOGO: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_COMPANY_LOGO
    ),
    GET_ALL_USERS: generateEndPointURL(API_PATH[ENVIRONMENT].GET_ALL_USERS)
  },

  REQUESTOR_DASHBOARD: {
    TRACKED_COMPANIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].TRACKED_COMPANIES
    ),
    TRACKED_POLICIES_COMPANY_BY_ID: generateEndPointURL(
      API_PATH[ENVIRONMENT].TRACKED_POLICIES_COMPANY_BY_ID
    ),
    GET_AGENT_LIST: generateEndPointURL(API_PATH[ENVIRONMENT].GET_AGENT),
    GET_AGENT_AMS_LIST: generateEndPointURL(API_PATH[ENVIRONMENT].GET_AGENT_AMS_LIST),
    CANCEL_TRACKED_POLICIES_BY_ID: generateEndPointURL(
      API_PATH[ENVIRONMENT].CANCEL_TRACKED_POLICIES_BY_ID
    ),
    HANDLE_EXCEPTION_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].HANDLE_EXCEPTION_REQUEST
    ),
    SHOW_DOC_UPDATE_HISTORY: generateEndPointURL(
      API_PATH[ENVIRONMENT].SHOW_DOC_UPDATE_HISTORY
    ),

    DELETE_DOC_HISTORY_DATA: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_DOC_HISTORY_DATA
    ),

    DOC_UPDATED_HISTORY: generateEndPointURL(
      API_PATH[ENVIRONMENT].DOC_UPDATED_HISTORY
    ),
    DELETE_EXCEPTION_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_EXCEPTION_REQUEST
    ),
    GET_EXCEPTION_DETAILS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_EXCEPTION_DETAILS
    ),
    GET_COMPANY_STATUS_DETAILS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_COMPANY_STATUS_DETAILS
    ),
    GET_COMPANY_GET_STATUS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_COMPANY_GET_STATUS
    ),
    SAVE_COMPLIANCE_STATUS: generateEndPointURL(
      API_PATH[ENVIRONMENT].SAVE_COMPLIANCE_STATUS
    ),
    GET_COMPLIANCE_TIPS_POPUP_STATUS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_COMPLIANCE_TIPS_POPUP_STATUS
    ),
    SAVE_COMPLIANCE_TIPS_POPUP_STATUS: generateEndPointURL(
      API_PATH[ENVIRONMENT].SAVE_COMPLIANCE_TIPS_POPUP_STATUS
    ),
    CHANGE_TEMPLATE: generateEndPointURL(API_PATH[ENVIRONMENT].CHANGE_TEMPLATE),
    UPDATE_SUPPLIER_CONTACT_INFO: generateEndPointURL(API_PATH[ENVIRONMENT].PUT_SUPPLIER_CONTACT_INFO),
    GET_TEMPLATE_LIST_REGION: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_TEMPLATE_LIST_REGION
    ),
    GET_SUPPLIER_AGENT_INFO: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_SUPPLIER_AGENT_INFO
    ),
    CREATE_POLICY_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].CREATE_POLICY_REQUEST
    ),
    NAMED_INSURED_SELECTION: generateEndPointURL(
      API_PATH[ENVIRONMENT].NAMED_INSURED_SELECTION
    ),
    SEARCH_COI_ID_DATA: generateEndPointURL(
      API_PATH[ENVIRONMENT].SEARCH_COI_ID_DATA
    ),
    NAMED_INSURED_SELECTION_AND_CLIENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].NAMED_INSURED_SELECTION_AND_CLIENT
    ),
    NAMED_INSURED_CHECK_INSURED_IS_PRIMARY_IN_SHARE: generateEndPointURL(
      API_PATH[ENVIRONMENT].NAMED_INSURED_CHECK_INSURED_IS_PRIMARY_IN_SHARE
    ),

    SUPPLIER_PROJECT_LIST:generateEndPointURL(
      API_PATH[ENVIRONMENT].SUPPLIER_PROJECT_LIST
    ),

    CHARTS:{
      GET_RESPONSE_RATE_DATA:generateEndPointURL(
        API_PATH[ENVIRONMENT].CHARTS.GET_RESPONSE_RATE_DATA
      ),
      GET_COMPLIANCE_RATE_DATA:generateEndPointURL(
        API_PATH[ENVIRONMENT].CHARTS.GET_COMPLIANCE_RATE_DATA
      ),
      GET_COMPLIANCE_BY_TEMPLATE_DATA:generateEndPointURL(
        API_PATH[ENVIRONMENT].CHARTS.GET_COMPLIANCE_BY_TEMPLATE_DATA
      ),  
      GET_COMPLIANCE_BY_COVERAGE_TYPE_DATA:generateEndPointURL(
        API_PATH[ENVIRONMENT].CHARTS.GET_COMPLIANCE_BY_COVERAGE_TYPE_DATA
      )  
    }    
  },
  SHARE_POLICY: {
    SHARE_POLICY_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].SHARE_POLICY_LIST
    ),
    MY_SHAREABLE_POLICIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].MY_SHAREABLE_POLICIES
    ),
    MY_RESHAREABLE_POLICIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].MY_RESHAREABLE_POLICIES
    ),
    GET_ALL_SHARED_DOCS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ALL_SHARED_DOCS
    ),
    SHARE_MY_POLICIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].SHARE_MY_POLICIES
    ),
    SHARE_POLICY_GRAPH: generateEndPointURL(
      API_PATH[ENVIRONMENT].SHARE_POLICY_GRAPH
    ),
    SHARE_POLICY_COUNT: generateEndPointURL(
      API_PATH[ENVIRONMENT].SHARE_POLICY_COUNT
    ),
    SHARE_POLICY_GRAPH_COMPANY: generateEndPointURL(
      API_PATH[ENVIRONMENT].SHARE_POLICY_GRAPH_COMPANY
    ),
	DOWNLOAD_DOCUMENTS_ZIP: generateEndPointURL(
      API_PATH[ENVIRONMENT].DOWNLOAD_DOCUMENTS_ZIP
    ),
    SHARE_POLICY_DELETE: generateEndPointURL(
      API_PATH[ENVIRONMENT].SHARE_POLICY_DELETE
    ),
    REMOVE_EOI_POLICY:generateEndPointURL(
      API_PATH[ENVIRONMENT].REMOVE_SHARED_POLICY
    ),
    SHARE_ENABLE: generateEndPointURL(API_PATH[ENVIRONMENT].SHARE_ENABLE),
    COMPANY_AVAILABLE_PROJECT_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].COMPANY_AVAILABLE_PROJECT_LIST
    ),
    UPDATE_EMAIL: generateEndPointURL(
      API_PATH[ENVIRONMENT].UPDATE_EMAIL
    ),
    EDIT_CC_OPTION: generateEndPointURL(API_PATH[ENVIRONMENT].EDIT_CC_OPTION),
    CHECK_ASSOCIATE_COMPANY: generateEndPointURL(
      API_PATH[ENVIRONMENT].CHECK_ASSOCIATE_COMPANY
    ),
    GET_CERTIFICATE_UPLOAD_STATUS_RE_RESPOND: generateEndPointURL(
      API_PATH[ENVIRONMENT].CERTIFICATE_UPLOAD_STATUS_RE_RESPOND
    ),
    GET_CLIENT_PROJECT_TEMPLATES_FOR_AGENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_CLIENT_PROJECT_TEMPLATES_FOR_AGENT
    )
  },
  TEMPLATE: {
    GET_TEMPLATE_LATEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_TEMPLATE_LATEST
    ),
    GET_TEMPLATE: generateEndPointURL(API_PATH[ENVIRONMENT].GET_TEMPLATE),
    GET_COI_BUTTON_ENABLE: generateEndPointURL(API_PATH[ENVIRONMENT].GET_COI_BUTTON_ENABLE),
    CONFIRM_COI: generateEndPointURL(API_PATH[ENVIRONMENT].CONFIRM_COI),
    GET_TEMPLATE_DETAIL: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_TEMPLATE_DETAIL
    ),
    GET_COVEREAGETYPES: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_COVEREAGETYPES
    ),
    POST_REQUIREMENT_TEMPLATE_ADD: generateEndPointURL(
      API_PATH[ENVIRONMENT].REQUIREMENT_TEMPLATE_ADD
    ),
    PUT_REQUIREMENT_TEMPLATE_EDIT: generateEndPointURL(
      API_PATH[ENVIRONMENT].REQUIREMENT_TEMPLATE_EDIT
    ),
    DELETE_REQUIREMENT_TEMPLATE: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_TEMPLATE
    ),
    GET_REQUIREMENT_TEMPLATE_HISTORY: generateEndPointURL(
      API_PATH[ENVIRONMENT].REQUIREMENT_TEMPLATE_HISTORY
    ),
    TEMPLATE_VERSION_OPEN_DRAWER: generateEndPointURL(
      API_PATH[ENVIRONMENT].TEMPLATE_VERSION_OPEN_DRAWER
    )
  },
  PENDING_INSURED_REQUEST: {
    GET_ALL_INSURED_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ALL_INSURED_REQUEST
    ),
    GET_INSURED_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_INSURED_REQUEST_DETAILS
    ),
    GET_INSURER_PENDING_REQUEST_DETAILS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_INSURER_PENDING_REQUEST_DETAILS
    ),
    INSURED_REQUEST_DENY: generateEndPointURL(
      API_PATH[ENVIRONMENT].INSURED_REQUEST_DENY
    ),
    RESPOND_REQUEST_SUBMIT: generateEndPointURL(
      API_PATH[ENVIRONMENT].RESPOND_REQUEST_SUBMIT
    ),
    RESPOND_REQUEST_SHAREABLE_POLICIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].RESPOND_REQUEST_SHAREABLE_POLICIES
    ),
    RE_RESPOND_REQUEST_SHAREABLE_POLICIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].RE_RESPOND_REQUEST_SHAREABLE_POLICIES
    ),
    RESPOND_REQUEST_SHAREABLE_DOCS: generateEndPointURL(
      API_PATH[ENVIRONMENT].RESPOND_REQUEST_SHAREABLE_DOCS
    ),
    GET_COI_INFO_BY_TEMPLATE_ID: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_COI_INFO_BY_TEMPLATE_ID
    ),
    RESPOND_COMPLIANCE_SUMMARY: generateEndPointURL(
      API_PATH[ENVIRONMENT].RESPOND_COMPLIANCE_SUMMARY
    ),
    GET_CERTIFICATE_UPLOAD_STATUS: generateEndPointURL(
      API_PATH[ENVIRONMENT].CERTIFICATE_UPLOAD_STATUS
    ),
    POST_CERTIFICATE_INSURANCE: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_CERTIFICATE_INSURANCE
    ),
    POST_CERTIFICATE_UPLOAD_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_CERTIFICATE_UPLOAD_REQUEST
    ),
    POST_COI_UPLOAD: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_COI_UPLOAD_REQUEST
    )
  },

  SEARCH_COMPANIES: {
    ON_DEMAND_GET_COMPANIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].ON_DEMAND_GET_COMPANIES
    ),
    ON_DEMAND_GET_AGENCY: generateEndPointURL(
      API_PATH[ENVIRONMENT].ON_DEMAND_GET_AGENCY
    ),
    VARIFY_COMPANY_BY_EMAIL: generateEndPointURL(
      API_PATH[ENVIRONMENT].VARIFY_COMPANY_BY_EMAIL
    ),
    SEARCH_ADDRESSBOOK_EMAIL: generateEndPointURL(
      API_PATH[ENVIRONMENT].SEARCH_ADDRESSBOOK_EMAIL
    ),
    SEARCH_ADDRESSBOOK_COMPANY: generateEndPointURL(
      API_PATH[ENVIRONMENT].SEARCH_ADDRESSBOOK_COMPANY
    )
  },
  MY_PENING_USERS: {
    GET_ALL_PENDING_USER_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ALL_PENDING_USER_REQUEST
    ),
    POST_PENDING_USER_ITEM_ACTION: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_PENDING_USER_ITEM_ACTION
    ),
    POST_INVITE_USER_EMAIL_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_INVITE_USER_EMAIL_REQUEST
    )
  },
  UPLOAD: {
    SINGLE_FILE: generateEndPointURL(API_PATH[ENVIRONMENT].UPLOAD_SINGLE_FILE),
    MULTIPLE_FILE: generateEndPointURL(
      API_PATH[ENVIRONMENT].UPLOAD_MULTIPLE_FILE
    )
  },
  UPLOADCERT: {
    GET_UPLOADCERT_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_UPLOADCERT_LIST
    ),
    PDF_FILE_UPLOAD: generateEndPointURL(API_PATH[ENVIRONMENT].PDF_FILE_UPLOAD),
    EXCEL_FILE_UPLOAD: generateEndPointURL(
      API_PATH[ENVIRONMENT].EXCEL_FILE_UPLOAD
    ),
    GET_TRANSACTION_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].TRANSACTION_LIST
    )
  },
  AGENT_BY_USER: {
    USER_PROFILE_AGENTS: generateEndPointURL(
      API_PATH[ENVIRONMENT].USER_PROFILE_AGENTS
    )
  },
  ADD_AGENT: {
    GET_AGENT_LIST: generateEndPointURL(API_PATH[ENVIRONMENT].GET_AGENT_LIST),
    POST_ADD_AGENT_TO_USER: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_ADD_AGENT_TO_USER
    ),
    POST_ADD_AGENCY_TO_USER: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_ADD_AGENCY_TO_USER
    ),
    INVITE_AGENT_BY_EMAIL: generateEndPointURL(
      API_PATH[ENVIRONMENT].INVITE_AGENT_BY_EMAIL
    ),
    UPDATE_AGENT_PRIORITY: generateEndPointURL(
      API_PATH[ENVIRONMENT].UPDATE_AGENT_PRIORITY
    )
  },
  REQUESTOR_REQUEST_POLICY: {
    GET_ALL_VERIFICATION_REQUESTS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ALL_VERIFICATION_REQUESTS
    ),
    GET_REQUESTED_POLICIES: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_REQUESTED_POLICIES
    ),
    GET_VERIFICATION_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_VERIFICATION_REQUEST
    ),
    DELETE_VERIFICATION_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_VERIFICATION_REQUEST
    ),
    APPROVE_VERIFICATION_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].APPROVE_VERIFICATION_REQUEST
    ),
    DENY_VERIFICATION_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].DENY_VERIFICATION_REQUEST
    ),
    CREATE_VERIFICATION_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].CREATE_VERIFICATION_REQUEST
    ),
    VALIADTE_PROJECTNAME_SUPPLIER_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].VALIADTE_PROJECTNAME_SUPPLIER_REQUEST
    )
  },
  AGENT_DASHBOARD: {
    GET_COMPANIES_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_AGENT_COMPANIES_LIST
    ),
    GET_CLIENT_COMPANIES_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_CLIENT_COMPANIES_LIST
    ),
    GET_AGENT_POLICY_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_AGENT_POLICY_LIST
    ),
    GET_CLIENT_POLICY_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_CLIENT_POLICY_LIST
    ),
    UPDATE_POLICY: generateEndPointURL(API_PATH[ENVIRONMENT].UPDATE_POLICY),
    ACTIONS: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_AGENT_POLICY_ACTION
    ),
    ISSUE_COI: generateEndPointURL(API_PATH[ENVIRONMENT].ISSUE_COI),
    ISSUE_COI_FOR_SHARE: generateEndPointURL(
      API_PATH[ENVIRONMENT].ISSUE_COI_FOR_SHARE
    ),
    AGENT_DELETE_SHARE: generateEndPointURL(
      API_PATH[ENVIRONMENT].AGENT_DELETE_SHARE
    ),
    AGENT_TRANSFER: generateEndPointURL(API_PATH[ENVIRONMENT].AGENT_TRANSFER),
    AGENT_AUTO_TRANSFER: generateEndPointURL(API_PATH[ENVIRONMENT].AGENT_AUTO_TRANSFER),
    GET_CERT_HOLDERS_FOR_A_CLIENT: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_CERT_HOLDERS_FOR_A_CLIENT
    ),
    BULK_SHARE_POLICY: generateEndPointURL(
      API_PATH[ENVIRONMENT].BULK_SHARE_POLICY
    ),
    REPLACE_SHARED_POLICY: generateEndPointURL(
      API_PATH[ENVIRONMENT].REPLACE_SHARED_POLICY
    ),
    GET_COMPLIANTCOUNT: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_COMPLIANTCOUNT
    ),
    CREATE_AGENT_TEMPLATE: generateEndPointURL(
      API_PATH[ENVIRONMENT].CREATE_AGENT_TEMPLATE
    ),
    EDIT_AGENT_TEMPLATE: generateEndPointURL(
      API_PATH[ENVIRONMENT].EDIT_AGENT_TEMPLATE
    ),
    TEMPLATE_AVAIALABLE_FOR_INSURED_USE:generateEndPointURL(
      API_PATH[ENVIRONMENT].TEMPLATE_AVAIALABLE_FOR_INSURED_USE
    ),
    DELETE_AGENT_PROJECT_TEMPLATE: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_AGENT_PROJECT_TEMPLATE
    ),
    GET_AGENT_TEMPLATES: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_AGENT_TEMPLATES
    ),
    GET_ALL_ADDRESS_BOOK_GROUP_LIST_SHARE: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ALL_ADDRESS_BOOK_GROUP_LIST_SHARE
    ),
    GET_CLIENT_ADDRESS_BOOK_GROUP_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_CLIENT_ADDRESS_BOOK_GROUP_LIST
    ),
    DELETE_CLIENT_ADDRESS_GROUP: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_CLIENT_ADDRESS_GROUP
    ),
    DELETE_ADDRESSESS_OF_CLIENT_ADDRESS_GROUP: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_ADDRESSESS_OF_CLIENT_ADDRESS_GROUP
    ),
    DELETE_ADDRESSESS_OF_AGENCY_ADDRESS_BOOK: generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_ADDRESSESS_OF_AGENCY_ADDRESS_BOOK
    ),
    POST_AGENT_UNAVAILABLE_COVERAGE: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_UNAVAILABLE_COVERAGE
    ),
    GET_VEHICLES:generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_VEHICLES
    )
  },
  AGENT_PENDING: {
    GET_REQUEST_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_AGENT_REQUEST_LIST
    ),
    GET_POLICY_REQUEST_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_POLICY_REQUEST_LIST
    ),
    POLICY_LOCATION_APPROVAL: generateEndPointURL(
      API_PATH[ENVIRONMENT].POLICY_LOCATION_APPROVAL
    ),
    UPDATE_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_AGENT_REQUEST_POLICY_ACTION
    ),
    GET_RESPOND_REQUEST_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_AGENT_RESPOND_LIST
    ),
    GET_POLICY_RESPOND_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_POLICY_RESPOND_LIST
    ),
    UPDATE_RESPOND_REQUEST: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_AGENT_RESPOND_POLICY_ACTION
    ),
    GET_INSURED_LIST_TO_RESPOND: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_INSURED_LIST_TO_RESPOND
    ),
    GET_ALL_PROJECTS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ALL_PROJECTS
    ),
    GET_ALL_REQUEST_PROJECTS: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_ALL_REQUEST_PROJECTS
    )
  },
  REQUESTORCOMPLIANCE: generateEndPointURL(
    API_PATH[ENVIRONMENT].REQUESTORCOMPLIANCE
  ),
  CHANGE_TEMPLATE_VALIDATE: generateEndPointURL(
    API_PATH[ENVIRONMENT].CHANGE_TEMPLATE_VALIDATE
  ),
  POST_ARCHIVE_CLIENT: generateEndPointURL(
    API_PATH[ENVIRONMENT].POST_ARCHIVE_CLIENT
  ),

  PROGRESS: generateEndPointURL(
    API_PATH[ENVIRONMENT].PROGRESS
  ),

  PROJECTS:{
    ADD:generateEndPointURL(
      API_PATH[ENVIRONMENT].ADD_NEW_PROJECT
    ),
    DELETE:generateEndPointURL(
      API_PATH[ENVIRONMENT].DELETE_PROJECT
    ),
    GET:generateEndPointURL(
      API_PATH[ENVIRONMENT].FETCH_ALL_PROJECTS
    ),
    UPDATE:generateEndPointURL(
      API_PATH[ENVIRONMENT].UPDATE_PROJECT
    ),
  },

  MANAGE_COMP_FILTER:{
      COVERAGE_TEMPLATE_LIST: generateEndPointURL(
        API_PATH[ENVIRONMENT].COVERAGE_TEMPLATE_LIST
      ),
      PRIMARY_INSURES_LIST: generateEndPointURL(
        API_PATH[ENVIRONMENT].PRIMARY_INSURES_LIST
    ),
  },

  SUPPLIER_FILTERS:{
    POLICY_TYPE_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].SUPPLIER_FILTERS.POLICY_TYPE_LIST
    ),
  
    TEMPLATE_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].SUPPLIER_FILTERS.TEMPLATE_LIST
    ),

    PROJECT_LIST:generateEndPointURL(
      API_PATH[ENVIRONMENT].SUPPLIER_FILTERS.PROJECT_LIST
    ),
  },

  REQUEST_COVERAGE_FILTERS:{
    POLICY_TYPE_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].REQUEST_COVERAGE_FILTERS.POLICY_TYPE_LIST
    ),
  
    TEMPLATE_LIST: generateEndPointURL(
      API_PATH[ENVIRONMENT].REQUEST_COVERAGE_FILTERS.TEMPLATE_LIST
    ),

    PROJECT_LIST:generateEndPointURL(
      API_PATH[ENVIRONMENT].REQUEST_COVERAGE_FILTERS.PROJECT_LIST
    ),
  },

  COMPLIANCE_OVERRIDE:{
    GET: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_COMPLIANCE_OVERRIDE
    ),
  
    POST: generateEndPointURL(
      API_PATH[ENVIRONMENT].POST_COMPLIANCE_OVERRIDE
    ),
  },

  REQUESTOR_CHANGE_PROJECT_TEMPLATE: generateEndPointURL(
    API_PATH[ENVIRONMENT].REQUESTOR_CHANGE_PROJECT_TEMPLATE
  ),
  REQUESTOR_ADD_REQUEST_PROJECT_LIST: generateEndPointURL(
    API_PATH[ENVIRONMENT].REQUESTOR_ADD_REQUEST_PROJECT_LIST
  ),
  REQUESTOR_DASHBOARD_URL:generateEndPointURL(
    API_PATH[ENVIRONMENT].REQUESTOR_DASHBOARD_URL
  ),

  SMART_COI:{
    GET_EXTRACTED_DATA: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_EXTRACTED_DATA
    ),

    GET_INACTIVE_POLICIES_DATA: generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_INACTIVE_POLICIES_DATA
    ),

    SAVE_EXTRACTED_DATA:generateEndPointURL(
      API_PATH[ENVIRONMENT].SAVE_EXTRACTED_DATA
    ),

    OPT_OUT_UPLOAD_COI:generateEndPointURL(
      API_PATH[ENVIRONMENT].OPT_OUT_COI_UPLOAD
    ),
  },
  MERGE_CLIENTS:{
    GET_EXISTING_CLIENT:generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_EXISTING_CLIENT
    ),

    GET_MERGE_PROGRESS:generateEndPointURL(
      API_PATH[ENVIRONMENT].GET_MERGE_PROGRESS
    ),

    EXECUTE:generateEndPointURL(
      API_PATH[ENVIRONMENT].MERGE_CLIENTS
    ),

    SEND_FAILURE_REPORT:generateEndPointURL(
      API_PATH[ENVIRONMENT].MERGE_CLIENTS_FAILURE_REPORT
    )
  },

  AUTO_RESPONSE:{
    VALIDATE_LINK:generateEndPointURL(
      API_PATH[ENVIRONMENT].AUTO_RESPONSE.VALIDATE_LINK
    ),
    
    OPT_IN:generateEndPointURL(
      API_PATH[ENVIRONMENT].AUTO_RESPONSE.OPT_IN
    ),

    OPT_IN_WITH_RESPOND:generateEndPointURL(
      API_PATH[ENVIRONMENT].AUTO_RESPONSE.OPT_IN_WITH_RESPOND
    ),

    GET_AGENCY_LEVEL_CONFIGURE:generateEndPointURL(
      API_PATH[ENVIRONMENT].AUTO_RESPONSE.GET_AGENCY_LEVEL_CONFIGURE
    ),
    SET_AGENCY_LEVEL_CONFIGURE:generateEndPointURL(
      API_PATH[ENVIRONMENT].AUTO_RESPONSE.SET_AGENCY_LEVEL_CONFIGURE
    ),
  },

  CHARTS:{
    GET_RESPONSE_RATE:generateEndPointURL(
      API_PATH[ENVIRONMENT].CHARTS.GET_RESPONSE_RATE
    )
  },
  BROAD_SUPPLIER_SEARCH: generateEndPointURL(
    API_PATH[ENVIRONMENT].BROAD_SUPPLIER_SEARCH
  )
};
