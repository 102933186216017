import { ConfigProvider, Empty, Icon } from "antd";
import enUS from "antd/es/locale/en_US";
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import App from "./App";
import { emptyImage } from "./Assets/assets";
import "./index.less";
import * as serviceWorker from "./serviceWorker";
import { configureStore } from "./Store/ConfigureStore";
import { appHistory } from "./Utils/Helpers/History";

const store = configureStore();
Icon.setTwoToneColor("#3596db");

export const CertificialResultsEmpty = ({ image, imageHeight, text }) => (
  <Empty
    image={image || emptyImage}
    imageStyle={{
      height: imageHeight || 55
    }}
    description={
      <span
        style={{
          color: "rgba(0, 0, 0, 0.65)",
          fontSize: "0.85rem",
          fontWeight: 500,
          fontStyle: "italic"
        }}
      >
       {text || " No data available"}
        {/* {APPLICATION_MESSAGES.AGENT.AGENT_DASHBOARD_PAGE.NO_POLICIES_AVAILABLE} */}
      </span>
    }
  />
);

const Application = () => (
  <div>
    <ConfigProvider locale={enUS} renderEmpty={CertificialResultsEmpty}>
      <Provider store={store}>
        <Router history={appHistory}>
          <App />
        </Router>
      </Provider>
    </ConfigProvider>
  </div>
);

ReactDOM.render(<Application />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
