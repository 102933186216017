import empty from "./Images/empty-blue.svg";
import emptyAlt from "./Images/empty.png";
import bellSnooze from "./Images/bell-snooze-w40.svg";
import errorPicture from "./Images/error.png";
import refresh from "./Images/refresh.svg";
import cancel from "./Images/cancel.svg";
import maintainance from "./Images/maintainance.svg";
import template from "./Images/forms.png";
import exception from "./Images/exception.png";
import success from "./Images/success.png";
import save from "./Images/save.png";
import mapPin from "./Images/mapPin.png";
import location from "./Images/location.png";
import bookmark from "./Images/bookmark.svg";
import bookmarkAlt from "./Images/bookmark-alt.svg";
import bookmarkCompliance from "./Images/bookmark-compliance.svg";
import calendar from "./Images/calendar.svg";
import agency from "./Images/agency.png";
import note from "./Images/note.png";
import table from "./Images/table.png";
import remarks from "./Images/remarks.png";
import remarksAlt from "./Images/remarks-alt.png";
import coverage from "./Images/Coverage.png";
import coverageAlt from "./Images/coverage-alt.svg";
import star from "./Images/star.png";
import starAlt from "./Images/star-alt.png";
import starGreen from "./Images/star-green.png";
import instructions from "./Images/instructions.png";
import showDocuments from "./Images/show-documents.png";
import edit from "./Images/edit.png";
import delete_Icon from "./Images/delete.png";
import viewDetails from "./Images/view-details.png";
import approval from "./Images/approval.png";
import respond from "./Images/respond.png";
import reject from "./Images/reject.svg";
import handleException from "./Images/handle-exception.png";
import noImageFound from "./Images/no-image.png";
import notification from "./Images/google-alerts.png";
import profileCompletion from "./Images/profile-completion.png";
import splashLogo from "./Logos/splash-certificial.png";
import splashLogoWhite from "./Logos/certificial-logo-white-final.png";
import certLogo from "./Logos/certificial-logo-xs.png";
import certLogoOld from "./Logos/certificial-logo-old.png";
import certIcon from "./Logos/certificial-icon.png";
import grmsLogo from "./Logos/grms-logo.jpg";
import history from "./Images/history.png";
import coi from "./Images/coi.png";
import coiPreview from "./Images/coiPreview.png";
import attachments from "./Images/attachments.png";
import upload from "./Images/upload.png";
import attachments_alt from "./Images/attachments-alt.png";
import bg from "./Images/bg.svg";
import verifiedOwner from "./Images/verifiedOwner.png";
import truck from "./Images/truck.png";
import mail from "./Images/mail.svg";
import policiesCovered from "./Images/policiesCovered.png";
import clientSettings from "./Images/client-settings.svg";
import logout from "./Images/logout.png";
import corrigoLogo from "./Logos/Corrigo_LOGO.png";
import noResults from "./Images/Status/sitBackAndRelax.svg";
import PageNotFound from "./Images/Status/404.svg";
import ServerError from "./Images/Status/500.svg";
import Maintenance from "./Images/Status/maintenance.png";
import AddressBook from "./Images/address-book.png";
import Team from "./Images/certificial-team.png";
import InviteMember from "./Images/invite-member.png";
import replace from "./Images/replace.png";
import share from "./Images/share.svg";
import disconnected from "./Images/disconnected.png";
import expired from "./Images/expired.svg";
import expiredNew from "./Images/expired-new.png";
import checkMark from "./Images/check-mark.png";
import editPolicy from "./Images/edit-policy.svg";
import reRespondProcess from "./Images/re-respond-process.svg";
import additionalDocument from "./Images/additional-documents.png";
import ErrorUnableToLoad from "./Images/ErrorUnableToLoad.png";
import CertifacteOfInsurance from "./Images/certificate_of_insurance.png";

import companyAdmin from "./Images/Applications/company-admin.png";
import teamManage from "./Images/Applications/team-manage.png";
import addressBookAlt from "./Images/Applications/address-book-alt.png";

export const emptyImage = empty;
export const emptyAltImage = emptyAlt;
export const bellSnoozeSvg = bellSnooze;
export const errorImage = errorPicture;
export const exceptionImage = exception;
export const refreshImage = refresh;
export const successImage = success;
export const mailImage = mail;
export const saveImage = save;
export const cancelImage = cancel;
export const maintainanceImage = maintainance;
export const mapPinIcon = mapPin;
export const locationIcon = location;
export const templateIcon = template;
export const bookmarkIcon = bookmark;
export const bookmarkComplianceIcon = bookmarkCompliance;
export const bookmarkAltIcon = bookmarkAlt;
export const agencyIcon = agency;
export const noteIcon = note;
export const calendarIcon = calendar;
export const tableIcon = table;
export const remarksIcon = remarks;
export const remarksAltIcon = remarksAlt;
export const coverageIcon = coverage;
export const coverageAltIcon = coverageAlt;
export const instructionsIcon = instructions;
export const showDocumentsIcon = showDocuments;
export const starIcon = star;
export const starGreenIcon = starGreen;
export const starIconAlt = starAlt;
export const editIcon = edit;
export const deleteIcon = delete_Icon;
export const viewDetailsIcon = viewDetails;
export const approvalIcon = approval;
export const respondIcon = respond;
export const rejectIcon = reject;
export const handleExceptionIcon = handleException;
export const noImageFoundIcon = noImageFound;
export const notificationIcon = notification;
export const profileCompletionIcon = profileCompletion;
export const splashLogoIcon = splashLogo;
export const splashLogoIconWhite = splashLogoWhite;
export const attachmentsIcon = attachments;
export const attachments_altIcon = attachments_alt;
export const historyIcon = history;
export const coiIcon = coi;
export const coiPreviewIcon = coiPreview;
export const uploadIcon = upload;
export const bgImage = bg;
export const certLogoImage = certLogo;
export const certLogoOldImage = certLogoOld;
export const grmsLogoImage = grmsLogo;
export const verifiedOwnerImage = verifiedOwner;
export const truckImage = truck;
export const policiesCoveredIcon = policiesCovered;
export const clientSettingsIcon = clientSettings;
export const logoutIcon = logout;
export const corrigoLogoImage = corrigoLogo;
export const noResultsImage = noResults;
export const PageNotFoundImage = PageNotFound;
export const ServerErrorImage = ServerError;
export const MaintenanceImage = Maintenance;
export const AddressBookImage = AddressBook;
export const TeamImage = Team;
export const InviteMemberImage = InviteMember;
export const replaceIcon = replace;
export const shareIcon = share;
export const disconnectedIcon = disconnected;
export const companyAdminAppIcon = companyAdmin;
export const teamManageAppIcon = teamManage;
export const addressBookAltAppIcon = addressBookAlt;
export const certificialIcon = certIcon;
export const expiredIcon = expired;
export const expiredNewIcon = expiredNew;
export const checkMarkIcon = checkMark;
export const editPolicyIcon = editPolicy;
export const reRespondProcessIcon = reRespondProcess;
export const additionalDocumentIcon = additionalDocument;
export const ErrorUnableToLoadIcon = ErrorUnableToLoad;
export const CoiIdCerticateOfInsurance = CertifacteOfInsurance;