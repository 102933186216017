import {
	Alert,
	Avatar,
	Badge,
	Button,
	Col,
	Icon,
	Layout,
	Menu,
	message,
	notification,
	Popover,
	Row,
	Switch,
	Tag,
	Tooltip,
	Input,
	Table,
	Modal,
	Card,
	Spin,
	Empty
} from "antd";
import Text from "antd/lib/typography/Text";
import RcQueueAnim from "rc-queue-anim";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { emptyImage, profileCompletionIcon } from "../../Assets/assets";
import certificial from "../../Assets/Logos/certificial-logo-old.png";
import NotificationPopoverContent from "../../Components/NotificationPopOver/Notification_Popover";
import ProfileDropdown from "../../Components/ProfileDropdown/ProfileDropdown";
import CertAvatar from "../../Components/UI/CertAvatar/CertAvatar";
import { ENDPOINTS } from "../../REST/API.endpoints";
import {
	downloadUploadedFile,
	handleUserFirstLoginonLogout,
	logout_action
} from "../../Store/Modules/Application/Application.asyncRequest";
import { NavbarMenu } from "../../Utils/Configurations/Navbar.config";
import { APPLICATION_USER_ROLES, CERTIFICIAL } from "../../Utils/Constants/Application.constants";
import { APPLICATION_MESSAGES } from "../../Utils/Constants/ApplicationMessages.constants";
import { appHistory } from "../../Utils/Helpers/History";
import { showFile, getBadgeOffset } from "../../Utils/utility/app.utility";
import { _modalService } from "../../Utils/wrappers/Modal/ModalWrapper";
import { CSSTransition } from 'react-transition-group';
import './SupplierSearchSummary.less'
import Paragraph from "antd/lib/typography/Paragraph";
import { makeRequest } from "../../REST/API.wrapper";
import { API_METHODS } from "../../Utils/Constants/API.constants";
import { PolicyStatusTag } from "../../Components/Policy/PolicyStatusTag/PolicyStatusTag";
import { render } from "less";
import moment from "moment";
import { useHistory } from 'react-router-dom';

const { Header } = Layout;
const { Search } = Input;

const SupplierSearchSummary = ({
	keyword,
	onCancel
}) => {


	let history = useHistory();
	const { userData } = useSelector((state) => state.myProfile);
	const [ searchData, setSearchData ] = useState([]);
    const [loading, setLoading] = useState(false);

	const getSupplierSearchData = async()=>{
		setLoading(true);
		try {
			const response = await makeRequest({
				type: API_METHODS.GET,
				url: ENDPOINTS.BROAD_SUPPLIER_SEARCH+"/"+userData?.company?.id,
				options: {
					params: {
						keyword
					}
				}
			});
			setSearchData(response);
		} catch (error) {
			// setSearchData([]);
		}
		setLoading(false);
	}

	const requestCoverageDataSource = searchData?.requestCoverageBroadSearchResponse;
	const supplierDataSource = searchData?.supplierBroadSearchResponse;
	const supplierAddressBookDataSource = searchData?.supplierAddressBookBroadSearchResponse;

	const isDataAvailable = requestCoverageDataSource?.length || supplierDataSource?.length || supplierAddressBookDataSource?.length;
 
	useEffect(()=>{
		if(!keyword){
			return;
		}
		getSupplierSearchData();
	}, [keyword])

	const onSupplierAddrBookClick = (record)=>{
		const navigationUrl = "/app/supplier-address-book";
		history.replace({ pathname: navigationUrl, state: {bookName:record.addressBookName, supplierName:record.supplierName} });
		onCancel();
	}

	
	const onSupplierClick = (value)=>{
		const navigationUrl = "/app/suppliers";
		history.replace({ pathname: navigationUrl, state: {supplierSearch:{value} }});
		onCancel();
	}

	const onRequestCoverageClick = (value)=>{
		const navigationUrl = "/app/request-coverage";
		history.replace({ pathname: navigationUrl, state: {supplierSearch:{value}} });
		onCancel();
	}


	const requestCoverageTableColumns = [
		{
			title: "Supplier Name",
			dataIndex: "supplierName",
			key: "supplierName",
			render: (value) => {
					return <Button className="supplier-name-button" type="link" onClick={()=>onRequestCoverageClick(value)}>{value}</Button>;
			},
			ellipsis:true

		},
		{
			title: "Supplier ID",
			dataIndex: "supplierId",
			key: "supplierId",
			ellipsis:true,
			width:"17%"

		},
		{
			title: "Primary Contact",
			dataIndex: "primaryContact",
			key: "primaryContact",
			ellipsis:true
		},
		{
			title: "Primary Contact Email",
			dataIndex: "primaryContactEmail",
			key: "primaryContactEmail",
			render: (value) => {
				const link = `mailto:${value}`;
				return (<Paragraph
					copyable={value}
				>
					<a href={link}>{value}</a>
			  </Paragraph>);
			}
		},		
		{
			title: "Request Date",
			dataIndex: "lastUpdatedDate",
			key: "lastUpdatedDate",
			render:(value)=>{
				return moment(value).format(
					CERTIFICIAL.APP_FORMAT.DATE
				)
			},
			width:"18%"

		}
	];

	const supplierTableColumns = [
		{
			title: "Supplier Name",
			dataIndex: "supplierName",
			key: "supplierName",
			render: (value) => {
				return <Button type="link" className="supplier-name-button" onClick={()=>onSupplierClick(value)}>{value}</Button>;
		    },
			ellipsis:true
		},
		{
			title: "Supplier ID",
			dataIndex: "supplierId",
			key: "supplierId",
			ellipsis:true,
			width:"17%"

		},
		{
			title: "Primary Contact",
			dataIndex: "primaryContact",
			key: "primaryContact",
			ellipsis:true,
		},
		{
			title: "Primary Contact Email",
			dataIndex: "primaryContactEmail",
			key: "primaryContactEmail",
			render: (value) => {
				const link = `mailto:${value}`;
				return (<Paragraph
					copyable={value}
				>
					<a href={link}>{value}</a>
			  </Paragraph>);
			}
		},
		{
			title: "Company Status",
			dataIndex: "companyStatus",
			key: "companyStatus",
			render:(value)=>{
				return  <div style={{position:"relative", left:"-4px"}}>
					<PolicyStatusTag
						status={value}
			  />
			</div>
			},
			width:"18%"
		}
	];

	const supplierAddressBookTableColumns = [
		{
			title: "Supplier Name",
			dataIndex: "supplierName",
			key: "supplierName",
			render: (value, record) => {
				return <Button type="link" className="supplier-name-button" onClick={()=>onSupplierAddrBookClick(record)}>{value}</Button>;
		    },
			width:"22%",
			ellipsis:true,
		},
		{
			title: "Supplier ID",
			dataIndex: "supplierId",
			key: "supplierId",
			ellipsis:true,
			width:"17%"
		},
		{
			title: "Address",
			dataIndex: "address",
			key: "address",
			ellipsis:true
		},
		{
			title: "Address Book Name",
			dataIndex: "addressBookName",
			key: "addressBookName",
			ellipsis:true,
			width:"26%"
		}
	];



	

	return (<>
		<Modal
			centered={true}
			className="broad-supplier-search-wrapper"
			// wrapClassName={less.complianceSummaryModal}
			visible={true}
			closable={true}
			destroyOnClose={true}
			maskClosable={false}
			mask={true}
			width={"auto"}
			footer={null}
			onCancel={onCancel}
			bodyStyle={{textAlign:"center", maxHeight:"90vh", height: 'auto', overflowY: 'auto', padding:"40px" }} // Adjust the content height
		// onCancel={()=>setShowComplianceSummaryModal(false)}
		>
			{loading ?  <Spin
			  style={{width:"500px"}}
              spinning={true}
              className="customclass"
              indicator={
                <Icon
                  type="loading-3-quarters"
                  className="color-primary fs-24x"
                  spin
                />
              }
            ></Spin> :
			
			!isDataAvailable ? <Empty
                  image={emptyImage}
                  imageStyle={{
                    height: 55,
                  }}
                style={{width:"500px"}}
                  description={
                    <span>
                      <span
                        style={{
                          marginTop: 0,
                          display: "block",
                          fontStyle: "italic",
                          color: "rgba(0, 0, 0, 0.85)",
                          fontWeight: 500,
                        }}
                      >
                        No records match the search criteria.
                      </span>
                    </span>
                  }
                ></Empty>:
			<>
			{!!requestCoverageDataSource?.length && <Row style={{maxWidth:"1100px", marginBottom: "40px" }}>
				<Col><h3 className="header">Request Coverage (Open Requests)</h3></Col>
				<Table
					columns={requestCoverageTableColumns}
					dataSource={requestCoverageDataSource}
					pagination={false}
					scroll={{ y: "200px" }}
				/>
			</Row>}
			{!!supplierDataSource?.length && <Row style={{maxWidth:"1100px", marginBottom: "40px" }}>
				<Col><h3 className="header">Suppliers (Responses)</h3></Col>
				<Table
					columns={supplierTableColumns}
					dataSource={supplierDataSource}
					pagination={false}
					scroll={{ y: "200px" }}
				/>
			</Row>}
			{!!supplierAddressBookDataSource?.length &&<Row style={{maxWidth:"1100px", marginBottom: "40px" }}>
				<Col><h3 className="header">Supplier Address Book</h3></Col>
				<Table
					columns={supplierAddressBookTableColumns}
					dataSource={supplierAddressBookDataSource}
					pagination={false}
					scroll={{ y: "200px" }}
				/>
			</Row>}
			</>}
		</Modal>
	</>)
};

export default SupplierSearchSummary;
