import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Table, Input, Form, Button, Icon, Select } from "antd";
import "../CreatePolicyLimitTable/CreatePolicyLimitTable.css";
import "./PropertyPolicyLimitTable.css";
import {isValidNumberForLimits} from '../../../Utils/utility/app.utility';
import { CERTIFICIAL, DEDUCTIBLE_TYPE_WITHOUT_SYMBOL, PERCENT_DEDUCTIBLE_TYPE, DISABLE_LIMIT_TYPES_IN_ADD_EDIT } from "../../../Utils/Constants/Application.constants";
const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => {
  return (
    <EditableContext.Provider value={form}>
      <tr {...props}/>
    </EditableContext.Provider>
  );
};

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };
  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title, inputType } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              pattern: new RegExp(/^[0-9]*$/g),
              message: `Numeric Input.`
            },
            {
              required: true,
              message: `${title} is required.`
            }
          ],
          initialValue: record[dataIndex]
        })(
          <Input
            prefix={CERTIFICIAL.APP_FORMAT.CURRENCY}
            ref={(node) => (this.input = node)}
            size="small"
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {CERTIFICIAL.APP_FORMAT.CURRENCY} {children}
      </div>
    );
  };
  getIsDisabled = (row, dataIndex) => {
    if (dataIndex == "limitTypeId" && !!!row.limitCategoryId) {
      return true;
    }
    if (dataIndex == "name" && !!!row.limitCategoryId) {
      return true;
    }
    if (dataIndex == "valuationAmt" && (!!!row.valuationTypeId || row.disableSpecificLimit === true)) {
      return true;
    }
    if (dataIndex == "deductible" && (!!!row.deductableTypeId||row.disableSpecificLimit === true)) {
      return true;
    }
    if (dataIndex == "limits" && !!!row.limitTypeId) {
      return true;
    }
    if (dataIndex == "limitValue" && (!!!row.limitTypeId)) {
      return true;
    }
    if (dataIndex == "limit2" && (!!!row.limitTypeId || row.disableSpecificLimit === true) ) {
      return true;
    }

    if(dataIndex === 'coinsurance' && row.disableSpecificLimit === true) {
      return true;
    }
    return false;
  };
  getOptions = (type, limitTypes, limitCategoryId, limitTypesUsed) => {
    if (type == "limitCategoryId") {
      return (
        limitTypes &&
        limitTypes.map((item) => (
          <Option title={item.name} key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))
      );
    } else if (type == "limitTypeId") {
      const limitTypeOptions =
        limitTypes && limitTypes.filter((item) => item.id == limitCategoryId);
      return (
        limitTypeOptions &&
        limitTypeOptions.length &&
        limitTypeOptions[0].subCategory.map((item) => (
          <Option
            key={item.id}
            value={item.id}
            disabled={limitTypesUsed.includes(item.id)}
            title={item.name}
          >
            {item.name}
          </Option>
        ))
      );
    } else if (type == "deductableTypeId") {
      return this.props.deductableTypes.map((item) => (
        <Option
          key={item.id}
          value={item.id}
          title={item.type}
          // disabled={limitTypesUsed.includes(item.id)}
        >
          {item.type}
        </Option>
      ));
    } else if (type == "valuationTypeId") {
      return this.props.valuationTypeWrapper.map((item) => (
        <Option
          key={item.id}
          value={item.id}
          title={item.type}
          // disabled={limitTypesUsed.includes(item.id)}
        >
          {item.type}
        </Option>
      ));
    } else if (type == "blanketId") {
      return this.props.blanketTypes.map((item) => (
        <Option
          key={item.id}
          value={item.id}
          title={item.blanketNumber}
          // disabled={limitTypesUsed.includes(item.id)}
        >
          {item.blanketNumber}
        </Option>
      ));
    } else if (type == "blanketType") {
      return this.props.blanketType.map((item) => (
        <Option
          key={item.id}
          value={item.id}
          title={item.type}
          // disabled={limitTypesUsed.includes(item.id)}
        >
          {item.type}
        </Option>
      ));
    }
  };
  loadChild = () =>{
    const {
      children,
    } = this.props;
     let letNewChildren = [...children];
    //let letNewChildren = Object.assign({}, children);
     let zeroValueindex = children.indexOf('$0');
     
     if(zeroValueindex>=0) {
      //console.log(children, zeroValueindex);
      letNewChildren[zeroValueindex] = '$';
     }
     return letNewChildren
  }

  isDeductibleWithoutSymbol = ()=>{
    return this.props?.dataIndex == "deductible" &&  DEDUCTIBLE_TYPE_WITHOUT_SYMBOL.includes(this.props?.record?.deductableType);
  }

  isPercentSymbol = ()=>{
    const shouldShowPercent = this.props?.dataIndex == "deductible" &&  PERCENT_DEDUCTIBLE_TYPE.includes(this.props?.record?.deductableType);
    return (this.props?.dataIndex == "coinsurance") || shouldShowPercent
  }

  getDeductibleType = (id)=>{
   const dedType = this.props.deductableTypes.find(data=>data?.id == id);
   return dedType?.type;
  }

  getPrefix = ()=>{
    if(this.isDeductibleWithoutSymbol() || this.isPercentSymbol()){
      return "";
    } else{
      return "$";
    }
  }

  getSuffix = ()=>{
    if(this.isPercentSymbol()){
      return "%";
    }
    return "";
  }

  render() {
    const {
      inputType,
      editable,
      readOnlyMode,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      deleteRow,
      limitTypes,
      limitTypesUsed,
      deleteByKey,
      ...restProps
    } = this.props;
    
    return (
      <td
        {...restProps}
      >
        {editable && !readOnlyMode ? (
          <React.Fragment>
            {/* <EditableContext.Consumer>
                            {this.renderCell}
                        </EditableContext.Consumer> */}
            {inputType === "number" && (
              <NumberFormat
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "1px 9px",
                  width: "100%",
                  cursor: this.getIsDisabled(record, dataIndex)? "not-allowed": "auto"
                }}
                disabled={this.getIsDisabled(record, dataIndex)}
                thousandSeparator={true}
                allowEmptyFormatting={true}
                prefix={this.getPrefix()}
                suffix={this.getSuffix()}
                isAllowed={
                  this.isPercentSymbol()
                    ? ({ floatValue }) =>
                        floatValue ? floatValue <= 100 : true
                    : () => {
                        return true;
                      }
                }
                value={this.isPercentSymbol() && (isValidNumberForLimits(record[dataIndex]) === false) ? 0 : parseInt(record[dataIndex]) === NaN ? '' : isValidNumberForLimits(record[dataIndex]) === false? '': record[dataIndex]}
                onValueChange={(values, e) => {
                  let { value } = values;
                  handleSave({ ...record, [dataIndex]: value });
                }}
                allowNegative={false}
                onFocus={(e) => {
                  if (e.target.value === "$") {
                    e.target.value = "$";
                  } else if (e.target.value === "%") {
                    e.target.value = "%";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "$") {
                    e.target.value = "$";
                  } else if (e.target.value === "%") {
                    e.target.value = "%";
                  }
                }}
              />
            )}
            {inputType == "string" && (
              <Input
                size="small"
                disabled={this.getIsDisabled(record, dataIndex)}
                style={{
                  cursor: this.getIsDisabled(record, dataIndex)? "not-allowed": "auto"
                }}
                value={(record && record[dataIndex]) || ""}
                onChange={(event) => {
                  event.persist();
                  handleSave({
                    ...record,
                    [dataIndex]: event.target.value || ""
                  });
                }}
              />
            )}
            {inputType.includes("select") && (
              <Select
                Option
                // getPopupContainer={() =>
                //   document.getElementById("LimitTypeTable")
                // }
                allowClear
                showSearch
                size={"small"}
                listHeight={250}
                listItemHeight={8}
                style={{ width: "100%", cursor: this.getIsDisabled(record, dataIndex)? "not-allowed": "auto" }}
                optionFilterProp="children"
                placeholder="Select a Limit Type"
                // mode="multiple"
                value={(record && record[dataIndex]) || undefined}
                onChange={(event) => {
                  // event.persist();
                  handleSave({
                    ...record,
                    limitTypeId:
                      dataIndex == "limitCategoryId" ? "" : record.limitTypeId,
                    valuationAmt:
                      dataIndex == "valuationTypeId" ? "" : record.valuationAmt,
                    ...(dataIndex == "deductableTypeId" ? {deductible:"", deductableType:this.getDeductibleType(event)} :{}),
                    [dataIndex]: event || ""
                  });
                }}
                disabled={this.getIsDisabled(record, dataIndex) || (record.disableSpecificLimit && dataIndex !== "limitTypeId" && dataIndex !== "limitCategoryId")}
                // filterOption={(input, option) =>
                //   option.props.children
                //     .toLowerCase()
                //     .indexOf(input.toLowerCase()) >= 0
                // }
                dropdownClassName={"PropertyTableDropDown"}
              >
                {this.getOptions(
                  dataIndex,
                  limitTypes,
                  record["limitCategoryId"],
                  limitTypesUsed
                )}
              </Select>
            )}
            {inputType == "delete" && (
              <Icon
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#ff4d4f"
                }}
                type="delete"
                fill="#ff4d4f"
                onClick={() => {
                  deleteRow(deleteByKey ? record[deleteByKey] : record.key);
                }}
              />
            )}
          </React.Fragment>
        ) : (
          this.loadChild()
        )}
      </td>
    );
  }
}

class PropertyPolicyLimitTable extends React.Component {
  render() {
    const {
      tableColumns,
      handleSave,
      deleteRow,
      handleRowSelection,
      dataSource,
      isEditAgentForm,
      selectedRowKeys,
      addPropertyPolicyLimits,
      readOnlyMode,
      limitTypes,
      limitTypesUsed,
      deleteByKey,
      deductableTypes,
      valuationTypeWrapper,
      blanketType = [],
      blanketTypes = [],
      source
    } = this.props;

const getDeductibleValueForReadonlyMode = (obj)=>{
  const invalidDeductible = isValidNumberForLimits(obj.deductible) === false;
  if(DEDUCTIBLE_TYPE_WITHOUT_SYMBOL.includes(obj.deductableType)){
    return invalidDeductible ? "":obj.deductible;
  }else if(PERCENT_DEDUCTIBLE_TYPE.includes(obj.deductableType)){
    return invalidDeductible ? "%" : obj.deductible && obj.deductible+'%';
  }else{
    return invalidDeductible ? "$" : obj.deductible && '$' + obj.deductible 
  }

}

const updatedDataSource = dataSource && dataSource.length && dataSource.map(obj => {

  const limitCategoryId = obj.limitCategoryId;

   const selectedLimitTypeData = (limitTypes || []).filter(item=>item.id === limitCategoryId) || [];
  const disableLimit = DISABLE_LIMIT_TYPES_IN_ADD_EDIT.includes(selectedLimitTypeData[0]?.name);
  if (readOnlyMode) {
    let limit1;
    if (source == "template") {
      if (isValidNumberForLimits(obj.limitValue) === false) {
        limit1 = '$'
      } else {
        limit1 = '$' + obj.limitValue.toString()
      }
    } else {
      if (isValidNumberForLimits(obj.limits) === false) {
        limit1 = '$'
      } else {
        limit1 = '$' + obj.limits
      }
    }
    if (source == 'template') {
      return {
        ...obj,
        limits: limit1,
        limitValue: limit1,
        disableSpecificLimit: disableLimit,
        limit2: isValidNumberForLimits(obj.limit2) === false ? "$" : obj.limit2 && '$' + obj.limit2,
        valuationAmt: isValidNumberForLimits(obj.valuationAmt) === false ? '$' : '$' + obj.valuationAmt,
        deductible: getDeductibleValueForReadonlyMode(obj),
        coinsurance: isValidNumberForLimits(obj.coinsurance) === false ? "0%" : obj.coinsurance && obj.coinsurance + '%',
        totalBlanketLimit: isValidNumberForLimits(obj.totalBlanketLimit) === false ? "$" : obj.totalBlanketLimit && '$' + obj.totalBlanketLimit
      }
    } else {
      return {
        ...obj,
        limits: limit1,
        disableSpecificLimit: disableLimit,
        limit2: isValidNumberForLimits(obj.limit2) === false ? "$" : obj.limit2 && '$' + obj.limit2,
        valuationAmt: isValidNumberForLimits(obj.valuationAmt) === false ? '$' : '$' + obj.valuationAmt,
        deductible: getDeductibleValueForReadonlyMode(obj),
        coinsurance: isValidNumberForLimits(obj.coinsurance) === false ? "0%" : obj.coinsurance && obj.coinsurance + '%',
        totalBlanketLimit: isValidNumberForLimits(obj.totalBlanketLimit) === false ? "$" : obj.totalBlanketLimit && '$' + obj.totalBlanketLimit
      }
    }
  }
  return {...obj, disableSpecificLimit: disableLimit};
})
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      // onChange: (selectedRowKeys, selectedRows) => {

      // },
      selectedRowKeys,
      onChange: handleRowSelection,
      getCheckboxProps: (record) => ({
        disabled: record.defaultSelectForCoi === true, // Column configuration not to be checked
        name: record.name
      })
    };
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    const columns =
      tableColumns &&
      tableColumns.map((col) => {
        if (!col.editable || readOnlyMode) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            inputType: col.inputType,
            title: col.title,
            readOnlyMode: col.readOnlyMode,
            handleSave,
            deleteRow,
            limitTypes: limitTypes,
            limitTypesUsed,
            deleteByKey: deleteByKey,
            deductableTypes,
            valuationTypeWrapper,
            blanketTypes: blanketTypes,
            blanketType: blanketType
          })
        };
      });
    return (
      <>
        <Table
          size="small"
          scroll={{ x: 800 }}
          components={components}
          pagination={false}
          rowClassName={(item) => {
            return readOnlyMode || item.isLimitValid
              ? "editable-row"
              : " compliance";
          }}
          bordered
          dataSource={updatedDataSource}
          columns={columns}
          rowSelection={isEditAgentForm ? rowSelection : null}
        />
      </>
    );
  }
}

export default PropertyPolicyLimitTable;
