import { MY_ADRESSSBOOK_TYPES } from "./MyAddressBook.types";

const AddressBookInitialState = {
  sampleFilePath: "",
  documents: [],
  addresses: {},
  addressesLoading: false,
  insuredNameList: [],
  namedInsuredPrimaryDetails: {},
  namedInsuredTotalCount: 0,
  includeNotesAndAddressOnCoi:true
};

const Base_myAddressBook = { ...AddressBookInitialState };
const MyAddressBookReducer = (state = AddressBookInitialState, action) => {
  switch (action.type) {
    case MY_ADRESSSBOOK_TYPES.GET_ADDRESSBOOK.SUCCESS: {
      return {
        ...state,
        documents: action.payload.documents,
        sampleFilePath: action.payload.sampleFilePath
      };
    }
    case MY_ADRESSSBOOK_TYPES.GET_ADDRESSBOOK.ERROR:
    case MY_ADRESSSBOOK_TYPES.CLEAR_ADDRESSBOOK: {
      return { ...state, documents: [] };
    }
    case MY_ADRESSSBOOK_TYPES.GET_ADDRESSES.SUCCESS: {
      return { ...state, addresses: action.payload };
    }
    case MY_ADRESSSBOOK_TYPES.SET_LOADING: {
      return { ...state, addressesLoading: action.payload };
    }

    case MY_ADRESSSBOOK_TYPES.SET_INCLUDE_NOTES_AND_ADDRESS_ON_COI: {
      return { ...state, includeNotesAndAddressOnCoi: !!action.payload };
    }

    case MY_ADRESSSBOOK_TYPES.SET_NAMED_INSURED: {
      return { ...state, insuredNameList: action.payload };
    }
    case MY_ADRESSSBOOK_TYPES.SET_NAMED_INSURED_PRIMARY_ID: {
      return { ...state, namedInsuredPrimaryDetails: action.payload };
    }
    case MY_ADRESSSBOOK_TYPES.SET_NAMED_INSURED_TOTAL_COUNT: {
      return { ...state, namedInsuredTotalCount: action.payload };
    }
    case MY_ADRESSSBOOK_TYPES.CLEAR_DATA_NAMED_INSURED : {
      return { ...state, namedInsuredTotalCount: 0 ,insuredNameList :[], namedInsuredPrimaryDetails : {}};
    }
    

    default:
      return state;
  }
};
export default MyAddressBookReducer;
