import { API_METHODS } from "../../../Utils/Constants/API.constants";
import { ENDPOINTS } from "../../../REST/API.endpoints";
import { makeRequest } from "../../../REST/API.wrapper";
import _ from "lodash";
import { CERTIFICIAL } from "../../../Utils/Constants/Application.constants";
/**
 * Get all the policies for a perticular user
 */
export const getActivityNotes = (data) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.ACTIVITY_LOG.GET_ALL_NOTES+"/"+data.userId+"/"+data.projectId+"?rowLimit="+CERTIFICIAL.PAGINATION.PAGE_SIZE_10+"&currentPage="+data.currentPage
  });
}

export const addNote = (payload) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.ACTIVITY_LOG.ADD_NOTES,
    data: { ...payload },
  });
}

export const deleteNote = (payload) => {
  return makeRequest({
    type: API_METHODS.DELETE,
    url: ENDPOINTS.ACTIVITY_LOG.DELETE_NOTES+"/"+payload.userId+"/"+payload.noteId,
  });
}


export const updateNote = (payload) => {
  return makeRequest({
    type: API_METHODS.PUT,
    url: ENDPOINTS.ACTIVITY_LOG.UPDATE_NOTES+"/"+payload.noteId,
    data: { ...payload }, //noteText="sfsdfsfsd"
  });
}

export const getComplianceReviewData = (data) => {

  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.ACTIVITY_LOG.GET_ALL_COMPLIANCE + "/"+data.requestId});
}