import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
  Upload
} from "antd";
import _, { filter } from "lodash";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import CertificialAutoComplete from "../../../Components/UI/CertificialAutoComplete/CertificialAutoComplete";
import {
  getExistingUserInformationViaEmail,
  getState,
  registration_action,
  registration_agent_grms,
  requestForApplicationReferenceCountry,
  getAddressSearchApiDetailsOpen
} from "../../../Store/Modules/Application/Application.asyncRequest";
import {
  onSearchAgency,
  onSearchCompanies
} from "../../../Store/Modules/SearchCompanies/SearchCompanies.asyncRequest";
import { CERTIFICIAL_LABEL } from "../../../Utils/Constants/App.labels";
import {
  APPLICATION_USER_ROLES,
  CERTIFICIAL,
  VALIDATE_FIELDS
} from "../../../Utils/Constants/Application.constants";
import { appHistory } from "../../../Utils/Helpers/History";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import { strongPasswordVerification } from "../../../Utils/utility/app.utility";
import { LoginHeader } from "../Login/LoginHeader";
import { PasswordStrength } from "../PasswordStrength/PasswordStrength";
import AgentTearmsAndCondition from "../TearmsAndConditions/AgentTearmsAndCondition";
import UserTearmsAndCondition from "../TearmsAndConditions/UserTearmsAndCondition";
import MaskedInput from "antd-mask-input";
import "./SignUp.css";
import SignupVerifyUser from "./SignUpVerifyUser.js";
import { ENDPOINTS } from "../../../REST/API.endpoints";
import { makeRequest } from "../../../REST/API.wrapper";
import { API_METHODS } from "../../../Utils/Constants/API.constants";
import AddressSearch from '../../../Components/AddressSearch/AddressSearch.js'
function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
// const { Text } = Typography;
const {Option} = Select;
class SignUp extends Component {
  state = {
    confirmDirty: false,
    isRegisterCompany: false,
    isChecked: true,
    registrationError: false,
    errorMessage: "",
    iconLoading: false,
    fetching: false,
    companyDataSource: [],
    searchCompanyStringError: "",
    visible: false,
    stateSource: [],
    stateSourceCompany: [],
    fileList: [],
    isVerifyAgentFlow: false,
    verifyAgentData: [],
    isLoading: false,
    refernceCountry: [],
    postalPattern: "",
    inputMask: "(111) 111 1111",
    isExternalAgency: false,
    isPrimaryContact: false,
    emailVerificationLoading: false,
    emailVerifiedAndAvailable: false,
    emailVerifiedMessage: null,
    populatedCompany: {},
    agentSignedUpCompany: false,
    agentSignedUpAgency: false,
    amsCompanyTaxId: false,
    isPopulatedCompanyData: false,
    isSecondaryContact: false,
    enableForm:false,
    isCompanyReadOnly:false,
    selectedCompany:{},
    userEmail:"",
    showAMS: true,
    amsisRequred: false,
    amsListOptions:[],
    selectedAMSName: '',
    //addressResult:[],
    disableFields: false,
    showAddressSearch: false,
    searchSecretKey: '',
    companyAddress: ""
  };
  
  defaultValues = { companyAddress: "", companyCity: "", companyState: "", companyPostalcode: "", companyAddress2: "" }
  onChangeHandler(values = {}) {
    if (values !== undefined) {
      this.props.form.setFieldsValue({
        companyCity: values?.city || "",
        companyState: values?.state || "",
        companyPostalcode: values?.zipcode || "",
        companyAddress2: values?.secondary || "",
        companyAddress: values.address1 || ""
      })
      this.setState({ ...this.state, disableFields: true, companyAddress: values.address1})
    } else {
      this.setState({ ...this.state, disableFields: false })
      this.props.form.setFieldsValue({...this.defaultValues})
    }
  }

  onClearSearch = () => {
    this.setState({ ...this.state, disableFields: false, companyAddress: "" })
    this.props.form.setFieldsValue({ 'companyCity': "", companyState: "", companyPostalcode: "", companyAddress2: "", companyAddress: "" })
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  getAmsList = async (name = '') =>{
    const response =  await makeRequest({
      type: API_METHODS.GET,
      url: `${ENDPOINTS.REQUESTOR_DASHBOARD.GET_AGENT_AMS_LIST}?amsName=${name}`
    }).catch(err=>console.log(`Error while fetching policy types data-->`+err));
    if (!response) {
      return [];
    }
    let appendOther = [...response, {id:'Other', amsName:'Other'}];
    this.setState({...this.state, amsListOptions: appendOther.map(item=>{return {...item, id: String(item.amsName)}})});
  }

  handleOk = (e) => {
    const { form } = this.props;
    this.setState(
      {
        visible: false,
        isChecked: this.state.checked
      },
      () => {
        form.setFieldsValue({
          acceptTerms: true
        });
      }
    );
  };
  handleCancel = (e) => {
    const { form } = this.props;
    this.setState(
      {
        visible: false,
        isChecked: !this.state.checked
      },
      () => {
        form.setFieldsValue({
          acceptTerms: false
        });
      }
    );
  };

  checkAMSNameAndAssign = ()=>{
    const amsTypesArr = ["HawkSoft","Sagitta"];
    const paramsLocation = decodeURIComponent(this.props.location.search).split(
      "="
    );
    const amsName = paramsLocation && paramsLocation.length > 1 && paramsLocation[1];

    //Return if no amsName provided
    if(!amsName){
      return;
    }

    //Redirect to page-not-found if amsName is Uknown
    if (!amsTypesArr.includes(amsName)) {
      appHistory.push("/page-not-found");
      return;
    }

    //Set amsName if it is sagitta or hawksoft
    this.props.form.getFieldDecorator("agentMangementSystem", {
      initialValue: amsName
    });
    this.setState((prevState) => ({
      ...prevState,
      isExternalAgency: true,
      isRegisterCompany: true
    }));
    
  }
 
  getAPIKey = async () => {
    const response  = await getAddressSearchApiDetailsOpen();
    this.setState({...this.state, showAddressSearch: response.smartyEnabled || false, searchSecretKey: response.emabeddedKey.toString()})
  }

  handleComponentDidMount = ()=>{
    this.getAPIKey()
    _authService.logoutUser();
    _authService.clearUserInfo();
    _authService.clearUserRole();
    requestForApplicationReferenceCountry().then(
      (response) => {
        this.setState({
          refernceCountry:
            response && response.length
              ? _.orderBy(
                response,
                [(country) => country.countryName.toLowerCase()],
                ["asc"]
              )
              : []
        });
        this.setState({ postalPattern: /^[0-9a-zA-Z\- ]{0,10}$/ });
        const _countryCode =
          (response.find((o) => o.iso2CountryCode === "US") &&
            response.find((o) => o.iso2CountryCode === "US").iso2CountryCode) ||
          (response[0] && response[0].iso2CountryCode) ||
          "US";
        this.getStateInformationFromCountryCode(
          _countryCode,
          ["stateSource", "stateSourceCompany"],
          []
        );
      },
      (error) => { }
    );
    const { agentId } = this.props.match.params;

    this.checkAMSNameAndAssign();
    if (agentId && agentId !== "new") {
      this.setState({ isLoading: true });
      let agent = {
        token: agentId
      };
      const { form } = this.props;

      this.setState((prevState) => ({
        ...prevState,
        isRegisterCompany: true,
        isVerifyAgentFlow: true
      }));

      registration_agent_grms(agent).then(
        (response) => {
          if (response) {
            this.setState({
              isLoading: false,
              verifyAgentData: response,
              isPrimaryContact: response.isPrimaryContact
            });
            form.setFieldsValue(response);
            this.props.form.validateFields((errors, values) => { });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
          this.props.form.validateFields((errors, values) => { });
        }
      );
    }

    this.props.form.validateFields((errors, values) => { });
  }

  getSearchApiDetails(){
    return 
  }

  componentDidMount() {
     this.handleComponentDidMount();
     this.getAmsList('');
     this.getSearchApiDetails();
  }

  handleCountryChange = (value, option) => {
    if (!value) return;
    this.getStateInformationFromCountryCode(value, ["stateSource"], ["state"]);
  };

  handleCompanyCountryChange = (value, option) => {
    //if(value !== 'US') {
      this.onClearSearch();
    //}
    if (!!!this.isAgentForm() && this.props.match.params != "corrigo") {
      this.props.form.setFieldsValue({
        companyTaxId: ""
      });
      this.props.form.validateFields((errors, values) => { });
    }
    if (!value) return;
    this.getStateInformationFromCountryCode(
      value,
      ["stateSourceCompany"],
      ["companyState"]
    );
    //this.props.form.setFieldsValue({});
    this.setState({ ...this.state, disableFields: false })
  };

  getStateInformationFromCountryCode = (
    countryCode,
    stateVariableToUpdate,
    validatingFormItems
  ) => {
    getState({
      countryCode
    }).then(
      (response) => {
        const updatedState = _.reduce(
          stateVariableToUpdate,
          function (obj, param) {
            obj[param] = response;
            return obj;
          },
          {}
        );
        this.setState((prevState) => ({
          ...prevState,
          ...updatedState
        }));
        if (validatingFormItems && validatingFormItems.length) {
          this.props.form.setFieldsValue({ [validatingFormItems]: undefined });
          this.props.form.validateFields(
            [validatingFormItems],
            (errors, values) => { }
          );
        }
      },
      (error) => { }
    );
  };

  handleSubmit = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      registrationError: false
    }));
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState((prevState) => ({
          ...prevState,
          iconLoading: true
        }));
        const { fileList } = this.state;
        let _omittingFormKeys = ["phone", "email"];
        let companyInfoEdited = false;
        let includeFiles = true;
        if (this.state.populatedCompany && this.state.populatedCompany.id) {
          const { isCompanyInfoEdited, keysToOmit, shouldAddFiles } =
            this.verifyCompanyDetails(values, fileList);
          companyInfoEdited = isCompanyInfoEdited;
          _omittingFormKeys = _omittingFormKeys.concat(keysToOmit);
          includeFiles = shouldAddFiles;
        }
        let registrationModel = {
          amsCompanyTaxId: this.state.amsCompanyTaxId,
          role: this.props.data,
          userId:
            this.state.verifyAgentData && this.state.verifyAgentData.userId
              ? this.state.verifyAgentData.userId
              : null,
          companyId:
            this.state.verifyAgentData && this.state.verifyAgentData.companyId
              ? this.state.verifyAgentData.companyId
              : null,
          isRegisterCompany: !this.state.isRegisterCompany,
          phone: values.phone || "",
          email: (this.state.userEmail+"").trim(),
          ..._.omit(values, _omittingFormKeys),
          ...(values.searchCompany && {
            companyId: values.searchCompany
          }),
          country: values.companyCountry || this.state?.selectedCompany?.country?.iso2CountryCode,
          city: values.companyCity || this.state?.selectedCompany?.city,
          state: values.companyState || this.state?.selectedCompany?.state,
          postalCode: values.companyPostalcode || this.state?.selectedCompany?.postalCode || this.state?.selectedCompany?.postCode,
          address : values.companyAddress || this.state?.selectedCompany?.street,
          address2 : values.companyAddress2 || this.state?.selectedCompany?.address2,
          //agentMangementSystemId: amsID,
          ...(this.state.populatedCompany &&
            this.state.populatedCompany.id && {
            companyId:
              this.state.populatedCompany && this.state.populatedCompany.id
          }),
          companyInfoEdited
        };
        let amsID = values.agentMangementSystemId === 'Other'? '': values.agentMangementSystemId;

        if(amsID){
          const selectedAMS = this.state.amsListOptions.filter(item=>item.id===amsID);
          registrationModel = {...registrationModel, agentMangementSystem: selectedAMS[0].amsName}
        }
        if (this.state.isExternalAgency) {
          registrationModel = {
            ...registrationModel,
            amsName: values.agentMangementSystem
              ? values.agentMangementSystem
              : "HawkSoft",
            agentMangementSystem: values.agentMangementSystem
              ? values.agentMangementSystem
              : "HawkSoft"
          };
        }
        if (values.companyTaxId) {
          registrationModel = {
            ...registrationModel,
            companyTaxId:
              values.companyCountry == "US"
                ? values.companyTaxId.replace(/-/, "")
                : values.companyTaxId
          };
        }

        const fData = new FormData();
        fData.append("userRequest", JSON.stringify(registrationModel));
        if (includeFiles) {
          fileList.forEach((file) => {
            fData.append("multipartFile", file);
          });
        }
        registration_action(fData).then(
          (response) => {
            if (this.state.isVerifyAgentFlow) {
              message.success("Activation Successful");
              this.props.history.push("/registration-success-agentverify");
            } else {
              this.props.history.push("/registration-success", {
                ...response
              });
            }
            this.setState((prevState) => ({
              ...prevState,
              iconLoading: false,
              registrationError: false
            }));
          },
          (error) => {
            this.setState((prevState) => ({
              ...prevState,
              iconLoading: false,
              errorMessage: error.message,
              registrationError: true
            }));
          }
        );
      }
    });
  };

  copyAddressFromUser = (checked) => {
    if (checked) {
      this.setState((prevState) => ({
        ...prevState,
        stateSourceCompany: prevState.stateSource
      }));
      this.props.form.setFieldsValue({
        companyAddress: this.props.form.getFieldValue("address"),
        companyAddress2: this.props.form.getFieldValue("address2"),
        companyState: this.props.form.getFieldValue("state"),
        companyCity: this.props.form.getFieldValue("city"),
        companyCountry: this.props.form.getFieldValue("country"),
        companyPostalcode: this.props.form.getFieldValue("postalCode")
      });
    }
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && !form.getFieldValue("password")) {
      const strongnessOfPassword = strongPasswordVerification(value);
      if (!!strongnessOfPassword) {
        callback(strongnessOfPassword);
      }
    } else if (value && value !== form.getFieldValue("password")) {
      callback("Password does not match");
    } else {
      callback();
    }
  };
  validateAgencyPhoneNumber = (rule, value, callback) => {
    //const { form } = this.props;
    const reg = /^\d+$/;
    if(reg.test(value) === false){
      callback("Please enter valid Agent Phone Number");
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    if (!!value) {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      const strongnessOfPassword = strongPasswordVerification(value);
      if (!!strongnessOfPassword) {
        callback(strongnessOfPassword);
      }
    }
    callback();
  };

  selectExistingCompany = (checked) => {
    this.props.form.setFieldsValue({ searchCompany: undefined });
    this.setState(
      {
        isRegisterCompany: checked,
        registrationError: false,
        errorMessage: "",
        companyDataSource: [],
        fileList: []
      },
      () => {
        this.props.form.validateFields((errors, values) => { });
      }
    );
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.file;
  };

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  };

  onSelectComapnyName = (value, option) => {
    const { form } = this.props;
    let paramData = this.props.data;
    this.setState({ searchCompanyStringError: "" });
    let isAgentForm = paramData === APPLICATION_USER_ROLES.AGENT;
    if (
      option &&
      option.props &&
      option.props.value &&
      this.state.companyDataSource
    ) {
      let compIndex = _.findIndex(this.state.companyDataSource, (item) => {
        if (isAgentForm) {
          return item.agencyId === option.props.value;
        } else {
          return item.id === option.props.value;
        }
      });
      let companyAddress;
      let company;
      if (compIndex >= 0) {
        company = this.state.companyDataSource[compIndex];
        companyAddress = this.state.companyDataSource[compIndex].street;
      } else {
        company = {};
        companyAddress = "";
      }
      form.setFieldsValue({
        readOnlyCompanyAddress: companyAddress
      });
      this.setState((prevState) => ({
        ...prevState,
        populatedCompany:
          prevState.populatedCompany.id === company.id
            ? prevState.populatedCompany
            : {}
      }));
    } else {
      form.setFieldsValue({
        readOnlyCompanyAddress: ""
      });
    }
  };

  inputValidation = (e) => {
    let invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  validateAndFetchEmailUserInformation = async () => {
    const that = this;
    const {
      form: { getFieldError, getFieldValue },
      data: role
    } = this.props;
    const emailField = getFieldError("email");
    const email = getFieldValue("email");
    const isEmailValid = !!!emailField;
    if (this.state.requestedEmail !== email && isEmailValid) {
      try {
        this.setState((prevState) => ({
          ...prevState,
          emailVerificationLoading: true,
          emailVerifiedAndAvailable: false,
          emailVerifiedMessage: null,
          requestedEmail: email
        }));
        const userInfo = await getExistingUserInformationViaEmail(email, role);
        const validatedUserInfo = userInfo && userInfo.company;

        const userCountryCode =
          (userInfo && userInfo.country && userInfo.country.iso2CountryCode) ||
          "US";
        const userCompanyCountryCode =
          (validatedUserInfo &&
            validatedUserInfo.country &&
            validatedUserInfo.country.iso2CountryCode) ||
          "US";

        if (userCountryCode === userCompanyCountryCode) {
          that.getStateInformationFromCountryCode(
            userCountryCode,
            ["stateSource", "stateSourceCompany"],
            []
          );
        } else {
          that.getStateInformationFromCountryCode(
            userCountryCode,
            ["stateSource"],
            []
          );
          that.getStateInformationFromCountryCode(
            userCompanyCountryCode,
            ["stateSourceCompany"],
            []
          );
        }

        that.populateFormDataForExistingAccounts(
          validatedUserInfo ? userInfo : {}
        );
      } catch (error) {
        const { message: emailVerifiedMessage } = error;
        this.setState((prevState) => ({
          ...prevState,
          emailVerifiedAndAvailable: !!emailVerifiedMessage,
          emailVerifiedMessage
        }));
        that.populateFormDataForExistingAccounts({});
      } finally {
        this.setState((prevState) => ({
          ...prevState,
          emailVerificationLoading: false
        }));
      }
    } else {
      if (!!!isEmailValid) {
        this.setState((prevState) => ({
          ...prevState,
          emailVerifiedAndAvailable: false,
          emailVerifiedMessage: null,
          requestedEmail: email
        }));
      }
    }
  };

  populateFormDataForExistingAccounts = (userInformation) => {
    const { getFieldsValue, setFieldsValue, validateFields } = this.props.form;
    const _isAgent = this.isAgentForm();
    if (userInformation && userInformation.company) {
      const {
        firstName,
        lastName,
        company,
        country: userCountry,
        phone: userPhone,
        zipCode: userZipCode,
        state: userState,
        city: userCity,
        address: userAddress,
        address2: userAddress2,
        external,
        secondaryContact
      } = userInformation;
      const {
        id,
        name: companyName,
        country,
        street: address,
        address2,
        city,
        state,
        postalCode,
        logo,
        companyTaxId
      } = company;

      let _companyTaxID = companyTaxId;

      this.setState(
        (prevState) => ({
          ...prevState,
          isPopulatedCompanyData: true,
          amsCompanyTaxId: !!_companyTaxID,
          populatedCompany: company || {},
          isSecondaryContact: secondaryContact,
          agentSignedUpAgency: _isAgent && !!companyName && !!!external,
          agentSignedUpCompany:
            _isAgent && !!companyName && !!!external || secondaryContact
              ? false
              : !!companyName || false,
          companyDataSource:
            _isAgent && !!companyName && !!!external || secondaryContact ? [company] : [],
          fileList:
            _isAgent && !!companyName && !!!external || secondaryContact
              ? []
              : logo
                ? [
                  {
                    uid: "-2",
                    name: "company logo",
                    url:
                      process.env.REACT_APP_IMAGE_BASE_URL +
                      encodeURIComponent(logo),
                    existingLogo: true,
                    status: "done"
                  }
                ]
                : []
        }),
        () => {
          if (_isAgent && !!companyName && !!!external || secondaryContact) {
            const readOnyAddress = [company.street, company.city, company.state, company.postalCode || company.postCode, company.country?.iso2CountryCode ].filter(Boolean).join(", ");
            setFieldsValue({
              readOnlyCompanyAddress: readOnyAddress,
              searchCompany: id
            });
            validateFields((errors, values) => { });
          } else {
            const {
              firstName: form_firstName,
              lastName: form_lastName,
              phone: form_phone,
              address: form_address,
              address2: form_userAddress2,
              city: form_city,
              state: form_state,
              postalCode: form_postalCode
            } = getFieldsValue();
            setFieldsValue({
              firstName: firstName || form_firstName || null,
              lastName: lastName || form_lastName || null,
              phone: userPhone || form_phone || null,
              country: (userCountry && userCountry.iso2CountryCode) || "US",
              address: userAddress || form_address || null,
              address2: userAddress2 || form_userAddress2 || null,
              city: userCity || form_city || null,
              state: userState || form_state || [],
              postalCode: userZipCode || form_postalCode || null,
              companyName,
              companyTaxId: _companyTaxID,
              companyCountry: (country && country.iso2CountryCode) || "US",
              companyAddress: address,
              companyAddress2: address2,
              companyCity: city,
              companyState: state || [],
              companyPostalcode: postalCode
            });
            validateFields((errors, values) => { });
          }
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          populatedCompany: {},
          isPopulatedCompanyData: false,
          amsCompanyTaxId: false,
          agentSignedUpCompany: false,
          agentSignedUpAgency: false
        }),
        () => {
          const {
            firstName: form_firstName,
            lastName: form_lastName,
            country: form_country,
            address: form_address,
            address2: form_address2,
            city: form_city,
            state: form_state,
            postalCode: form_postalCode,
            phone: form_phone,
            readOnlyCompanyAddress: form_readOnlyCompanyAddress,
            searchCompany: form_searchCompany
          } = getFieldsValue();

          setFieldsValue({
            firstName: form_firstName || null,
            lastName: form_lastName || null,
            country: form_country || "US",
            address: form_address || null,
            address2: form_address2 || null,
            city: form_city || null,
            state: form_state || [],
            postalCode: form_postalCode || null,
            phone: form_phone || null,
            readOnlyCompanyAddress: form_readOnlyCompanyAddress || null,
            searchCompany: form_searchCompany || undefined
          });
          validateFields((errors, values) => { });
        }
      );
    }
  };

  verifyCompanyDetails = (formData, logoFile) => {
    let companyLogoEdited = false;
    const {
      name,
      country,
      street: address,
      address2,
      city,
      state,
      postalCode,
      logo
    } = this.state.populatedCompany;
    const populatedCompanyData = {
      companyName: name,
      companyCountry: (country && country.iso2CountryCode) || "US",
      companyAddress: address,
      companyAddress2: address2,
      companyCity: city,
      companyState: state,
      companyPostalcode: postalCode
    };
    const formCompanyData = _.pickBy(formData, function (v, k) {
      return k.substring(0, 7) === "company";
    });
    if (
      !(logo && logoFile && logoFile.length && _.head(logoFile).existingLogo)
    ) {
      companyLogoEdited = true;
    }

    const differenceKeys = _.reduce(
      populatedCompanyData,
      function (result, value, key) {
        return _.isEqual(value, formCompanyData[key])
          ? result
          : result.concat(key);
      },
      []
    );
    const companyKeys = _.keys(formCompanyData);
    const keysToOmit = _.without(
      _.difference(companyKeys, differenceKeys),
      "companyCountry"
    );
    return {
      isCompanyInfoEdited:
        (!!this.state.populatedCompany && !!differenceKeys.length) ||
        companyLogoEdited,
      keysToOmit,
      shouldAddFiles: companyLogoEdited
    };
  };

  isAgentForm = () => {
    let paramData = this.props.data;
    return paramData === APPLICATION_USER_ROLES.AGENT;
  };

  searchOptions = (searchCompanyString) => {
    const { isAgentForm } = this;
    this.setState({
      companyDataSource: [],
      fetching: true,
      searchCompanyStringError: ""
    });
    if (searchCompanyString.length >= 3) {
      if (isAgentForm()) {
        onSearchAgency(searchCompanyString).then(
          (response) => {
            this.setState({
              companyDataSource: response,
              searchCompanyStringError:
                response && response.length === 0
                  ? "No results found. If your Agency is not available, click the checkbox on the right to register"
                  : "",
              fetching: false
            });
          },
          (error) => {
            this.setState({
              companyDataSource: [],
              searchCompanyStringError:
                "No results found. If your Agency is not available, click the checkbox on the right to register",
              fetching: false
            });
          }
        );
      } else {
        onSearchCompanies(searchCompanyString).then(
          (response) => {
            this.setState({
              companyDataSource: response.companies,
              searchCompanyStringError:
                response &&
                  response.companies &&
                  response.companies.length === 0
                  ? "No results found. If your Company  is not available, click the checkbox on the right to register"
                  : "",
              fetching: false
            });
          },
          (error) => {
            this.setState({
              companyDataSource: [],
              searchCompanyStringError:
                "No results found. If your Company  is not available, click the checkbox on the right to register",
              fetching: false
            });
          }
        );
      }
    } else {
      this.setState({
        fetching: false,
        searchCompanyStringError:
          searchCompanyString.length !== 0
            ? "Type at least the first three letters of your " +
            (isAgentForm() ? "Agency name" : " Company name")
            : ""
      });
    }
  };

  onAMSChangeHander = (value) => {
    if(value === "Other") {
      this.setState({...this.state, showAMS: false, amsisRequred: true});
      this.props.form.setFieldsValue({
        agentMangementSystem:''
      });
    } else {
      this.setState({...this.state, showAMS: true, amsisRequred: false});
      this.props.form.resetFields(['agentMangementSystem'])
    }
  }

  render() {
    const { name: paramName } = this.props.match.params;
    const { isAgentForm } = this;
    const showAgentManagementSystem = () => {
      if (isAgentForm()) {
        if (this.state.isExternalAgency) {
          if (this.state.isRegisterCompany) {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.state.isRegisterCompany) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    };
    const ref = React.createRef();
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;

    const firstnameError =
      isFieldTouched("firstName") && getFieldError("firstName");
    const lastnameError =
      isFieldTouched("lastName") && getFieldError("lastName");
    const emailError = isFieldTouched("email") && getFieldError("email");
    const phoneError = isFieldTouched("phone") && getFieldError("phone");
    const passwordError =
      isFieldTouched("password") && getFieldError("password");
    const confirmError = isFieldTouched("confirm") && getFieldError("confirm");
    const addressError = isFieldTouched("address") && getFieldError("address");
    const address2Error =
      isFieldTouched("address2") && getFieldError("address2");
    const cityError = isFieldTouched("city") && getFieldError("city");
    const stateError = isFieldTouched("state") && getFieldError("state");
    const postalcodeError =
      isFieldTouched("postalCode") && getFieldError("postalCode");
    const companyNameError =
      isFieldTouched("companyName") && getFieldError("companyName");
    const taxIdError =
      isFieldTouched("companyTaxId") && getFieldError("companyTaxId");
    const CompanyAddressError =
      isFieldTouched("companyAddress") && getFieldError("companyAddress");
    const CompanyAddress2Error =
      isFieldTouched("companyAddress2") && getFieldError("companyAddress2");
    const CompanyCityError =
      isFieldTouched("companyCity") && getFieldError("companyCity");
    const CompanyStateError =
      isFieldTouched("companyState") && getFieldError("companyState");
    const CompanyPostalcodeError =
      isFieldTouched("companyPostalcode") && getFieldError("companyPostalcode");
      const CompanyFaxNoError =
      isFieldTouched("companyFaxNumber") && getFieldError("companyFaxNumber");
    const searchCompanyError =
      !this.state.isRegisterCompany &&
      (this.state.searchCompanyStringError ||
        (isFieldTouched("searchCompany") && getFieldError("searchCompany")));
    const checkedError =
      isFieldTouched("acceptTerms") && getFieldError("acceptTerms");
    const agentLicenseNumberError =
      isFieldTouched("agentLicenseNumber") &&
      getFieldError("agentLicenseNumber");
      const agencyPhoneNumberError =
      isFieldTouched("phone") &&
      getFieldError("phone");
    const agentMangementSystemError = getFieldError("agentMangementSystem");
      const agentMangementSystemIdError = getFieldError("agentMangementSystemId");
    const agencyLicenceNoError =
      isFieldTouched("agencyLicenceNo") && getFieldError("agencyLicenceNo");
    const externalAgencyIdError =
      isFieldTouched("externalAgencyId") && getFieldError("externalAgencyId");

    //Sagitta agent related  fields
    const sagittaServerUrlError =
      isFieldTouched("sagittaServerUrl") && getFieldError("sagittaServerUrl");
    const sagittaAccountError =
      isFieldTouched("sagittaAccount") && getFieldError("sagittaAccount");
    const sagittaUsernameError =
      isFieldTouched("sagittaUsername") && getFieldError("sagittaUsername");
    const sagittaPasswordError =
      isFieldTouched("sagittaPassword") && getFieldError("sagittaPassword");
    const sagittaServerPoolError =
      isFieldTouched("sagittaServerPool") && getFieldError("sagittaServerPool");
    const sagittaOnlineCodeError =
      isFieldTouched("sagittaOnlineCode") && getFieldError("sagittaOnlineCode");

    const { fileList } = this.state;
    const uploadLogoProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            ...state,
            fileList: newFileList
          };
        });
      },
      beforeUpload: (file) => {
        const fileType = CERTIFICIAL.UPLOAD_FILES.UPLOAD_SIGN_SUPPORT_CHECK;
        if (_.includes(fileType, file.type)) {
          this.setState((state) => ({
            ...state,
            fileList: [file]
          }));
        } else {
          message.error("You can only upload valid image file.");
        }
        return false;
      },
      fileList: fileList.length > 1 ? fileList.splice(-1) : fileList
    };

    const onBackbuttonclick = ()=>{
      this.setState((prevState)=>({
        ...prevState,
        enableForm:false
      }), ()=>{
        this.checkAMSNameAndAssign();
      })
    }

    const onNextClick = (userEmail, company, validatedUserInfo, companyId)=>{
      if(validatedUserInfo){
        this.setState((prevState)=>({
          ...prevState,
          selectedCompany:validatedUserInfo?.company,
          isRegisterCompany:false,
          userEmail,
          enableForm:true
        }),()=>{
          this.populateFormDataForExistingAccounts(validatedUserInfo);
        })
        return;
      }
      const isCreateNewCompany = company.id === "new"
      this.setState((prevState)=>({
        ...prevState,
        enableForm:true,
        isRegisterCompany:isCreateNewCompany
      }),()=>{
        const {form} = this.props;
        form.setFieldsValue({
          readOnlyCompanyAddress:  [company.street, company.city, company.state, company.postalCode || company.postCode, company.country?.iso2CountryCode ].filter(Boolean).join(", "),
          searchCompany:!isCreateNewCompany ? companyId:null,
        });
  
        this.setState((prev)=>({
          ...prev,
          selectedCompany:!isCreateNewCompany? company : {},
          userEmail,
          isCompanyReadOnly:!isCreateNewCompany,
          companyDataSource:!isCreateNewCompany ? [company] :[]
        }))
      })

   
    }

    return (
      <React.Fragment>
        {(!this.state.isLoading && (
          <Row className="signUp-form" type="flex" justify="center">
            <Col xs={22} sm={20} md={16} lg={13} xl={12}>
              <Card hoverable size="small" style={{ cursor: "context-menu" }}>
              <LoginHeader
                  source={paramName == "corrigo" ? "SignUp" : ""}
                  user={paramName}
                  componentStyle={
                    !paramName ||
                    paramName !== "grms" ||
                    paramName !== "corrigo"
                      ? { marginLeft: "auto", marginRight:"auto" }
                      : {}
                  }
                  onBackbuttonclick={()=>onBackbuttonclick()}
                  enableForm={this.state.enableForm}
                />
                <SignupVerifyUser onNextClick={onNextClick} enableForm={this.state.enableForm} role={this.props.data}/>
                {<Form style={{display:!this.state.enableForm?'none':'block'}} onSubmit={this.handleSubmit}>
                  <Row>
                    <Col span={12} className="p-12">
                      <Form.Item
                        label="First Name"
                        validateStatus={firstnameError ? "error" : ""}
                        help={firstnameError || ""}
                      >
                        {getFieldDecorator("firstName", {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter your first name."
                            },
                            {
                              pattern: /^.{1,30}$/,
                              message: "First name should be max 30 chars long."
                            }
                          ]
                        })(
                          <Input
                            prefix={
                              <Icon
                                type="user"
                                style={{
                                  color: "rgba(0,0,0,.25)"
                                }}
                              />
                            }
                            placeholder="First Name"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12} className="p-12">
                      <Form.Item
                        label="Last Name"
                        validateStatus={lastnameError ? "error" : ""}
                        help={lastnameError || ""}
                      >
                        {getFieldDecorator("lastName", {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter your last name."
                            },
                            {
                              pattern: /^.{1,30}$/,
                              message: "Last name should be max 30 chars long."
                            }
                          ]
                        })(
                          <Input
                            autoComplete="off"
                            prefix={
                              <Icon
                                type="user"
                                style={{
                                  color: "rgba(0,0,0,.25)"
                                }}
                              />
                            }
                            placeholder="Last Name"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <div style={{display:"none"}}><Row><Col span={12} className="p-12">
                      <Form.Item
                        label="email"
                      >
                          <Input
                            value={this.state.userEmail}
                            autoComplete="username"
                          />
                      </Form.Item>
                    </Col>
                  </Row></div>
                  
                  <Row>
                    <Col span={11} className="p-12">
                      <Form.Item
                        label="Password"
                        hasFeedback
                        validateStatus={passwordError ? "error" : ""}
                        help={passwordError || ""}
                      >
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              transform: (value) =>
                                (value && value.trim()) || null
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter your password."
                            },
                            {
                              validator: this.validateToNextPassword
                            }
                          ]
                        })(
                          <Input.Password
                            placeholder="Password"
                            // maxLength={12}
                            minLength={8}
                            prefix={
                              <Icon
                                type="lock"
                                style={{ color: "rgba(0,0,0,.45)" }}
                              />
                            }
                            autoComplete="new-password"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <div style={{ lineHeight: "3.6" }}>
                        <PasswordStrength />
                      </div>
                    </Col>
                    <Col span={12} className="p-12">
                      <Form.Item
                        label="Confirm Password"
                        hasFeedback
                        validateStatus={confirmError ? "error" : ""}
                        help={confirmError || ""}
                      >
                        {getFieldDecorator("confirm", {
                          rules: [
                            {
                              transform: (value) =>
                                (value && value.trim()) || null
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: "Please confirm your password."
                            },
                            {
                              validator: this.compareToFirstPassword
                            }
                          ]
                        })(
                          <Input.Password
                            prefix={
                              <Icon
                                type="lock"
                                style={{
                                  color: "rgba(0,0,0,.25)"
                                }}
                              />
                            }
                            placeholder="Confirm Password"
                            onBlur={this.handleConfirmBlur}
                            autoComplete="new-password"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  
                  <Row>
                    
                    {!isAgentForm() ?
                    <Col span={12} className="p-12">
                      <Form.Item
                        colon={false}
                        label={
                          <span>
                            Insurance Agent's Email Address:{" "}
                            <span
                              style={{
                                color: "gray",
                                fontSize: "12px"
                              }}
                            >
                              (Optional)
                            </span>
                          </span>
                        }
                      >
                        {getFieldDecorator("agentEmail", {
                          rules: [
                            {
                              transform: (value) =>
                                (value && value.trim()) || null
                            },
                            {
                              pattern: CERTIFICIAL.REGEX_PATTERNS.EMAIL,
                              whitespace: true,
                              message: "Please enter valid email address."
                            }
                          ]
                        })(
                          <Input
                            prefix={
                              <Icon
                                type="mail"
                                style={{
                                  color: "rgba(0,0,0,.25)"
                                }}
                              />
                            }
                            placeholder="E-mail"
                          />
                        )}
                      </Form.Item>
                    </Col> : null}
                  </Row>
                  {isAgentForm() ? (
                    <div>
                      <Row>
                        <Col span={12}  className="p-12">
                          <Form.Item
                            //label=" Agent License Number"
                            colon={false}
                            label={
                              <span>
                                Agent License Number:{" "}
                                <span
                                  style={{
                                    color: "gray",
                                    fontSize: "12px"
                                  }}
                                >
                                  (Optional)
                                </span>
                                &nbsp;&nbsp;&nbsp;
                                <Tooltip title="Please provide the license number for the Agent that will be verifying the insurance data requested.  Staff may be authorized to verify on that Agent’s behalf.  This state issued license number is publicly available">
                                  <Icon
                                    type="info-circle"
                                    style={{
                                      color: "#3596db"
                                      // verticalAlign: "bottom"
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            }
                            validateStatus={
                              agentLicenseNumberError ? "error" : ""
                            }
                            help={agentLicenseNumberError || ""}
                          >
                            {getFieldDecorator("agentLicenseNumber", {
                              rules: VALIDATE_FIELDS.AGENCY_NUMBER
                            })(<Input placeholder="Agent License Number" />)}
                          </Form.Item>
                        </Col>
                        <Col span={11} className="p-12">
                          <Form.Item
                            label="Agent Phone Number"
                            
                            validateStatus={
                              agencyPhoneNumberError ? "error" : ""
                            }
                            help={agencyPhoneNumberError || ""}
                          >
                            {getFieldDecorator("phone", {
                              rules: [
                                {
                                  required: true,
                                  whitespace: false,
                                  message: "Please enter Agent Phone Number."
                                },{
                                  max: 30,
                                  message: "Please enter Agent Phone Number"
                                },
                                {
                                  validator: this.validateAgencyPhoneNumber
                                }
                              ]
                            })(
                              <Input
                                prefix={
                                  <Icon
                                    type="phone"
                                    style={{
                                      color: "rgba(0,0,0,.25)"
                                    }}
                                  />
                                }
                                placeholder="Agent Phone Number"
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  <Divider dashed style={{ margin: "12px 0" }} />
                  <Row
                    style={
                      this.state.isVerifyAgentFlow ||
                      this.state.isExternalAgency
                        ? { display: "none" }
                        : {}
                    }
                  >
                    {!!!(
                      this.state.isRegisterCompany ||
                      this.state.agentSignedUpCompany
                    ) && (
                      <Col span={18} className="p-12 ">
                        <div id="company-selection-form">
                          <Form.Item                            
                            label={
                              isAgentForm() ? (
                                <span>
                                  Agency Name:
                                </span>
                              ) : (
                                <span>
                                  Company Name:
                                </span>
                              )
                            }
                            validateStatus={searchCompanyError ? "error" : ""}
                            help={searchCompanyError || ""}
                          >
                            {getFieldDecorator("searchCompany", {
                              rules: [
                                {
                                  required: !!!this.state.isRegisterCompany,
                                  message: isAgentForm()
                                    ? "Agency name is required."
                                    : "Company name is required"
                                }
                              ]
                            })(
                              <CertificialAutoComplete
                                iscreateRequest="SignUp"
                                placeholder={
                                  isAgentForm()
                                    ? "Type at least the first three letters of your Agency name"
                                    : "Type at least the first three letters of your Company name"
                                }
                                requestedRegion={true}
                                ref={ref}
                                containerId="company-selection-form"
                                source={this.state.companyDataSource}
                                onChange={this.onSelectComapnyName}
                                searchOptions={this.searchOptions}
                                fetching={this.state.fetching}
                                fetchText={"Searching"}
                                disabled={
                                  this.state.agentSignedUpCompany ||
                                  this.state.agentSignedUpAgency ||
                                  this.state.isSecondaryContact||
                                  this.state.isCompanyReadOnly
                                }
                              />
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                    )}
                  </Row>
                  {this.state.isRegisterCompany ||
                  this.state.agentSignedUpCompany ? null : (
                    <Row>
                      <Col span={24} className="p-12">
                        <Form.Item
                          label={
                            isAgentForm() ? "Agency Address" : "Company Address"
                          }
                        >
                          {getFieldDecorator("readOnlyCompanyAddress")(
                            <Input
                              disabled
                              placeholder={
                                isAgentForm()
                                  ? "Agency Address"
                                  : "Company Address"
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {this.state.isRegisterCompany ||
                  this.state.agentSignedUpCompany ? (
                    <div>
                      
                      <div className="ant-row p-12">
                        <Form.Item
                          label={isAgentForm() ? "Agency Name" : "Company Name"}
                          validateStatus={companyNameError ? "error" : ""}
                          help={companyNameError || ""}
                        >
                          {getFieldDecorator("companyName", {
                            validateTrigger: ["onChange", "onBlur"],
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: isAgentForm()
                                  ? "Please enter your Agency Name"
                                  : "Please enter your Company Name."
                              },
                              {
                                max: 50,
                                message: isAgentForm()
                                  ? "Agency Name should not be greater than 50 char."
                                  : "Company Name should not be greater than 50 char."
                              }
                            ]
                          })(
                            <Input
                              disabled={
                                (this.state.isVerifyAgentFlow &&
                                  !this.state.isPrimaryContact &&
                                  !this.state.isExternalAgency) ||
                                this.state.agentSignedUpCompany
                              }
                              placeholder={
                                isAgentForm() ? "Agency Name" : "Company Name."
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                      {(!!!isAgentForm() && paramName != "corrigo" && (
                        <div className="ant-row p-12">
                          <Form.Item
                            label={
                              <span>
                                Tax ID/FEIN:{" "}
                                <span
                                  style={{
                                    color: "gray",
                                    fontSize: "12px"
                                  }}
                                >
                                  (Optional)
                                </span>
                              </span>
                            }
                            colon={false}
                            validateStatus={taxIdError ? "error" : ""}
                            help={taxIdError || ""}
                          >
                            {getFieldDecorator("companyTaxId", {
                              validateTrigger: ["onChange", "onBlur"],
                              rules: [
                                {
                                  required: false,
                                  whitespace: true,
                                  message: "Please provide Tax ID/FEIN"
                                },
                                this.props.form.getFieldValue(
                                  "companyCountry"
                                ) == "US"
                                  ? {
                                      pattern: /^[0-9\-]{0,10}$/,
                                      message: "Please provide Tax ID/FEIN"
                                    }
                                  : {}
                              ]
                            })(
                              this.props.form.getFieldValue("companyCountry") ==
                                "US" ? (
                                <MaskedInput
                                  mask={"11-1111111"}
                                  formatCharacters={{
                                    1: {
                                      validate(char) {
                                        return /\d/.test(char);
                                      },
                                      transform(char) {
                                        return char.replace(/-/, "");
                                      }
                                    }
                                  }}
                                  disabled={
                                    (this.state.isVerifyAgentFlow &&
                                      this.state.amsCompanyTaxId &&
                                      !this.state.isPrimaryContact &&
                                      !this.state.isExternalAgency) ||
                                    (this.state.agentSignedUpCompany &&
                                      this.state.amsCompanyTaxId)
                                  }
                                />
                              ) : (
                                <Input
                                  disabled={
                                    (this.state.isVerifyAgentFlow &&
                                      this.state.amsCompanyTaxId &&
                                      !this.state.isPrimaryContact &&
                                      !this.state.isExternalAgency) ||
                                    (this.state.agentSignedUpCompany &&
                                      this.state.amsCompanyTaxId)
                                  }
                                />
                              )
                            )}
                          </Form.Item>
                        </div>
                      )) ||
                        null}
                      <Row>
                        <Col span={24} className="p-12">
                          <div id="country-selection-form">
                            <Form.Item
                              required
                              label="Country"
                              name={["companyCountry", "companyState"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Country is required."
                                }
                              ]}
                            >
                              {getFieldDecorator("companyCountry", {
                                initialValue:
                                  this.state.refernceCountry.length > 0
                                    ? this.state.refernceCountry.find(
                                        (o) => o.iso2CountryCode === "US"
                                      ).iso2CountryCode ||
                                      this.state.refernceCountry[0]
                                        .iso2CountryCode
                                    : undefined
                              })(
                                <Select
                                  disabled={
                                    (this.state.isVerifyAgentFlow &&
                                      !this.state.isPrimaryContact &&
                                      !this.state.isExternalAgency) ||
                                    this.state.amsCompanyTaxId
                                  }
                                  placeholder="Country"
                                  onChange={this.handleCompanyCountryChange}
                                  getPopupContainer={() =>
                                    document.getElementById(
                                      "country-selection-form"
                                    )
                                  }
                                  showSearch
                                  filterOption={(inputValue, option) =>
                                    option.props.children
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  }
                                >
                                  {this.state.refernceCountry &&
                                    this.state.refernceCountry.length &&
                                    this.state.refernceCountry.map(
                                      (country) => (
                                        <Option
                                          key={country.iso2CountryCode}
                                          value={country.iso2CountryCode}
                                        >
                                          {country.countryName}
                                        </Option>
                                      )
                                    )}
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} className="p-12">
                          <Form.Item
                            label="Address 1"    
                            validateStatus={CompanyAddressError ? "error" : ""}
                            help={CompanyAddressError || ""}
                          >
                              {getFieldDecorator("companyAddress", {
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please enter address 1."
                                  },
                                  {
                                    max: 50,
                                    message:
                                      "Address 1 should not be greater than 50 char."
                                  }
                                ]
                              })(
                                (this.props.form.getFieldValue('companyCountry') === 'US' || this.props.form.getFieldValue('companyCountry') === undefined) && this.state.showAddressSearch ? (
                                  <>
                                    <AddressSearch
                                      onChangeHandler={(result) => { this.onChangeHandler(result) }}
                                      onClearSearch = {()=>{
                                        this.onClearSearch()
                                      }}
                                      defaultValue={this.props.form.getFieldValue('companyAddress') || ""}
                                      secretApiKey={this.state.searchSecretKey}
                                    />
                                  </>
                                ) : (<Input
                                  disabled={
                                    this.state.isVerifyAgentFlow &&
                                    !this.state.isPrimaryContact &&
                                    !this.state.isExternalAgency
                                  }
                                  placeholder="Address 1"
                                />)

                              )}
                          </Form.Item>
                        </Col>
                        <Col span={12} className="p-12">
                          <Form.Item
                            label="Address 2 / Suite#"
                            validateStatus={CompanyAddress2Error ? "error" : ""}
                            help={CompanyAddress2Error || ""}
                          >
                            {getFieldDecorator("companyAddress2", {
                              rules: [
                                {
                                  required: false,
                                  whitespace: true,
                                  message: "Please enter the Address 2 / Suite#"
                                },
                                {
                                  max: 50,
                                  message:
                                    "Address 2 / Suite# should not be greater than 50 char."
                                }
                              ]
                            })(
                              
                              <Input
                                disabled={  
                                  this.state.isVerifyAgentFlow &&
                                  !this.state.isPrimaryContact &&
                                  !this.state.isExternalAgency
                                }
                                placeholder="Address 2 / Suite#"
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} className="p-12">
                          <Form.Item
                            label="City / Town"
                            validateStatus={CompanyCityError ? "error" : ""}
                            help={CompanyCityError || ""}
                          >
                            {getFieldDecorator("companyCity", {
                              rules: [
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please enter your City / Town."
                                }
                              ]
                            })(
                              <Input
                                disabled={
                                  this.props.form.getFieldValue('companyCountry') === 'US' ||
                                  this.state.disableFields ||
                                  this.state.isVerifyAgentFlow &&
                                  !this.state.isPrimaryContact &&
                                  !this.state.isExternalAgency
                                }
                                placeholder="City / Town"
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={12} className="p-12">
                          <div id="state-container">
                            <Form.Item
                              label="State / Province / County"
                              validateStatus={CompanyStateError ? "error" : ""}
                              help={CompanyStateError || ""}
                            >
                              {getFieldDecorator("companyState", {
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "Please enter your state / province / county."
                                  }
                                ]
                              })(
                                <Select
                                  getPopupContainer={() =>
                                    document.getElementById("state-container")
                                  }
                                  disabled={
                                    this.props.form.getFieldValue('companyCountry') === 'US' ||
                                    this.state.disableFields ||  
                                    this.state.isVerifyAgentFlow &&
                                    !this.state.isPrimaryContact &&
                                    !this.state.isExternalAgency
                                  }
                                  placeholder="State / Province / County"
                                  showSearch
                                  filterOption={(inputValue, option) =>
                                    option.props.children
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  }
                                >
                                  {this.state.stateSourceCompany &&
                                    this.state.stateSourceCompany.map(
                                      (data) =>
                                        (
                                          <Option
                                            key={data.stateCode}
                                            value={data.stateCode}
                                          >
                                            {data.stateName}
                                          </Option>
                                        ) || null
                                    )}
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} className="p-12">
                          <Form.Item
                            label="Zip Code / Post Code / Pin Code"
                            validateStatus={
                              CompanyPostalcodeError ? "error" : ""
                            }
                            help={CompanyPostalcodeError || ""}
                          >
                            {getFieldDecorator("companyPostalcode", {
                              rules: [
                                {
                                  required: true,
                                  whitespace: true,
                                  message:
                                    "Please enter your zip code / post code / pin code."
                                },
                                {
                                  pattern: this.state.postalPattern,
                                  message:
                                    "Invalid zip code / post code / pin code."
                                }
                              ]
                            })(
                              <Input
                                type="text"
                                disabled={
                                  this.props.form.getFieldValue('companyCountry') === 'US' ||
                                  this.state.disableFields || 
                                  this.state.isVerifyAgentFlow &&
                                  !this.state.isPrimaryContact &&
                                  !this.state.isExternalAgency
                                }
                                placeholder="Zip Code / Post Code / Pin Code"
                              />
                            )}
                          </Form.Item>
                        </Col>
                        {this.isAgentForm() && (
                        <Col span={12} className="p-12">
                          <Form.Item
                            label="Fax Number"
                            validateStatus={
                              CompanyFaxNoError ? "error" : ""
                            }
                            help={CompanyFaxNoError || ""}
                          >
                            {getFieldDecorator("companyFaxNumber", {
                              rules: CERTIFICIAL.FAX_NUMBER_VALIDATION
                            })(
                              <Input
                                type="text"
                                disabled={false}
                                placeholder="Fax Number"
                                // maxLength={CERTIFICIAL.FAX_NO_MAX_LENGTH}
                              />
                            )}
                          </Form.Item>
                        </Col>)}
                      </Row>
                      <Row className="m-b-24">
                        <Col span={24} className="p-12">
                          <Form.Item>
                            <Upload
                              {...uploadLogoProps}
                              className="uploadLogo"
                              accept={
                                CERTIFICIAL.UPLOAD_FILES
                                  .ONLY_IMAGE_SUPPORTED_FILE_EXTENTIONS
                              }
                              name="logo"
                              action=""
                              listType="picture"
                              multiple={false}
                              disabled={
                                this.state.isVerifyAgentFlow &&
                                !this.state.isPrimaryContact &&
                                !this.state.isExternalAgency
                              }
                            >
                              <Button
                                disabled={
                                  this.state.isVerifyAgentFlow &&
                                  !this.state.isPrimaryContact &&
                                  !this.state.isExternalAgency
                                }
                                className="ant-col-24 p-12"
                              >
                                <Icon type="upload" /> Please upload your
                                company logo
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  {showAgentManagementSystem() ? (
                    <Row>
                      <Col className="p-12">
                        <Form.Item
                          colon={false}
                          label={
                            <span>
                              Agent Management System:&nbsp;&nbsp;&nbsp;
                              <Tooltip title="Please provide the name of the software that your Agency uses to manage your client's policy data.  If uncertain, simply enter Unknown.">
                                <Icon
                                  type="info-circle"
                                  style={{
                                    color: "#3596db"
                                    // verticalAlign: "bottom"
                                  }}
                                />
                              </Tooltip>
                            </span>
                          }
                          validateStatus={
                            agentMangementSystemIdError ? "error" : ""
                          }
                          help={agentMangementSystemIdError || ""}
                        >
                          {getFieldDecorator("agentMangementSystemId", {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "Agent management system is required."
                              },
                              {
                                pattern: /^[a-zA-Z0-9!@#$%^&*)(+=._ -]{0,50}$/,
                                message: "Invalid agent management system."
                              }
                            ]
                          })(
                            // <Input
                            //   disabled={
                            //     this.state.isVerifyAgentFlow
                            //       ? !this.state.isPrimaryContact
                            //       : this.state.isExternalAgency
                            //   }
                            //   placeholder="Agent Management System"
                            // />
                            <Select
                            allowClear={true}
                              showSearch
                              optionFilterProp="children"
                              mode="default"
                              style={{ width: '100%' }}
                              placeholder="Select Agent Management System"
                              onSearch={async (searchText) => {
                                await this.getAmsList(searchText)
                              }
                              }
                              onChange={(value)=>{
                                this.onAMSChangeHander(value);
                              }}
                              filterOption={false}
                            >
                              {(this.state.amsListOptions || []).map(
                                (data) => {
                                    return (
                                      <Option
                                        value={data.amsName}
                                        key={data.amsName}
                                        label={data.amsName}
                                        style={data.id === 'Other'?{ color: "#3596db" }:{}}
                                      >
                                        {data.amsName}
                                      </Option>
                                    )
                                  //}
                                }
                              )}
                              
                              </Select>
                          )}
                        </Form.Item>

                        <Form.Item
                          colon={false}
                          hidden={this.state.showAMS}
                          label={
                            ""
                          }
                          // validateStatus={
                          //   agentMangementSystemError ? "error" : ""
                          // }
                          //help={agentMangementSystemError || ""}
                        >
                          {getFieldDecorator("agentMangementSystem", {
                            rules: [
                              {
                                required: this.state.amsisRequred,
                                whitespace: true,
                                message: "Agent management system is required."
                              },
                              {
                                pattern: /^[a-zA-Z0-9!@#$%^&*)(+=._ -]{0,50}$/,
                                message: "Invalid agent management system."
                              }
                            ]
                          })(
                            <Input
                              disabled={
                                this.state.isVerifyAgentFlow
                                  ? !this.state.isPrimaryContact
                                  : this.state.isExternalAgency
                              }
                              placeholder="Agent Management System"
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null}
                  {isAgentForm() &&
                  this.state.isExternalAgency &&
                  this.state.isRegisterCompany &&
                  this.props.form.getFieldValue("agentMangementSystem") !=
                    "Sagitta" ? (
                    <Row className="ant-row p-12">
                      <Form.Item
                        label="AMS Agency ID"
                        validateStatus={externalAgencyIdError ? "error" : ""}
                        help={externalAgencyIdError || ""}
                      >
                        {getFieldDecorator("externalAgencyId", {
                          validateTrigger: ["onChange", "onBlur"],
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter AMS Agency  ID."
                            }
                          ]
                        })(<Input placeholder="AMS Agency ID" />)}
                      </Form.Item>
                    </Row>
                  ) : null}
                  {isAgentForm() &&
                  this.state.isExternalAgency &&
                  this.state.isRegisterCompany &&
                  this.props.form.getFieldValue("agentMangementSystem") ===
                    "Sagitta" ? (
                    <>
                      <Row>
                        <Col span={8} className="p-12">
                          <Form.Item
                            colon={false}
                            label={<span>IIS Server:&nbsp;&nbsp;&nbsp;</span>}
                            validateStatus={
                              sagittaServerUrlError ? "error" : ""
                            }
                            help={sagittaServerUrlError || ""}
                          >
                            {getFieldDecorator("sagittaServerUrl", {
                              rules: [
                                {
                                  transform: (value) =>
                                    (value && value.trim()) || null
                                },
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please enter IIS Server"
                                },
                                {
                                  max: 30,
                                  message:
                                    "IIS Server should not be greater than 30 char."
                                }
                              ]
                            })(<Input placeholder="IIS Server" />)}
                          </Form.Item>
                        </Col>
                        <Col span={8} className="p-12">
                          <Form.Item
                            colon={false}
                            label={<span>Account:&nbsp;&nbsp;&nbsp;</span>}
                            validateStatus={sagittaAccountError ? "error" : ""}
                            help={sagittaAccountError || ""}
                          >
                            {getFieldDecorator("sagittaAccount", {
                              rules: [
                                {
                                  transform: (value) =>
                                    (value && value.trim()) || null
                                },
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please enter Account."
                                },
                                {
                                  max: 30,
                                  message:
                                    "Account should not be greater than 30 char."
                                }
                              ]
                            })(<Input placeholder="Account" />)}
                          </Form.Item>
                        </Col>
                        <Col span={8} className="p-12">
                          <Form.Item
                            colon={false}
                            label={<span>User:&nbsp;&nbsp;&nbsp;</span>}
                            validateStatus={sagittaUsernameError ? "error" : ""}
                            help={sagittaUsernameError || ""}
                          >
                            {getFieldDecorator("sagittaUsername", {
                              rules: [
                                {
                                  transform: (value) =>
                                    (value && value.trim()) || null
                                },
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please enter user."
                                },
                                {
                                  // pattern: /^[A-Za-z0-9]+$/,
                                  pattern: /^\S*$/,
                                  message: "Please enter valid user."
                                },
                                {
                                  max: 30,
                                  message:
                                    "User should not be greater than 30 char."
                                }
                              ]
                            })(<Input placeholder="User" />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} className="p-12">
                          <Form.Item
                            colon={false}
                            label={<span>Passsword:&nbsp;&nbsp;&nbsp;</span>}
                            validateStatus={sagittaPasswordError ? "error" : ""}
                            help={sagittaPasswordError || ""}
                          >
                            {getFieldDecorator("sagittaPassword", {
                              rules: [
                                {
                                  transform: (value) =>
                                    (value && value.trim()) || null
                                },
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please enter Sagitta Password."
                                },
                                {
                                  max: 30,
                                  message:
                                    "Password should not be greater than 30 char."
                                }
                              ]
                            })(
                              <Input.Password
                                placeholder="Password"
                                prefix={
                                  <Icon
                                    type="lock"
                                    style={{ color: "rgba(0,0,0,.45)" }}
                                  />
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8} className="p-12">
                          <Form.Item
                            colon={false}
                            label={<span>Server Pool:&nbsp;&nbsp;&nbsp;</span>}
                            validateStatus={
                              sagittaServerPoolError ? "error" : ""
                            }
                            help={sagittaServerPoolError || ""}
                          >
                            {getFieldDecorator("sagittaServerPool", {
                              rules: [
                                {
                                  transform: (value) =>
                                    (value && value.trim()) || null
                                },
                                {
                                  max: 30,
                                  message:
                                    "Server Pool should not be greater than 30 char."
                                }
                              ]
                            })(<Input placeholder="Server Pool" />)}
                          </Form.Item>
                        </Col>
                        <Col span={8} className="p-12">
                          <Form.Item
                            colon={false}
                            label={<span>Online Code:&nbsp;&nbsp;&nbsp;</span>}
                            validateStatus={
                              sagittaOnlineCodeError ? "error" : ""
                            }
                            help={sagittaOnlineCodeError || ""}
                          >
                            {getFieldDecorator("sagittaOnlineCode", {
                              rules: [
                                {
                                  transform: (value) =>
                                    (value && value.trim()) || null
                                },
                                {
                                  max: 30,
                                  message:
                                    "Online Code should not be greater than 30 char."
                                }
                              ]
                            })(<Input placeholder="Online Code" />)}
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  {!!this.state.registrationError && this.state.errorMessage && (
                    <div className="p-12" style={{ marginBottom: 12 }}>
                      <Alert message={this.state.errorMessage} type="error" />
                    </div>
                  )}
                  <Row>
                    <Col span={18} className="p-12 signupForm">
                      <Form.Item
                        validateStatus={checkedError ? "error" : ""}
                        help={checkedError || ""}
                      >
                        {getFieldDecorator("acceptTerms", {
                          rules: [
                            {
                              required: true,
                              message:
                                "I understand and accept the Terms and Condition."
                            }
                          ],
                          valuePropName: "checked"
                        })(
                          <Checkbox className="unAvailableCoverage" onChange={this.toggleChange}>
                            I understand and accept the{" "}
                            <a onClick={this.showModal}>Terms and Conditions</a>
                          </Checkbox>
                        )}
                      </Form.Item>
                    </Col>
                    <Col offset={19} className="p-30">
                      <Form.Item>
                        <Button
                          style={{
                            paddingLeft: 20,
                            paddingRight: 20
                          }}
                          type="primary"
                          htmlType="submit"
                          disabled={
                            hasErrors(getFieldsError()) ||
                            this.state.isChecked ||
                            this.state.emailVerifiedAndAvailable
                          }
                          loading={this.state.iconLoading}
                        >
                          Sign Up
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>}
                <Divider type="horizontal" dashed />
                  <Row>
                    <Col
                      span={24}
                      className="p-12"
                      style={{
                        textAlign: "center",
                        paddingBottom: 12
                      }}
                    >
                      Already have an account?
                      <Link to={paramName ? "/login/" + paramName : "/login"}>
                        {" "}
                        Login
                      </Link>
                    </Col>
                  </Row>
              </Card>
            </Col>
          </Row>
        )) ||
          (this.state.isLoading && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: 50
              }}
            >
              <Spin tip={CERTIFICIAL_LABEL.COMPONENTS.LOADING.MESSAGE} />
            </div>
          )) ||
          null}
        {isAgentForm() ? (
          <AgentTearmsAndCondition
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            agreeFooter={true}
          />
        ) : (
          <UserTearmsAndCondition
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            agreeFooter={true}
          />
        )}
      </React.Fragment>
    );
  }
}

const Registertion = withRouter(Form.create({ name: "SignUp" })(SignUp));

export default Registertion;
