import { message } from "antd";
import _ from "lodash";
import { ENDPOINTS } from "../../../REST/API.endpoints";
import { makeRequest } from "../../../REST/API.wrapper";
import { API_METHODS } from "../../../Utils/Constants/API.constants";
import {
  MULTI_AGENTS,
  CERTIFICIAL,
  CERTIFICIAL_STATUS,
  APPLICATION_USER_ROLES
} from "../../../Utils/Constants/Application.constants";
import {
  disableCompanyDetailsLoading,
  disableCustomLabelLoading,
  disableListDetailsloading,
  disableListLoading,
  enableCompanyDetailsLoading,
  enableCustomLabelLoading,
  enableListDetailsloading,
  enableListLoading
} from "../Application/Application.actions";
import { checkMultiAgentSupport } from "../Application/Application.asyncRequest";
import {
  requestedRequestDetailError,
  requestedRequestDetailSuccess
} from "../pendingInsuredRequests/pendingInsuredRequests.action";
import {
  deleteSharedPolicyError,
  deleteSharedPolicySuccess,
  getSelectedCompanyByIdError,
  getSelectedCompanyByIdErrorListView,
  getSelectedCompanyByIdSuccess,
  getSelectedCompanyByIdSuccessListView,
  getSharedPolicyGraphError,
  getSharedPolicyGraphSuccess,
  insurerReSharableDataError,
  insurerReSharableDataSuccess,
  insurerSharablePoliciesError,
  insurerSharablePoliciesSuccess,
  insurerSharedPoliciesError,
  insurerSharedPoliciesSuccess,
  insurerSharedProjectsSuccess,
  saveCertUploadReRespondStatus,
  saveTotalPaggonation,
  setInsurerSharedProject,
  setRefreshCompanyId,
  setSelectedCompanyDrawerPolicies
} from "./InsurerSharedPolicies.action";
import { showFile, getSortedProjects, mergeReferenceDataToPolicy } from "../../../Utils/utility/app.utility";
import {APPLICATION_MESSAGES} from "../../../Utils/Constants/ApplicationMessages.constants"


export const getAllCompanies = (id) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: [ENDPOINTS.SHARE_POLICY.SHARE_POLICY_LIST, id].join("/")
  });
};

export const downloadAllCOIFromFilter = (companyId, agentID, filters) => {
  var str = Object.keys(filters).map(function(key) {
    return key + '=' + filters[key];
  }).join('&');
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.GET_DOWNLOAD_ALL_COIS+ `/${companyId}/latest/zip?agentId=${agentID}&`+str
  });
};

export const getShareEnableFlag = (companyId, agentId) => {
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.GET,
      url: [ENDPOINTS.SHARE_POLICY.SHARE_ENABLE, companyId].join("/"),
      options: { params: { agentId } }
    });
  };
};
export const sharePolicy_listView_action = (
  userId,
  selectedFilter,
  otherParams,
  selectedShareDataToPersist
) => {
  return (dispatch, getState) => {
    dispatch(enableListLoading());
    dispatch(enableListDetailsloading());
    const {
      myProfile: {
        userData: { role },
        agentList
      }
    } = getState();
    let agentId = null;
    if(role == APPLICATION_USER_ROLES.CLIENT){
      const insurerAgentList = (agentList || []).filter(agent=>agent.status !== CERTIFICIAL_STATUS.CLIENT_STATUS.ARCHIVED);
      if(insurerAgentList?.length == 1){
        agentId = insurerAgentList[0]?.agentId; 
      } 
    }
    return makeRequest({
      type: API_METHODS.GET,
      url: [ENDPOINTS.SHARE_POLICY.SHARE_POLICY_LIST, userId].join("/"),
      options: {
        params: dispatch(
          checkMultiAgentSupport(
            {
              ...selectedFilter,
              size: CERTIFICIAL.PAGINATION.PAGE_SIZE_20,
              ...(agentId &&{agentId})
            },
            MULTI_AGENTS.API_TYPES.REQUEST_PARAMS
          )
        )
      }
    }).then(
      async (trackedCompanies) => {
        const { insurerSharedPolicies } = getState();
        const { selectedCompany, projectsForSelectedCompany } = insurerSharedPolicies;
        trackedCompanies &&
          dispatch(saveTotalPaggonation(trackedCompanies.totalCount));
        if (
          trackedCompanies &&
          trackedCompanies.companies &&
          trackedCompanies.companies.length
        ) {
          const mappedcompanies =
            trackedCompanies.companies.map((item) => ({
              ...item,
              newName:
                item.certHolder &&
                item.certHolder.name === "Evidence of Insurance"
                  ? item.name
                  : (item.certHolder && item.certHolder.name) || item.name
            })) || [];
          const companies = mappedcompanies;
         await dispatch(insurerSharedPoliciesSuccess(companies));

          if (otherParams && otherParams.listAfterDeleteSharePolicy) {

            const activeProject = _.find(
              projectsForSelectedCompany,
              (p) => p.id === selectedCompany.projectId
            );


            if (selectedCompany?.policies?.length > 1 && activeProject?.id && !selectedFilter?.projectNameFilter?.length
            ) {
              // show 'same company, same project'
              await dispatch(setInsurerSharedProject(activeProject));
              await dispatch(
                sharePolicy_listView_action_getCompany1(
                  userId,
                  activeProject.shareId,
                  activeProject.flag,
                  activeProject.id,
                  selectedCompany,
                  selectedFilter
                )
              );
            } else if (
              selectedCompany?.id &&
              projectsForSelectedCompany?.length > 1 &&
              activeProject?.id &&
              !selectedFilter?.projectNameFilter?.length
            ) {
              // show 'same comapny and other projects'
              const updatedProjectsForSelectedCompany = _.filter(
                projectsForSelectedCompany,
                (prj) => {
                  return prj.id !== activeProject.id;
                }
              );

              const updatedDefaultProject = _.head(
                updatedProjectsForSelectedCompany
              );
              dispatch(setInsurerSharedProject(updatedDefaultProject));
              dispatch(
                insurerSharedProjectsSuccess(updatedProjectsForSelectedCompany)
              );
              await dispatch(
                sharePolicy_listView_action_getCompany1(
                  userId,
                  updatedDefaultProject.shareId,
                  updatedDefaultProject.flag,
                  updatedDefaultProject.id,
                  selectedCompany,
                  selectedFilter
                )
              );
            } else {
              await dispatch(
                getCompanyAvailableProjectsList(
                  userId,
                  (_.head(companies).flag === "company" &&
                    _.head(companies).id) ||
                    null,
                  (_.head(companies).flag === "user" && _.head(companies).id) ||
                    null,
                    (_.head(companies).flag === "printOnly" && _.head(companies).id) ||
                    null,
                    selectedFilter?.projectNameFilter
                )
              ).then(async (res) => {
                if (res && res.length) {
                  const defaultProject = res[0];
                  await dispatch(setInsurerSharedProject(defaultProject));
                  await dispatch(
                    sharePolicy_listView_action_getCompany1(
                      userId,
                      defaultProject.shareId,
                      defaultProject.flag,
                      defaultProject.id,
                      _.head(companies),
                      selectedFilter
                    )
                  );
                }
              });
            }
          } else {
            let updatedCompany = companies
            let companyToFocus = []
            if(selectedShareDataToPersist){
              companyToFocus = companies.filter( i => (i.projectId|| []).includes(selectedShareDataToPersist?.projectId))
            if(companyToFocus?.length){
              updatedCompany = companyToFocus
            }
            }

            const defaultCompany = _.head(updatedCompany);
            await dispatch(
              getCompanyAvailableProjectsList(
                userId,
                (defaultCompany.flag === "company" &&
                defaultCompany.id) ||
                  null,
                (defaultCompany.flag === "user" && defaultCompany.id) ||
                  null,
                (defaultCompany.flag === "printOnly" && defaultCompany.id) ||
                  null,
                  selectedFilter?.projectNameFilter,
                  defaultCompany?.projectId
              )
            ).then(async (res) => {
              if (res && res.length) {
                let defaultProject = res[0];
                if(companyToFocus.length && selectedShareDataToPersist){
                  defaultProject = res.find(i => i.id === selectedShareDataToPersist.projectId)
                }

                await dispatch(setInsurerSharedProject(defaultProject));
                await dispatch(
                  sharePolicy_listView_action_getCompany1(
                    userId,
                    defaultProject.shareId,
                    defaultProject.flag,
                    defaultProject.id,
                    _.head(updatedCompany),
                    selectedFilter,
                    null,
                    defaultProject.requestId
                  )
                );
              } else {
                dispatch(disableListDetailsloading());
              }
            });
          }
        } else {
          let isSearch = false;
          if (selectedFilter.companyName) {
            isSearch = true;
          }
          dispatch(insurerSharedPoliciesSuccess([], isSearch));
          dispatch(getSelectedCompanyByIdSuccessListView({}, isSearch));
          dispatch(insurerSharedProjectsSuccess([]));
          dispatch(disableListDetailsloading());
        }
        dispatch(disableListLoading());
      },
      (error) => {
        dispatch(insurerSharedPoliciesError(error));
        dispatch(disableListLoading());
        dispatch(disableListDetailsloading());
      }
    );
  };
};


export const eoi_delete_policy = (
  payload
) => {
  return (dispatch) => {
    dispatch(enableListDetailsloading());
    return makeRequest({
      type: API_METHODS.DELETE,
      url: `${ENDPOINTS.SHARE_POLICY.REMOVE_EOI_POLICY}`,
      options: { data: payload }
    }).then(
      (response) => {
        if (response) {
          message.success(response);
          message.success(APPLICATION_MESSAGES.SCREEN_AUTO_REFRESH_MESSAGE);
        }
        dispatch(disableListDetailsloading());
      },
      (error) => {
        dispatch(disableListDetailsloading());
        dispatch(deleteSharedPolicyError(error));
      }
    );
  };
};


export const share_policy_deleteShare = (
  payload,
  func,
  companyId,
  selectedFilter,
  shouldUpdateDrawerComapany
) => {
  return (dispatch) => {
    dispatch(enableListDetailsloading());
    return makeRequest({
      type: API_METHODS.DELETE,
      url: `${ENDPOINTS.SHARE_POLICY.SHARE_POLICY_DELETE}`,
      options: { data: payload }
    }).then(
      (response) => {
        if (response) {
          dispatch(deleteSharedPolicySuccess(response));
          message.success(response);

          if (func === "list_view") {
            dispatch(
              sharePolicy_listView_action(companyId, selectedFilter, {
                listAfterDeleteSharePolicy: true
              })
            );
          }
          if (func === "graph_view") {
            dispatch(sharePolicy_graphView_action(companyId));
            if (shouldUpdateDrawerComapany) {
              return companyId;
            }
          }
        }
        dispatch(disableListDetailsloading());
      },
      (error) => {
        dispatch(disableListDetailsloading());
        dispatch(deleteSharedPolicyError(error));
      }
    );
  };
};

export const sharePolicy_ListView_action_getCompany = (
  userCompanyId,
  sharedId,
  entity_type
) => {
  return (dispatch, getState) => {
    dispatch(enableListDetailsloading());
    return makeRequest({
      type: API_METHODS.POST,
      url: `${ENDPOINTS.SHARE_POLICY.SHARE_POLICY_GRAPH_COMPANY}/details`,
      data: {
        companyId: userCompanyId,
        sharedCompanyId: entity_type === "company" ? sharedId : null,
        sharedUserId: entity_type === "user" ? sharedId : null
      }
      // url: `${ENDPOINTS.SHARE_POLICY.SHARE_POLICY_GRAPH_COMPANY}/${userId}/${companyId}`
    }).then(
      (response) => {
        if (response) {
          const { policyTypes : refPolicyType } = getState().app?.applicationReference;
          if (response.policies) {
            let policies = response.policies.map((policy) => {
              mergeReferenceDataToPolicy(policy, refPolicyType);
              if (policy.coverageType) {
                let p = policy.coverageType.map((cType) => {
                  return {
                    ...cType,
                    isApplied: true
                  };
                });
                return { ...policy, coverageType: p };
              } else {
                return policy;
              }
            });

            response = { ...response, policies: [...policies] };
          }
          dispatch(getSelectedCompanyByIdSuccessListView(response));
        } else {
          dispatch(getSelectedCompanyByIdSuccessListView({}));
        }
        dispatch(disableListDetailsloading());
      },
      (error) => {
        dispatch(disableListDetailsloading());
        dispatch(getSelectedCompanyByIdErrorListView(error));
      }
    );
  };
};

export const sharePolicy_listView_action_getCompany1 = (
  userCompanyId,
  sharedId,
  entity_type,
  projectId,
  selectedSharedCompany,
  selectedFilter,
  handle,
  requestId
) => {
  return (dispatch, getState) => {
    dispatch(enableListDetailsloading());
    const data = dispatch(
      checkMultiAgentSupport(
        {
          companyId: userCompanyId,
          flag: entity_type,
          projectId: projectId,
          shareId: sharedId,
          ...(['printOnly','user'].includes(entity_type) && requestId && {requestId})
        },
        MULTI_AGENTS.API_TYPES.REQUEST_DATA
      )
    );
    //Need to skip shareTypeFilter from api request
    delete selectedFilter['shareTypeFilter'];   
    return makeRequest({
      type: API_METHODS.POST,
      url: [ENDPOINTS.SHARE_POLICY.SHARE_POLICY_GRAPH_COMPANY, "details2"].join(
        "/"
      ),
      data,
      options: {
        params: {
          ...selectedFilter
        }
      }
    }).then(
      async (response) => {
        if (response) {
          const { policyTypes : refPolicyType } = getState().app?.applicationReference;
          if (response.insuredUserId) {
            await dispatch(
              getCertificateUploadReRespondStatus(
                response.insuredUserId,
                userCompanyId,
                sharedId,
                projectId,
                response.id,
                response.name,
                data.agentId,
                true
              )
            );
          }

          if (response && response.id && handle == "refresh_status") {
            await dispatch(setRefreshCompanyId(response.id));
          } else {
            await dispatch(setRefreshCompanyId(null));
          }
          if (response.policies) {
            let policies = response.policies.map((policy) => {
              mergeReferenceDataToPolicy(policy, refPolicyType);
              if (policy.coverageType) {
                let p = policy.coverageType.map((cType) => {
                  return {
                    ...cType,
                    isApplied: true
                  };
                });
                return { ...policy, coverageType: p };
              } else {
                return policy;
              }
            });

            response = { ...response, policies: [...policies] };
          }

          let modifiedResponse = 
            {
              ...selectedSharedCompany,
              ...response
            }
          
          await dispatch(
            getSelectedCompanyByIdSuccessListView(modifiedResponse)
          );
        } else {
          await dispatch(getSelectedCompanyByIdSuccessListView({}));
        }
        await dispatch(disableListDetailsloading());
      },
      (error) => {
        dispatch(saveCertUploadReRespondStatus(false));
        dispatch(disableListDetailsloading());
        dispatch(
          getSelectedCompanyByIdErrorListView({
            error,
            selectedSharedCompany
          })
        );
      }
    );
  };
};

export const sharePolicy_graphView_action_getCompany = (
  userCompanyId,
  sharedId,
  entity_type
) => {
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.POST,
      url: `${ENDPOINTS.SHARE_POLICY.SHARE_POLICY_GRAPH_COMPANY}/details`,
      data: {
        companyId: userCompanyId,
        sharedCompanyId: entity_type === "company" ? sharedId : null,
        sharedUserId: entity_type === "user" ? sharedId : null
      }
    }).then(
      (response) => {
        if (response) {
          if (response.policies) {
            let policies = response.policies.map((policy) => {
              if (policy.coverageType) {
                let p = policy.coverageType.map((cType) => {
                  return {
                    ...cType,
                    isApplied: true
                  };
                });
                return { ...policy, coverageType: p };
              } else {
                return policy;
              }
            });

            response = { ...response, policies: [...policies] };
            dispatch(setSelectedCompanyDrawerPolicies(response.policies));
          }
          dispatch(getSelectedCompanyByIdSuccess(response));
        } else {
          dispatch(getSelectedCompanyByIdSuccess({}));
        }
      },
      (error) => {
        dispatch(getSelectedCompanyByIdError(error));
      }
    );
  };
};

export const download_documents_zip = (
  projectId,
  shareId,
  shareHolderName
) => {
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.GET,
      url: `${ENDPOINTS.SHARE_POLICY.DOWNLOAD_DOCUMENTS_ZIP}`,
      options: {
        responseType: "arraybuffer",
        params: {
          projectId: projectId,
          shareId: shareId,
          certHolderName: shareHolderName
        }
      }
    }).then((res) => {
      showFile(res, "policydocs.zip");
    })
  };
};

export const sharePolicy_graphView_action_getCompany1 = (
  userCompanyId,
  sharedId,
  entity_type,
  projectId,
  status
) => {
  return (dispatch) => {
    dispatch(enableCompanyDetailsLoading());
    return makeRequest({
      type: API_METHODS.POST,
      url: [ENDPOINTS.SHARE_POLICY.SHARE_POLICY_GRAPH_COMPANY, "details2"].join(
        "/"
      ),
      data: {
        companyId: userCompanyId,
        flag: entity_type,
        projectId: projectId,
        shareId: sharedId
      }
    }).then(
      (response) => {
        if (response) {
          if (response.insuredUserId) {
            dispatch(
              getCertificateUploadReRespondStatus(
                response.insuredUserId,
                userCompanyId,
                sharedId,
                projectId,
                response.id,
                response.name,
                null,
                true
              )
            );
          }
          if (response.policies) {
            let policies = response.policies.map((policy) => {
              if (policy.coverageType) {
                let p = policy.coverageType.map((cType) => {
                  return {
                    ...cType,
                    isApplied: true
                  };
                });
                return { ...policy, coverageType: p };
              } else {
                return policy;
              }
            });

            response = { ...response, status, policies: [...policies] };
            dispatch(setSelectedCompanyDrawerPolicies(response.policies));
          }
          dispatch(getSelectedCompanyByIdSuccess(response));
        } else {
          dispatch(getSelectedCompanyByIdSuccess({}));
        }
        dispatch(disableCompanyDetailsLoading());
      },
      (error) => {
        dispatch(getSelectedCompanyByIdError(error));
        dispatch(saveCertUploadReRespondStatus(false));
        dispatch(disableCompanyDetailsLoading());
      }
    );
  };
};

export const sharePolicy_graphView_count = (id) => {
    return makeRequest({
      type: API_METHODS.GET,
      url: `${ENDPOINTS.SHARE_POLICY.SHARE_POLICY_COUNT}/${id}`
    })
  };

export const sharePolicy_graphView_action = (id) => {
  return (dispatch) => {
    dispatch(enableListLoading());
    return makeRequest({
      type: API_METHODS.GET,
      url: `${ENDPOINTS.SHARE_POLICY.SHARE_POLICY_GRAPH}/${id}`
    }).then(
      (response) => {
        if (response && response.children) {
          let res = _.map(response.children, function (policyType) {
            if (
              policyType &&
              policyType.children &&
              policyType.children.length > 0
            ) {
              return {
                ...policyType,
                size: 1
              };
            }
          });
          res = _.without(res, undefined);
          let array = [];
          let result = _.map(res, function (policyType) {
            return _.map(policyType.children, function (company) {
              let a = {
                policyType: { ...policyType },
                company: { ...company },
                source: policyType.name,
                target: company.name,
                source_type: "policyType",
                // target_type: company.flag
                target_type: "company"
              };
              array.push(a);

              return {
                policyType: { ...policyType },
                company: { ...company },
                source: policyType.name,
                target: company.name,
                source_type: "policyType",
                // target_type: company.flag
                target_type: "company"
              };
            });
          });

          // array = _.sortBy(array, "source").reverse();
          array = _.orderBy(
            array,
            [(item) => item.target.toLowerCase()],
            ["desc"]
          ).reverse();
          dispatch(
            getSharedPolicyGraphSuccess({
              array
            })
          );
        } else {
          dispatch(getSharedPolicyGraphSuccess({}));
        }
        dispatch(disableListLoading());
        return !!response;
      },
      (error) => {
        dispatch(disableListLoading());
        dispatch(getSharedPolicyGraphError(error));
      }
    );
  };
};

export const getMyPoliciesToShare = (
  userId,
  templateid,
  callback,
  isRequestFromAgent,
  agentId
) => {
  const params = { templateid: templateid || 0, agentId };
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.POST,
      url: [ENDPOINTS.SHARE_POLICY.MY_SHAREABLE_POLICIES].join("/"),
      data: !isRequestFromAgent
        ? { companyId: userId, templateId: templateid || 0 }
        : { companyId: userId, templateId: templateid || 0, agentId, role:"agent" }
    }).then(
      (sharablePolicies) => {
        if (sharablePolicies) {
          let policyList = sharablePolicies.policies;
          if (isRequestFromAgent && agentId) {
            let unassignedPolicies = policyList.filter(
              (o) => !(o.agency && o.agent)
            );
            let loggedinAgencyPolicies = policyList.filter(
              (o) => o.agent && o.agent.id === agentId
            );
            policyList = [
              ...unassignedPolicies,
              ...loggedinAgencyPolicies
            ];
          }
          dispatch(insurerSharablePoliciesSuccess(policyList));
        } else {
          dispatch(insurerSharablePoliciesSuccess([]));
        }
        callback && callback();
      },
      (error) => {
        dispatch(insurerSharablePoliciesError(error));
        callback && callback();
      }
    );
  };
};

export const getAllSelectedDocuments = (projectId, shareId, agentId, page, size) => {
  const url = [ENDPOINTS.SHARE_POLICY.GET_ALL_SHARED_DOCS];
  return makeRequest({
    type: API_METHODS.POST,
    url: url,
    data: {
        page: page,
        size: size,
        shareId:shareId,
        projectId: projectId,
        agentId: agentId }
  });
};

export const getMyPoliciesToReShare = (
  payload,
  callback,
  isRequestFromAgent,
  agentId
) => {
  return (dispatch, getState) => {
    return makeRequest({
      type: API_METHODS.POST,
      url: [ENDPOINTS.SHARE_POLICY.MY_RESHAREABLE_POLICIES],
      data: { ...payload, agentId }
    }).then(
      (sharablePolicies) => {
        const {selectedCompany} = getState().insurerSharedPolicies;
        const coverageTemplateData = selectedCompany?.coverageTemplateData
        const tempArray =
          (sharablePolicies &&
            sharablePolicies.sharedPolicy &&
            sharablePolicies.sharedPolicy.length &&
            sharablePolicies.sharedPolicy.map((o) => {
              return { ...o, lastShared: true };
            })) ||
          [];
        let allPolicyUnderReShare = [
          ...sharablePolicies.policies,
          ...(tempArray || [])
        ];

        allPolicyUnderReShare = _.orderBy(
          allPolicyUnderReShare,
          [(policy) => policy.policyType.name.toLowerCase()],
          ["asc"]
        );

        if (isRequestFromAgent && agentId) {
          const unassignedPolicies = allPolicyUnderReShare.filter(
            (o) => !(o.agency && o.agent)
          );
          const loggedinAgencyPolicies = allPolicyUnderReShare.filter(
            (o) => o.agent && o.agent.id === agentId
          );
          allPolicyUnderReShare = [
            ...unassignedPolicies,
            ...loggedinAgencyPolicies
          ];
        }
        //If coverage template is presen in share then modify myPoliciesToShare data
        let myPoliciesToShare
        if(coverageTemplateData?.id){
          const policiesToBeReplacedKeys = _.compact(allPolicyUnderReShare.map(policy=>{
            if(policy.lastShared == true && policy?.policyToBeReplaced)
            {
              return policy.policyToBeReplaced
            }else{
              return null
            }
          }));
          myPoliciesToShare = allPolicyUnderReShare.filter(p=>p.lastShared == true || policiesToBeReplacedKeys.includes(p.policyId))
        }else{
          myPoliciesToShare = allPolicyUnderReShare;
        }

        dispatch(insurerReSharableDataSuccess(
          {
            ...sharablePolicies,
            myPoliciesToShare:myPoliciesToShare,
            ...(coverageTemplateData?.id && {
               selectedClientTemplateForShare:coverageTemplateData?.id,
               reshareByTemplatePolicyBackup:allPolicyUnderReShare 
            })
          }));
        callback && callback();
      },
      (error) => {
        dispatch(insurerSharablePoliciesError(error));
        dispatch(insurerReSharableDataError(error));
        callback && callback();
      }
    );
  };
};

const setDefaultProject = (projects = [])=>{
  (projects||[]).map(project=>{
    project.default = project.requestorDefault || false;
  })
}
/**
 * request to delete the company project templates
 */
export const getCompanyAvailableProjectsList = (
  companyId,
  sharedCompanyId,
  sharedUserId,
  claimId,
  projectNameFilter = null,
  projectId
) => {
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.SHARE_POLICY.COMPANY_AVAILABLE_PROJECT_LIST,
      data: dispatch(
        checkMultiAgentSupport(
          {
            companyId,
            sharedCompanyId,
            sharedUserId,
            claimId,
            ...( typeof projectNameFilter === 'string' && {projectNameFilter:projectNameFilter.split(',')}),
            projectId
          },
          MULTI_AGENTS.API_TYPES.REQUEST_DATA
        )
      )
    }).then(
      (projects) => {
        projects = getSortedProjects(projects, 'name')
        setDefaultProject(projects)
        dispatch(insurerSharedProjectsSuccess(projects));
        return projects ? projects : [];
      },
      (error) => {
        dispatch(insurerSharedPoliciesError(error));
        dispatch(disableListLoading());
      }
    );
  };
};

export const updateNewEmail = (model) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.SHARE_POLICY.UPDATE_EMAIL,
    data: model
  });
};

export const editEmailCcOption = (data) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.SHARE_POLICY.EDIT_CC_OPTION,
    data: data
  });
};

export const check_company_associated = (email) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: [ ENDPOINTS.SHARE_POLICY.CHECK_ASSOCIATE_COMPANY ].join("/"),
    options: {
      params: {
          email: email.email,
          insuredCompanyId: email.insuredCompanyId
        }
      }
    });
};
export const getCertificateUploadReRespondStatus = (
  insuredUserId,
  companyId,
  shareId,
  projectId,
  requestor_comp_id,
  requestor_comp_name,
  agentId,
  reRespond
) => {
  return (dispatch, getState) => {
    dispatch(enableListDetailsloading());
    const {
      userData: { id: userid }
    } = getState().myProfile;
    return makeRequest({
      type: API_METHODS.GET,
      url: [ENDPOINTS.SHARE_POLICY.GET_CERTIFICATE_UPLOAD_STATUS_RE_RESPOND],
      options: {
        params: {
          insuredUserId,
          insuredCompanyId: companyId,
          shareId,
          projectId,
          agentId
        }
      }
    }).then(
      (resp) => {
        if (resp) {
          resp.requestor_comp_id = requestor_comp_id;
          resp.requestor_comp_name = requestor_comp_name;
          dispatch(saveCertUploadReRespondStatus(resp));
          if (
            resp.enableButton &&
            (resp.respondToRequest || resp.certUpload) &&
            resp.requestId
          ) {
            dispatch(enableCustomLabelLoading("reRespondPoliciesLoading"));
            return makeRequest({
              type: API_METHODS.GET,
              url: [
                ENDPOINTS.PENDING_INSURED_REQUEST
                  .GET_INSURER_PENDING_REQUEST_DETAILS,
                companyId,
                resp.requestId,
                userid
              ].join("/"),
              options: {
                params: { reRespond }
              }
            }).then(
              (pendingInsuredRequestDetails) => {
                if (pendingInsuredRequestDetails) {
                  dispatch(
                    requestedRequestDetailSuccess({
                      ..._.head(pendingInsuredRequestDetails)
                    })
                  );
                }
                dispatch(disableListDetailsloading());
                dispatch(disableCustomLabelLoading("reRespondPoliciesLoading"));
              },
              (error) => {
                dispatch(requestedRequestDetailError(error));
                dispatch(disableCustomLabelLoading("reRespondPoliciesLoading"));
                dispatch(disableListDetailsloading());
              }
            );
          }
        }
      },
      (err) => {
        dispatch(saveCertUploadReRespondStatus(false));
      }
    );
  };
};
