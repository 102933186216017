import { ACCORD_MAPPING, NO_PREFERENCE_ACORD_FORM } from "../Constants/Application.constants";
import _ from "lodash";


export const getUpdatedEndorsementsWithAcordDropdownValue = (selectedPolicies, defaultDooValues, acordFormDropdownValues = [], isRequester, isRespondFlow = false)=>{
	const existingSpecialEndorsements = [...defaultDooValues];
	let acordFormDropdownValuesClone = [...acordFormDropdownValues];
	let updatedSpecialEndorsemnts = [];
	let selectedPolicyTypeIds = []
	selectedPolicies.forEach(policyTypeData => {
		const acordFormDetails = policyTypeData?.acordFormDetails;
		const acordFormData = acordFormDetails?.acordFormData;
		const showShareDropdownForm = acordFormDetails?.showShareDropdownForm;
		const multipleFormSupport = acordFormDetails?.multipleFormSupport;
		const policyTypeId = !!policyTypeData.policyTypeId ? policyTypeData.policyTypeId : policyTypeData?.policyType?.id;
		selectedPolicyTypeIds.push(policyTypeId);
		let selectedAcordFormNumber = getAcordFormNumberForPolicy(policyTypeData, acordFormDropdownValuesClone, isRequester || isRespondFlow);
		let dooToAdd;
		if (showShareDropdownForm) {
			const isNoPreferenceSelectedFromDropdown = (selectedAcordFormNumber == NO_PREFERENCE_ACORD_FORM);
			const isAnythingSelectedFromDropdown = acordFormDropdownValuesClone.find(data=>data.policyTypeId == policyTypeId);
			dooToAdd = acordFormData.find(formData => {
				let _selectedAcord = selectedAcordFormNumber
				if(isNoPreferenceSelectedFromDropdown){
					_selectedAcord = getPolicyLevelAcordFormNumber(policyTypeData, true)
				}
				return (formData.formNumber == _selectedAcord)
			}
			);

			if(!isAnythingSelectedFromDropdown){
					if(isRequester){
						acordFormDropdownValuesClone.push({
							policyTypeId : policyTypeId,
							acordFormNumber: NO_PREFERENCE_ACORD_FORM
						})  
					}else {
								acordFormDropdownValuesClone.push({
									policyTypeId : policyTypeId,
									acordFormNumber: dooToAdd?.formNumber
								})
						}
				}
		} else if (multipleFormSupport) {
			dooToAdd = acordFormData.find(formData => formData.formNumber == selectedAcordFormNumber);
		} else {
			dooToAdd = acordFormData[0];
		}

		updatedSpecialEndorsemnts.push({
			id: dooToAdd.specialEndorsementId,
			specialEndorsementId: dooToAdd.specialEndorsementId,
			specialEndorsement:dooToAdd.specialEndorsement,
			value: ''
		});
	});

	updatedSpecialEndorsemnts = _.uniqBy(updatedSpecialEndorsemnts, "specialEndorsementId");
	updatedSpecialEndorsemnts = updatedSpecialEndorsemnts.map(data=>{
		const existingValue = existingSpecialEndorsements.find(_data=>_data.specialEndorsementId == data.specialEndorsementId);
		if(existingValue){
			data.value = existingValue.value
			data.originalValue = existingValue.originalValue
		}
		return {
			...existingValue,
			...data
		}

	})

	// acordFormDropdownValuesClone = acordFormDropdownValuesClone.filter(data=>selectedPolicyTypeIds.includes(data.policyTypeId));
	return {spEndorsements:updatedSpecialEndorsemnts, acordFormDropdownValues : acordFormDropdownValuesClone};
}

export const getFormLabel = (policyData, selectedAcordForm) => {
	const acordFormData = policyData.acordFormDetails.acordFormData;
	const labelData = acordFormData.find(item=>item.formNumber === selectedAcordForm);
	return labelData;
}

const getPolicyLevelAcordFormNumber = (policy, isRequester)=>{
	const acordFormData = policy?.acordFormDetails?.acordFormData
	let formNumber;
	if(!isRequester && !!policy.acordFormSpecialEndorsementMappingId){
		formNumber = acordFormData.find(formData=>formData.acordFormSpecialEndorsementMappingId == policy.acordFormSpecialEndorsementMappingId)?.formNumber;
		if(!!formNumber){
			return formNumber;
		}
	}
	if(isRequester){
		formNumber = (acordFormData || []).find(data=>data?.requesterDefaultFormFlag)?.formNumber;
	}

	if(!formNumber || !isRequester){
		formNumber = (acordFormData || []).find(data=>data?.defaultFormFlag)?.formNumber;
	}
	return formNumber;
}


export const getAcordFormNumberForPolicy = (policy, selectedAcord, isRequester) => {
	const policyTypeId = policy?.policyTypeId ? policy?.policyTypeId : policy?.policyType?.id;
	if(!policyTypeId){
		return;
	}
	const value = (selectedAcord || []).find(data => data.policyTypeId == policyTypeId)?.acordFormNumber;
	let _selectedAcord;
	if (value) {
		_selectedAcord = value;
	} else {
		if(isRequester){
			_selectedAcord = getPolicyLevelAcordFormNumber(policy, isRequester);
		}else{
			_selectedAcord = policy?.selectedAcordForm || getPolicyLevelAcordFormNumber(policy);
		}
	}
	return _selectedAcord;
}

export const getSelectedAcordMappingId = (policy, selectedAcord, isRequester)=>{
  const formNumber = getAcordFormNumberForPolicy(policy, selectedAcord, isRequester);
  const acordFormDataObject = policy.acordFormDetails?.acordFormData.find(data=>data?.formNumber == formNumber);
  return acordFormDataObject?.acordFormSpecialEndorsementMappingId || null;
}

export const getSelectedAcordObject = (acordFormDropdownValues = [], policy, isRequester = false)=>{
	let selectedAcord = getAcordFormNumberForPolicy(policy, acordFormDropdownValues, isRequester);
	const acordFormData = (policy?.acordFormDetails?.acordFormData || []);
	const res = acordFormData.find(data=>data?.formNumber == selectedAcord);
	return res;
}