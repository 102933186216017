import React from 'react'
import { Icon, Alert } from 'antd';
import "./EmailComponents.less";

const SuccessMessage = ({title, subTitle})=>{
	return (<div className="validation-msg">
		<Alert
			banner
			showIcon={false}
			
			message={
				(<><div style={{textAlign:'center'}}>
					<p>
						<Icon style={{ fontSize: "45px", color: "#49ad17" }} type="check-circle" theme="filled" className="tickColor" />
					</p>
					<h3 style={{marginBottom:'10px'}} className="color-primary title"
					>{title}</h3>
					{subTitle ? <p className="sub-title">{subTitle}</p> : null}
					<p className="sub-title">You may now close this window.</p>
				</div></>)}
		/>
	</div>)
}

export default SuccessMessage;