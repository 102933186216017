import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Empty,
  Icon,
  message,
  Modal,
  Popover,
  Row,
  Tag,
  Tooltip,
  Typography,
  Upload
} from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  bookmarkComplianceIcon,
  bookmarkIcon,
  clientSettingsIcon,
  emptyImage
} from "../../../Assets/assets";
import ErrorBoundary from "../../../HOCs/ErrorBoundary/ErrorBoundary";
import { downloadUploadedFile } from "../../../Store/Modules/Application/Application.asyncRequest";
import { CERTIFICIAL_LABEL } from "../../../Utils/Constants/App.labels";
import {
  APPLICATION_USER_ROLES,
  CERTIFICIAL,
  CERTIFICIAL_STATUS
} from "../../../Utils/Constants/Application.constants";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import {
  shouldShowEndorsementsOnSpecialEndorsements,
  showFile,
  titleMaintainer
} from "../../../Utils/utility/app.utility";
import { _modalService } from "../../../Utils/wrappers/Modal/ModalWrapper";
import CertInfoTooltip from "../../UI/CertTooltip/CertInfoTooltip";
import EndorsmentManagement from "./EndorsmentManagement";
import { PolicyEndorsementGroupUpload } from "./PolicyEndorsementGroupUpload";
import ReviewEndorsementExceptionManagement from "./ReviewEndorsementExceptionManagement";
const { Paragraph } = Typography;
const { confirm } = Modal;

export const PolicyEndorsment = ({
  isDisabledDueToArchive,
  source,
  isEndorsementDocumentCompliance,
  isEndorsementDocumentRequiresReview,
  comp_key,
  title,
  isTitleRequired,
  readOnlyMode,
  policyEndorsements,
  onCheck,
  bordered,
  standardLanguage,
  isStandardLanguageEditable,
  onStandardLanguageChange,
  shouldManageEndorsment,
  onEndorsmentManagementChange,
  customEmptyMessage,
  endorsementUploadedDocument,
  shouldEndorsementDocumentUpload,
  onEndorsementDocumentUpload,
  includeEndorsementDocument,
  includeEndorsementDocumentText,
  onIncludeEndorsementDocument,
  shouldDisbleOnEndorsmentCount,
  overrideAppliedFlag,
  overrideCountFlag,
  policyEndorsementsCompliance,
  addEndorsementsInException,
  handlingException,
  exceptionDefaultCheck,
  overrideAgentTooltipFlag,
  agentUploadingDocument,
  agentPolicyApprovalUploadingDocument,
  loading,
  oneTimeEndorsementDocuments,
  requestSpecialEndorsements,
  endorsementUploadedGroupDocument,
  shouldEndorsementDocumentGroupUpload,
  openDrawerForEndorsementGrouping,
  onDeleteEndorsementGroupDocument,
  overrideReadOnlyForGroupDocuments,
  endorsementReadOnlyUploadedDocument,
  onDeleteAgentDoc,
  disableAdditionalInsured,
  endorsmentSaveActionLoading,
  isRequestFromAgent,
  isReresponded,
  endorsementSaveAgent,
  hideCheckBox,
  isOtherAgent,
  accessingModule,
  agentClientCoverageTemplate,
  isReviewRequiredDocument,
  isReviewRequiredDocumentText,
  exceptionHandlingReviewProps,
  isbulkShare,
  isNewPolicy,
  sliceEndorsementText,
  isPolicyToBeReplaced,
  policy,
  regionId,
  policyType
}) => {


  const { regionWisePolicyTypeData } = useSelector((state) => state.app);
  let regionPolicyTypes = regionWisePolicyTypeData && regionWisePolicyTypeData[[regionId]] || []
  let policyEndorsementsUpdated = policyEndorsements;
  if(accessingModule == 'dashboard' && !!regionPolicyTypes?.length){
    const policyTypes = _.cloneDeep(regionPolicyTypes);
    let refPolicy = (policyTypes.find(_policy=>policyType == _policy.name));
    if(refPolicy){
      let refPolicyCoverages = refPolicy?.endorsementTypes
      let selectedCoverageTypes = refPolicyCoverages?.length ?  _.unionBy(policyEndorsements, refPolicyCoverages, 'name')  : null;
      policyEndorsementsUpdated = selectedCoverageTypes;
    }
  }


  const endorsementFileNameLength = source && source === 'ProjectTemplate' ? 30 : 35;
  const { role: accessingRole } = useSelector(
    (state) => state.app.currentSession
  );
  const {    
    selectedClientTemplateForShare
  } = useSelector((state) => {
    return state.insurerSharedPolicies;
  });

  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);
  const onEndorsementDocumentDownload = (end) => {
    if (end && end.attachedDocumentPath && end.attachedDocumentName) {
      setFileDownloadLoading(true);
      downloadUploadedFile(end.attachedDocumentPath).then(
        (res) => {
          message.success(end.attachedDocumentName + " download successfully.");
          showFile(res, end.attachedDocumentName);
          setFileDownloadLoading(false);
        },
        () => {
          setFileDownloadLoading(false);
        }
      );
    } else {
      message.error("Unable to download file.");
    }
  };

  const _isCompliance =
    policyEndorsementsCompliance && policyEndorsementsCompliance.length
      ? _.filter(
          policyEndorsementsCompliance,
          (end) =>
            !!end.isDocumentComplianceIssue && !!!end.isDocumentRequiresReview
        )
      : null;

  const _requiresReview =
    policyEndorsementsCompliance && policyEndorsementsCompliance.length
      ? _.filter(
          policyEndorsementsCompliance,
          (end) =>
            !!!end.isDocumentComplianceIssue && !!end.isDocumentRequiresReview
        )
      : null;

  const _isDocRequiresReview =
    _isCompliance &&
    !_isCompliance.length &&
    _requiresReview &&
    _requiresReview.length;

  const text = (
    <span>
      <Avatar
        src={bookmarkComplianceIcon}
        size="small"
        shape="square"
        className="margin-right-sm"
      />
      {title || "Endorsements"}
    </span>
  );

  const generateView = (array) => {
    let getRowClass = (endorsement)=>{
      let className = (endorsement[comp_key] ||
      (!endorsement[comp_key] &&
        endorsement.isDocumentComplianceIssue &&
        !endorsement.missingEndorsementDocument)
        ? "coverage-compliance margin-bottom-sm"
        : "margin-bottom-sm")
      

      className = !endorsement.applied && endorsement.isEndorsementRequired && source !== "RespondSummaryList" ? className+" endorsement-highlight" : className;

      className = endorsement.isEndorsementDocumentRequired && source !== "RespondSummaryList" ? className+" endorsement-document-highlight" : className;

      return className;
    }

    return (
      <div>
        {array &&
          array.length &&
          _.sortBy(array, [
            "requestedEndorsementTypeId",
            "endorsementTypeId",
            "endorsementId",
            "id"
          ]).map((endorsement, idx) => {
            const checkedStatus = handlingException
              ? { defaultChecked: exceptionDefaultCheck }
              : isbulkShare ? {checked: !!endorsement.selected}: { checked: !!endorsement.applied };
            return (
              <div key={idx} style={{ position: "relative" }}>
                <Row
                  type="flex"
                  align="middle"
                  className={
                    getRowClass(endorsement)
                  }
                >
                  <Col
                    span={
                      endorsement[comp_key] &&
                      endorsement.missingEndorsementDocument
                        ? 12
                        : !handlingException &&
                          !endorsement.missingEndorsementDocument &&
                          endorsement.isDocumentRequiresReview
                        ? 16
                        : !endorsement.missingEndorsementDocument &&
                          endorsement.isDocumentRequiresReview
                        ? 9
                        : endorsement.missingEndorsementDocument
                        ? 12
                        : endorsement[comp_key]
                        ? 24
                        : shouldManageEndorsment ||
                          ((endorsementUploadedDocument ||
                            endorsementReadOnlyUploadedDocument ||
                            endorsementUploadedGroupDocument) &&
                            (!!endorsement.attachedDocumentName ||
                              !!endorsement.groupAttachedDocumentName)) ||
                          oneTimeEndorsementDocuments ||
                          shouldEndorsementDocumentGroupUpload || (isPolicyToBeReplaced && (endorsement?.oldPolicyAttachedDocumentName !=null))
                        ? 12
                        : includeEndorsementDocument &&
                          includeEndorsementDocumentText &&
                          (endorsement.includeEndorsementDocument ||
                            !readOnlyMode)
                        ? 12
                        : !readOnlyMode &&
                          shouldEndorsementDocumentUpload &&
                          ((agentUploadingDocument && endorsement.isOneTime) ||
                            agentPolicyApprovalUploadingDocument)
                        ? 10
                        : 24
                    }
                  >
                    <Tooltip
                      title={ selectedClientTemplateForShare? "":
                        !isRequestFromAgent &&
                        accessingRole !== APPLICATION_USER_ROLES.AGENT
                          ? (!overrideCountFlag &&
                              ((!isStandardLanguageEditable &&
                                !!endorsement.endorsementCount &&
                                !!endorsement.endorsementUsed &&
                                (endorsement.endorsementCount ===
                                  endorsement.endorsementUsed ||
                                  (endorsement.endorsementCount !== -1 &&
                                    endorsement.endorsementCount <
                                      endorsement.endorsementUsed)) &&
                                CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                                  .MAXIMUM_SHARE_REACHED_MESSAGE) ||
                                (shouldDisbleOnEndorsmentCount &&
                                  endorsement.endorsementCount !== -1 &&
                                  (!!!endorsement.endorsementCount ||
                                    endorsement.endorsementCount === 0) &&
                                  CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                                    .AGENT_REVIEW_REQUIRED_ON_ENDORSEMENT_MESSAGE))) ||
                            null
                          : null
                      }
                      placement="topLeft"
                    >
                      {hideCheckBox ||
                      (handlingException &&
                        endorsement.isDocumentRequiresReview) ? (
                          sliceEndorsementText !== undefined && sliceEndorsementText === true ? (<Tooltip
                            title={
                              endorsement.name
                            }
                            placement="left"
                            color={'#3596db'}
                          >
                            {handlingException ? titleMaintainer(endorsement.name, 8) : endorsement.name}
                          </Tooltip>) : (endorsement.name)
                      ) : (
                        <span style={{minWidth:"175px", display:"inline-block"}}>
                          {isReviewRequiredDocument &&
                          endorsement.isRequiresReview ? (
                            <span className="endorsement-requires-review-view">
                              <Tooltip title={isReviewRequiredDocumentText}>
                                <Icon
                                  type="warning"
                                  theme="filled"
                                  className="color-warning"
                                />
                              </Tooltip>
                            </span>
                          ) : null}

                          <Checkbox
                            onChange={
                              addEndorsementsInException
                                ? (e) => {
                                    addEndorsementsInException({
                                      ...endorsement,
                                      applied: (accessingModule && accessingModule === 'Respond' && source != "ProjectTemplate") ? true : e.target.checked
                                    });
                                  }
                                : (e) => {
                                    onCheck(e, isbulkShare ? endorsement.name : endorsement.id);
                                  }
                            }
                            disabled={
                              isDisabledDueToArchive ||
                              isOtherAgent ||
                              readOnlyMode ||
                              endorsement.disableEndorsement ||
                              (disableAdditionalInsured && endorsement.id === 1)
                            }
                            className={
                              endorsement[comp_key]
                                ? "compliance-issue"
                                : endorsement["isDocumentRequiresReview"]
                                ? "review-issue"
                                : ""
                            }
                            {...checkedStatus}
                          >
                              {sliceEndorsementText !== undefined && sliceEndorsementText === true ? (<Tooltip
                                title={
                                  endorsement.name
                                }
                                placement="left"
                                color={'#3596db'}
                              >
                                {handlingException ? titleMaintainer(endorsement.name, 10) : endorsement.name}
                              </Tooltip>) : (endorsement.name)}                            
                          </Checkbox>
                        </span>
                      )}
                    </Tooltip>
                    {!endorsement.applied && endorsement.isEndorsementRequired && source !== "RespondSummaryList" ? <span className="endorsement-required">Endorsement Required</span> : null}
                  </Col>
                  {(includeEndorsementDocument &&
                    includeEndorsementDocumentText &&
                    !readOnlyMode && (
                      <Col span={12}>
                        {(readOnlyMode && null) || (
                          <Checkbox
                            onChange={(e) => {
                              onIncludeEndorsementDocument(e, endorsement.id);
                            }}
                            disabled={
                              isDisabledDueToArchive ||
                              !endorsement.applied ||
                              (disableAdditionalInsured && endorsement.id === 1)
                            }
                            checked={endorsement.includeEndorsementDocument}
                          >
                          {sliceEndorsementText !== undefined && sliceEndorsementText === true ? (
                            <Tooltip
                              title={
                                includeEndorsementDocumentText
                              }
                              placement="left"
                              color={'#3596db'}
                            >
                              {titleMaintainer(includeEndorsementDocumentText, 10)}
                            </Tooltip>) : (includeEndorsementDocumentText)}
                          </Checkbox>
                        )}
                      </Col>
                    )) ||
                    null}
                  {(readOnlyMode &&
                    endorsement.applied &&
                    endorsement.includeEndorsementDocument &&
                    !endorsement.missingEndorsementDocument &&
                    !endorsement.isDocumentRequiresReview &&
                    includeEndorsementDocumentText && (
                      <Col
                        span={12}
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          textAlign:"right"
                        }}
                      >
                        {/* <Icon
                          type="check-circle"
                          style={{
                            color: "#49ad17",
                            marginRight: 8
                          }}
                        /> */}
                      {sliceEndorsementText !== undefined && sliceEndorsementText === true ? (
                        <Tooltip
                          title={
                            includeEndorsementDocumentText
                          }
                          placement="left"
                          color={'#3596db'}
                        >
                         <label style={{color:'#faad14'}}>{includeEndorsementDocumentText}</label>  {/* {titleMaintainer(includeEndorsementDocumentText, 10)} */}
                        </Tooltip>) : (<label style={{color:'#faad14'}}>{includeEndorsementDocumentText}</label>)}
                      </Col>
                    )) ||
                    null}
                  {(shouldManageEndorsment && (
                    <Col span={12}>
                      <div>
                        <EndorsmentManagement
                          onEndorsmentChange={(value) =>
                            onEndorsmentManagementChange(value, endorsement)
                          }
                          disabled={
                            isDisabledDueToArchive ||
                            isOtherAgent || readOnlyMode || !endorsement.applied
                          }
                          value={endorsement.endorsementCount}
                        />
                      </div>
                    </Col>
                  )) ||
                    null}
                  {(((endorsementUploadedDocument &&
                    !!endorsement.attachedDocumentName &&
                    endorsement.groupAttachedDocumentName !==
                      endorsement.attachedDocumentName) || (isPolicyToBeReplaced && (endorsement?.oldPolicyAttachedDocumentName !=null)) ) && (
                      <Col span={12}>
                        <p
                          title={(isPolicyToBeReplaced && (endorsement?.oldPolicyAttachedDocumentName !=null)) ? endorsement?.oldPolicyAttachedDocumentName : endorsement.attachedDocumentName}
                          style={{
                            textAlign: "right",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                          className="flush-margin-bottom"
                        >
                        {
                          source === "ProjectTemplate" ?
                            endorsement?.isDocumentAttached && endorsement.isDocumentAttached === true ? 
                              (
                                <>
                                  <Icon type="paper-clip" />{" "}
                                  {titleMaintainer(
                                    (isPolicyToBeReplaced && (endorsement?.oldPolicyAttachedDocumentName != null)) ? endorsement?.oldPolicyAttachedDocumentName : endorsement.attachedDocumentName,
                                    endorsementFileNameLength
                                  )}
                                </>
                              ) : readOnlyMode === false?

                              (<>
                                <Icon type="paper-clip" />{" "}
                                {titleMaintainer(
                                  (isPolicyToBeReplaced && (endorsement?.oldPolicyAttachedDocumentName != null)) ? endorsement?.oldPolicyAttachedDocumentName : endorsement.attachedDocumentName,
                                  endorsementFileNameLength
                                )}
                              </>):(<></>)
                          : (
                            <>
                              <Icon type="paper-clip" />{" "}
                              {titleMaintainer(
                                (isPolicyToBeReplaced && (endorsement?.oldPolicyAttachedDocumentName != null)) ? endorsement?.oldPolicyAttachedDocumentName : endorsement.attachedDocumentName,
                                endorsementFileNameLength
                              )}
                            </>
                          )
                        }         
                          {(!readOnlyMode && (
                            <Tooltip
                              placement="topRight"
                              title={
                                !readOnlyMode
                                  ? endorsement.applied
                                    ? CERTIFICIAL_LABEL.WIDGETS
                                        .POLICY_ENDORSMENT
                                        .DELETE_ENDORSEMENT_DOCUMENT
                                    : CERTIFICIAL_LABEL.WIDGETS
                                        .POLICY_ENDORSMENT
                                        .SELECT_ENDORSEMENT_TO_DELETE_DOCUMENT
                                  : null
                              }
                              arrowPointAtCenter
                            >
                              <Icon
                                type="delete"
                                style={{
                                  color: !!!endorsement.applied
                                    ? "#aaa"
                                    : "orangered",
                                  right: 0,
                                  top: 5,
                                  marginLeft: "10px",
                                  fontSize: "inherit"
                                }}
                                onClick={() => {
                                  if (
                                    !isDisabledDueToArchive ||
                                    !!endorsement.applied
                                    ) {
                                    onDeleteAgentDoc(endorsement);
                                  }
                                }}
                              />
                            </Tooltip>
                          )) ||
                            null}
                        </p>
                      </Col>
                    )) ||
                    (endorsementReadOnlyUploadedDocument &&
                      readOnlyMode &&
                      !!endorsement.applied &&
                      !!endorsement.attachedDocumentName && (
                        <Col span={12}>
                          <p
                            onClick={() =>
                              !fileDownloadLoading
                              || !isDisabledDueToArchive
                                ? onEndorsementDocumentDownload(endorsement)
                                : {}
                            }
                            title={endorsement.attachedDocumentName}
                            className="flush-margin-bottom end-attachement-link"
                          >
                            <Icon type="paper-clip" />{" "}
                            {endorsement.attachedDocumentName}
                          </p>
                        </Col>
                      )) ||
                    (endorsementUploadedGroupDocument &&
                      !!endorsement.groupAttachedDocumentName &&
                      !!!endorsement.isAgentDoc && (
                        <Col span={12}>
                          <p
                            style={{
                              textAlign: "right",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width:
                                overrideReadOnlyForGroupDocuments &&
                                !!endorsement.isAgentDoc
                                  ? "100%"
                                  : overrideReadOnlyForGroupDocuments &&
                                    !!endorsement.attachedDocumentId &&
                                    !!!endorsement.groupAttachedDocument
                                  ? "85%"
                                  : overrideReadOnlyForGroupDocuments &&
                                    !fileDownloadLoading
                                  ? "88%"
                                  : overrideReadOnlyForGroupDocuments &&
                                    fileDownloadLoading
                                  ? "90%"
                                  : readOnlyMode
                                  ? "100%"
                                  : "93%"
                            }}
                            title={endorsement.groupAttachedDocumentName}
                            className="flush-margin-bottom"
                          >
                            <Icon type="paper-clip" />{" "}
                            {endorsement.groupAttachedDocumentName}
                          </p>
                          {((!readOnlyMode ||
                            overrideReadOnlyForGroupDocuments) && (
                            <>
                              {(!!endorsement.attachedDocumentId &&
                                !!endorsement.attachedDocumentName &&
                                !!!endorsement.groupAttachedDocument &&
                                !fileDownloadLoading && (
                                  <Tooltip
                                    placement="topRight"
                                    title={
                                      CERTIFICIAL_LABEL.WIDGETS
                                        .POLICY_ENDORSMENT
                                        .DOWNLOAD_ATTACHED_POLICY_ENDORSEMENT_DOCUMENT
                                    }
                                    arrowPointAtCenter
                                  >
                                    <Icon
                                      type="download"
                                      style={{
                                        color: "#666464",
                                        position: " absolute",
                                        right:
                                          (!!endorsement.attachedDocumentId &&
                                            40) ||
                                          25,
                                        top: 5,
                                        fontSize: "inherit"
                                      }}
                                      onClick={() => {
                                        if (!isDisabledDueToArchive) {
                                          onEndorsementDocumentDownload(
                                            endorsement
                                          )
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                )) ||
                                null}
                              {(endorsement.groupAttachedDocumentName && (
                                <Tooltip
                                  placement="topRight"
                                  title={
                                    CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                                      .SHOW_ENDORSMENT_DOCUMENT
                                  }
                                  arrowPointAtCenter
                                >
                                  <Icon
                                    type="eye"
                                    style={{
                                      color: "#3596db",
                                      position: " absolute",
                                      right: 20,
                                      top: 5,
                                      fontSize: "inherit"
                                    }}
                                    onClick={() => {
                                      if (!isDisabledDueToArchive) {
                                        openDrawerForEndorsementGrouping(
                                          endorsement
                                        )
                                      }
                                    }}
                                  />
                                </Tooltip>
                              )) ||
                                null}

                              <Tooltip
                                placement="topRight"
                                title={
                                  !readOnlyMode
                                    ? endorsement.applied
                                      ? CERTIFICIAL_LABEL.WIDGETS
                                          .POLICY_ENDORSMENT
                                          .DELETE_ENDORSEMENT_DOCUMENT
                                      : CERTIFICIAL_LABEL.WIDGETS
                                          .POLICY_ENDORSMENT
                                          .SELECT_ENDORSEMENT_TO_DELETE_DOCUMENT
                                    : null
                                }
                                arrowPointAtCenter
                              >
                                <Icon
                                  type="delete"
                                  style={{
                                    color: "orangered",
                                    position: " absolute",
                                    right: 0,
                                    top: 5,
                                    fontSize: "inherit"
                                  }}
                                  onClick={() => {
                                    if (!isDisabledDueToArchive) {
                                      onDeleteEndorsementGroupDocument(
                                        endorsement.id
                                      );
                                    }
                                  }}
                                />
                              </Tooltip>
                            </>
                          )) ||
                            null}
                        </Col>
                      )) ||
                    null}

                  {(!readOnlyMode &&
                     !isbulkShare &&
                    shouldEndorsementDocumentUpload &&
                    ((agentUploadingDocument && endorsement.isOneTime) ||
                      agentPolicyApprovalUploadingDocument) && (
                      <Col span={14}>
                        <div
                          style={{
                            width: "88%",
                            margin: 0
                          }}
                        > 
                          <UploadEndorsmentDocument
                            isNewPolicy={isNewPolicy}
                            isDisabledDueToArchive={isDisabledDueToArchive}
                            isOtherAgent={isOtherAgent}
                            title={title}
                            endorsement={endorsement}
                            onUpload={onEndorsementDocumentUpload}
                            fileList={endorsement.attachedDocument}
                            onDownload={onEndorsementDocumentDownload}
                            fileDownloadLoading={
                              fileDownloadLoading || endorsmentSaveActionLoading
                            }
                            overrideAgentTooltipFlag={overrideAgentTooltipFlag}
                            shouldEndorsementDocumentUpload={
                              shouldEndorsementDocumentUpload
                            }
                          />
                        </div>
                      </Col>
                    )) ||
                    null}

                    {(!readOnlyMode &&
                    isbulkShare &&
                    shouldEndorsementDocumentUpload &&
                    ((agentUploadingDocument && endorsement.isOneTime) ||
                      agentPolicyApprovalUploadingDocument) && (
                      <Col span={14}>
                        <div
                          style={{
                            width: "88%",
                            margin: 0
                          }}
                        > 
                          <UploadEndorsmentDocument
                            isNewPolicy={isNewPolicy}
                            isbulkShare={isbulkShare}
                            isDisabledDueToArchive={isDisabledDueToArchive}
                            isOtherAgent={isOtherAgent}
                            title={title}
                            endorsement={endorsement}
                            onUpload={onEndorsementDocumentUpload}
                            fileList={endorsement.attachedDocument}
                            onDownload={onEndorsementDocumentDownload}
                            fileDownloadLoading={
                              fileDownloadLoading || endorsmentSaveActionLoading
                            }
                            overrideAgentTooltipFlag={overrideAgentTooltipFlag}
                            shouldEndorsementDocumentUpload={
                              shouldEndorsementDocumentUpload
                            }
                          />
                        </div>
                      </Col>
                    )) ||
                    null}

                  {(( (shouldEndorsementDocumentGroupUpload &&
                    (!isReresponded ||
                      (isReresponded && !endorsement.attachedDocumentName)) &&
                    (!readOnlyMode ||
                      (readOnlyMode &&
                        !!endorsement.applied &&
                        !agentClientCoverageTemplate)) &&
                    !endorsement.groupAttachedDocumentName &&
                    !!!endorsement.isAgentDoc) && (!isPolicyToBeReplaced)) && (
                      <Col span={12} style={{ display: 'flex', justifyContent: "flex-end" }}>
                        {endorsement.isEndorsementDocumentRequired ? <div style={{ fontSize: 12, fontWeight: 500, color: 'red', marginRight: 25}}>
                        Doc Required
                        </div> : null}
                      <PolicyEndorsementGroupUpload
                        source={source}
                        isPolicyToBeReplaced={isPolicyToBeReplaced}
                        endorsement={endorsement}
                        openDrawerForEndorsementGrouping={
                          openDrawerForEndorsementGrouping
                        }
                        overrideAgentTooltipFlag={overrideAgentTooltipFlag}
                        readOnlyMode={readOnlyMode}
                        accessingModule={accessingModule}
                        policy = {policy}
                      />
                      </Col>
                    )) ||
                    null}

                  {((readOnlyMode || handlingException) &&
                    endorsement.applied &&
                    endorsement.missingEndorsementDocument && (
                      <Col
                        span={12}
                        className={
                          handlingException ? "handling-exception" : ""
                        }
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          color: "#f30b17",
                          textOverflow: "ellipsis"
                        }}
                      >
                        <Icon
                          type="close-circle"
                          style={{
                            marginRight: 8
                          }}
                        />
                        {
                          CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                            .MISSING_ENDORSEMENT_DOCUMENT
                        }
                      </Col>
                    )) ||
                    null}
                  {((readOnlyMode || handlingException) &&
                    endorsement.applied &&
                    !endorsement.missingEndorsementDocument &&
                    endorsement.isDocumentRequiresReview && (
                      <Col
                        span={15}
                        className={handlingException ? "review-exception" : ""}
                      >
                        <ReviewEndorsementExceptionManagement
                          endorsement={endorsement}
                          {...exceptionHandlingReviewProps}
                        />
                      </Col>
                    )) ||
                    null}
                </Row>

                {(typeof endorsement.missingEndorsementDocument !== "boolean" &&
                  standardLanguage &&
                  (overrideAppliedFlag || endorsement.applied) &&
                  ((isStandardLanguageEditable && (
                    <div className={["endorsement-standard-text"].join(" ")}>
                      <Paragraph
                        disabled={isDisabledDueToArchive}
                        ellipsis={{
                          rows: 1,
                          expandable: true,
                          onExpand: () => {}
                        }}
                        copyable={!readOnlyMode}
                        editable={
                          isOtherAgent
                            ? false
                            : isStandardLanguageEditable
                            ? {
                                editing: isStandardLanguageEditable,
                                onChange: (endorsementText) => {
                                  onStandardLanguageChange &&
                                    onStandardLanguageChange(
                                      endorsementText,
                                      endorsement
                                    );
                                },
                                disabled: true
                              }
                            : false
                        }
                        type="secondary"
                      >
                        {endorsement.endorsementText}
                      </Paragraph>
                    </div>
                  )) ||
                    (endorsement.endorsementText &&
                      shouldShowEndorsementsOnSpecialEndorsements(
                        requestSpecialEndorsements
                      ) &&
                      !accessingModule && (
                        <div
                          className={[
                            "endorsement-standard-text",
                            "read-only"
                          ].join(" ")}
                        >
                          <Paragraph
                            ellipsis={{
                              rows: 1,
                              expandable: true,
                              onExpand: () => {}
                            }}
                            type="secondary"
                          >
                            {endorsement.endorsementText}
                          </Paragraph>
                        </div>
                      )))) ||
                  null}
              </div>
            );
          })}
      </div>
    );
  };

  const isSupplierView = (accessingRole == APPLICATION_USER_ROLES.REQUESTER && accessingModule == 'dashboard');
  return (
    ((!!policyEndorsementsUpdated?.length || !!policyEndorsementsCompliance?.length) || isSupplierView)? (<ErrorBoundary>
      <Card
        size="small"
        bordered={bordered}
        className="widget-card"
        loading={loading}
        title={
          (isTitleRequired && (
            <h4>
              <Avatar
                src={
                  title &&
                  title ===
                    CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                      .CLIENT_SELF_MANAGEMENT_TITLE
                    ? clientSettingsIcon
                    : bookmarkIcon
                }
                size="small"
                shape="square"
                className="margin-right-sm"
              />
              {(title && title) || "Endorsements"}
              {!_isDocRequiresReview && _isDocRequiresReview !== null ? (
                <CertInfoTooltip
                  placement="topLeft"
                  title={
                    APPLICATION_MESSAGES.COMPLIANCE_ISSUE_INFO
                      .MISSING_ENDORSEMENT_DOCUMENT
                  }
                  className="compliance-issue-info"
                />
              ) : null}
              {(policyEndorsementsCompliance &&
                policyEndorsementsCompliance.length && (
                  <Popover
                    placement="rightTop"
                    title={text}
                    overlayClassName="compliance-container"
                    destroyTooltipOnHide={true}
                    content={generateView(policyEndorsementsCompliance)}
                    trigger="hover"
                  >
                    <Tag
                      color={
                        source == "RespondSummaryList" &&
                        isEndorsementDocumentCompliance
                          ? "#f30b17"
                          : source == "RespondSummaryList" &&
                            isEndorsementDocumentRequiresReview
                          ? "#f5a505"
                          : _isDocRequiresReview
                          ? "#f5a505"
                          : "#f30b17"
                      }
                      style={{ float: "right" }}
                    >
                      {source == "RespondSummaryList"
                        ? isEndorsementDocumentCompliance
                          ? CERTIFICIAL_STATUS.POLICY_STATUS
                              .HYPHEN_NON_COMPLIANT
                          : isEndorsementDocumentRequiresReview
                          ? CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW
                          : CERTIFICIAL_STATUS.POLICY_STATUS
                              .HYPHEN_NON_COMPLIANT
                        : _isDocRequiresReview
                        ? accessingRole !== APPLICATION_USER_ROLES.REQUESTER
                          ? CERTIFICIAL_STATUS.POLICY_STATUS.IN_REVIEW
                          : CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW
                        : CERTIFICIAL_STATUS.POLICY_STATUS.HYPHEN_NON_COMPLIANT}
                    </Tag>
                  </Popover>
                )) ||
                null}
            </h4>
          )) ||
          null
        }
        extra={
          ((fileDownloadLoading ||
            (title && title === endorsmentSaveActionLoading)) && (
            <Icon
              type="loading-3-quarters"
              className="color-primary fs-18x"
              spin
            />
          )) ||
          (title &&
            (title ===
              CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                .ENDORSEMENT_DOCUMENT_TITLE ||
              title ===
                CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                  .CLIENT_SELF_MANAGEMENT_TITLE) &&
            policyEndorsementsUpdated &&
            policyEndorsementsUpdated.length > 0 && (
              <Tooltip
                arrowPointAtCenter
                title={isOtherAgent ? null : "Save and Verify"}
                placement="topRight"
              >
                <Button
                  style={
                    !isOtherAgent
                      ? {
                          marginRight: 4,
                          float: "right",
                          padding: "0px 9px",
                          height: 25,
                          fontSize: 12
                        }
                      : { display: "none" }
                  }
                  type="primary"
                  size="small"
                  disabled={
                    isDisabledDueToArchive ||
                    isOtherAgent ||
                    endorsmentSaveActionLoading
                  }
                  onClick={() => endorsementSaveAgent(title)}
                >
                  Save
                </Button>
              </Tooltip>
            )) ||
          null
        }
      >
        {(policyEndorsementsUpdated &&
          policyEndorsementsUpdated.length &&
          generateView(policyEndorsementsUpdated)) || (isSupplierView ? (
            <Empty
              image={emptyImage}
              imageStyle={{
                height: 36
              }}
              description={
                <span>
                  {customEmptyMessage
                    ? customEmptyMessage
                    : CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                        .EMPTY_POLICY_ENDORSMENT}
                </span>
              }
            />
          ):null)}
      </Card>
    </ErrorBoundary>) : null
  );
};

class UploadEndorsmentDocument extends React.Component {
  render() {
    const {
      isDisabledDueToArchive,
      title,
      fileList,
      endorsement,
      onUpload,
      onDownload,
      fileDownloadLoading,
      overrideAgentTooltipFlag,
      shouldEndorsementDocumentUpload,
      isOtherAgent,
      isbulkShare,
      isNewPolicy
    } = this.props;
    const uploadDocumentProps = {
      onRemove: (file) => {
        _modalService.confirm({
          title: (
            <h4 className="margin-0">{endorsement.attachedDocumentName}</h4>
          ),
          content: [
            "Do you want to delete document for",
            endorsement.name,
            "?"
          ].join(" "),
          icon: "warning",
          okButtonProps: {
            type: "danger"
          },
          okText: "Delete",
          onOk() {
            onUpload(
              {
                ...endorsement,
                uploadType: endorsement.documentId ? "Removed" : null
              },
              {}
            );
          },
          onCancel() {}
        });
      },
      onDownload: () => {
        onDownload(endorsement);
      },
      beforeUpload: (file) => {
        if (!fileList || fileList.length < 1) {
          onUpload(
            {
              ...endorsement,
              uploadType:
                !endorsement.attachedDocumentName &&
                !!file.name &&
                endorsement.documentId &&
                endorsement.uploadType === "Removed"
                  ? "Update"
                  : !endorsement.attachedDocumentName && !!file.name
                  ? "NEW"
                  : null
            },
            file
          );
        } else {
          message.warn("Only One document is allowed.");
        }

        return false;
      },
      fileList
    };

    const shouldUploadButtonDisabled = ()=>{
      if(isbulkShare){
        return !isNewPolicy ? true : !endorsement.selected;
      }

      return (isDisabledDueToArchive ||
      (!endorsement.applied && !shouldEndorsementDocumentUpload) ||
        fileDownloadLoading ||
        isOtherAgent);

    };

    const getShowUploadListParams = ()=>{
      if(isbulkShare){
        return {
          showDownloadIcon:isNewPolicy,
          showDownloadIcon: !fileDownloadLoading,
          showRemoveIcon: !fileDownloadLoading && isNewPolicy
        }
      }
      return {
        showDownloadIcon: !fileDownloadLoading,
        showRemoveIcon: !fileDownloadLoading
      }
    }


    return (
      <div className="endorsment-document">
        <Upload
          {...uploadDocumentProps}
          iconRender
          accept={CERTIFICIAL.UPLOAD_FILES.ALL_SUPPORTED_FILE_EXTENTIONS}
          disabled={ shouldUploadButtonDisabled()}
          showUploadList={getShowUploadListParams()}
        >
        {(isbulkShare && isNewPolicy || !isbulkShare) &&  <Tooltip
            title={
              overrideAgentTooltipFlag || isOtherAgent
                ? null
                : title ===
                  CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                    .ENDORSEMENT_DOCUMENT_TITLE
                ? CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                    .CLICK_ENDORSEMENT_TO_UPLOAD_DOCUMENT
                : !!!endorsement.applied &&
                  CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                    .SELECT_ENDORSEMENT_TO_UPLOAD_DOCUMENT
            }
            arrowPointAtCenter
            placement="topRight"
          >
           <Button
              type={"primary"}
              style={
                !isOtherAgent
                  ? {
                      position: "absolute",
                      top:
                        uploadDocumentProps.fileList &&
                        uploadDocumentProps.fileList.length
                          ? 0
                          : "-12px",
                      right: 0
                    }
                  : { display: "none" }
              }
              disabled={shouldUploadButtonDisabled()}
              shape="circle"
              icon="cloud-upload"
              size="small"
            />
          </Tooltip>}
        </Upload>
      </div>
    );
  }
}
