import React, { useState } from "react";
import { Select } from "antd";
import {debounce} from 'lodash';
import { addressSearch } from "../../Store/Modules/Application/Application.asyncRequest";
import {CERTIFICIAL} from '../../Utils/Constants/Application.constants'
const AddressSearch = ({
	onChangeHandler,
	defaultValue,
	secretApiKey,
	onClearSearch,
	fieldSize
}) => {
	const [addressResult, setAddressSearchResult] = useState([]);
	const [isLoading, setLoding] = useState(false);
	const searchAddress = async (searchString = '') => {
		setLoding(true)
		if (searchString.length >= 3 && secretApiKey !== '') {
			const searchResult = await addressSearch(searchString, secretApiKey);
			setAddressSearchResult(searchResult);
			setLoding(false);
		}
	}
	
	const onChangeAction = (value) => {
		if (value !== undefined) {
			const selectedAddress = addressResult.filter(item => item.key === parseInt(value));
			const address1 = selectedAddress[0].street_line;// + " " + selectedAddress[0].city + " " + selectedAddress[0].state + " " + selectedAddress[0].zipcode;
			onChangeHandler({ ...selectedAddress[0], address1: address1 });
		} else {
			onClearSearch();
			setAddressSearchResult([]);
		}
	}


	return (
		<>
			<Select
				showSearch
				allowClear={true}
				defaultValue={defaultValue === ""? undefined:defaultValue}
				loading = {isLoading}
				value={defaultValue === ""? undefined:defaultValue}
				defaultActiveFirstOption={false}
				showArrow={false}
				filterOption={false}
				onSearch={debounce(searchAddress, CERTIFICIAL.DEBOUNCE_DELAY)}
				onChange={(e) => {
					onChangeAction(e);
				}}
				notFoundContent={isLoading?"Searching address": "Please start typing to search the address"}
				//placeholder={"Search Address"}
				size={fieldSize || "default"}
				placeholder={"Address 1"}
			>
				{addressResult.map((d, key) => {
					const concatString = [d.street_line, d.secondary, d.city, d.state, d.zipcode];
					const value = concatString.filter(item=>item !== undefined && item !== "").join(", ");
					return (<Select.Option key={d.key}>{value}</Select.Option>)
				})}
			</Select>
		</>
	)
}

export default AddressSearch;