import { Avatar, Icon, Tooltip } from "antd";
import React from "react";
import { notificationIcon } from "../../Assets/assets";
import { ENDPOINTS } from "../../REST/API.endpoints";
import { API_METHODS } from "../../Utils/Constants/API.constants";
import { _authService } from "../../Utils/Services/AuthenticationService";
import WebWorker from "../../WebWorker";

let w;
export class NotificationBell extends React.Component {
  notificationCounter;
  bufferCounter;
  state = {
    totalNotificationsCount: undefined,
    unreadNotificationCount: undefined
  };
  runNotificationWorker = (callBack) => {
    if (
      typeof Worker !== "undefined" &&
      _authService.getUserInfo() &&
      _authService.getUserInfo().userId
    ) {
      if (typeof w == "undefined") {
        w = new WebWorker();
      }
      /*
      // Disabled GET_NOTIFICATION call on every tab change CER-31349
      w.postMessage({
        type: API_METHODS.GET,
        url: ENDPOINTS.APPLICATION.GET_NOTIFICATION,
        userId: _authService.getUserInfo().userId,
        authToken: ["Bearer", _authService.getUserToken()].join(" ")
      });
      */
      this.notificationCounter = setInterval(() => {
        if (!!_authService.getUserInfo()) {
          w.postMessage({
            type: API_METHODS.GET,
            url: ENDPOINTS.APPLICATION.GET_NOTIFICATION,
            userId: _authService.getUserInfo().userId,
            authToken: ["Bearer", _authService.getUserToken()].join(" ")
          });
        }
      }, 30000);

      w.onmessage = function (event) {
        callBack(event.data);
      };
    } else {
    }
  };
  componentDidMount() {
    this.runNotificationWorker(this.setNotification);
    const that = this;
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        that.runNotificationWorker(that.setNotification);
      } else {
        that.notificationCounter && clearInterval(that.notificationCounter);
        that.bufferCounter && clearTimeout(that.bufferCounter);
      }
    });
  }
  componentWillUnmount() {
    this.notificationCounter && clearInterval(this.notificationCounter);
    this.bufferCounter && clearTimeout(this.bufferCounter);
  }
  setNotification = (notification) => {
    const {
      status,
      totalNotificationsCount,
      unreadNotificationCount,
      headerInfo,
      error
    } = notification;

    if (
      status !== "error" &&
      !!totalNotificationsCount &&
      this.state.unreadNotificationCount !== unreadNotificationCount
    ) {
      this.setState(
        (prev) => ({
          ...prev,
          totalNotificationsCount,
          unreadNotificationCount
        }),
        () => {
          if (this.bufferCounter) {
            clearTimeout(this.bufferCounter);
          }
        }
      );
    } else if (status !== "error" && headerInfo) {
      let arr = headerInfo.trim().split(/[\r\n]+/);
      let headerMap = {};
      arr.forEach(function (line) {
        let parts = line.split(": ");
        let header = parts.shift();
        let value = parts.join(": ");
        headerMap[header] = value;
      });
      const refreshedToken = headerMap["jwt_refreshed"];
      if (!!refreshedToken) {
        _authService.setUserToken(refreshedToken);
      }
    } else if (status === "error") {
      clearInterval(this.notificationCounter);
      if (error && error.status !== 403) {
        const that = this;
        this.bufferCounter = setTimeout(() => {
          that.runNotificationWorker(that.setNotification);
        }, 50000);
      }
    }
  };

  render() {
    return (
      <Tooltip
        placement="bottomRight"
        title={
          this.state.unreadNotificationCount === 0
            ? " No new notifications"
            : ""
        }
      >
            <Icon className="notification-section" type="bell" onClick={this.props.onClick}></Icon>
      </Tooltip>
    );
  }
}

export default NotificationBell;
