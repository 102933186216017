import { Badge, Tag } from "antd";
import _ from "lodash";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  CERTIFICIAL,
  CERTIFICIAL_STATUS
} from "../../../Utils/Constants/Application.constants";
import {
  titleMaintainer,
  _getStatusColor
} from "../../../Utils/utility/app.utility";

export const PolicyStatusTag = memo(function PolicyStatusTag({
  status,
  width,
  className,
  source,
  style,
  onmouseover,
  onmouseleave,
  mode,
  sameDomainOpenRequest 
}) {

  const { selectedCompany} = (useSelector(
    (state) => state.requestorDashboard
  ) || {});

  const { activeProject } = (useSelector(
    (state) => state?.pendingInsuredRequests
  ) || {});

  const reShareData = useSelector((state) => state.insurerSharedPolicies) || {};

  const isEvidenceRequest = (source == CERTIFICIAL.REQUESTOR_VIEW.DASHBOARD) ? selectedCompany?.isEvidenceOnly : activeProject?.isEvidenceOnly || reShareData?.selectedCompany?.isEvidenceOnly;
  if (!status || (status && !status.trim())) return null;

  
  const incomingStatus = ([CERTIFICIAL.SHARE_VIEW.POLICY_SHARE_SUMMARY, CERTIFICIAL.SHARE_VIEW.POLICY_RESPOND_SUMMARY].includes(source) && (status == CERTIFICIAL_STATUS.POLICY_STATUS.IN_REVIEW)) ? CERTIFICIAL_STATUS.POLICY_STATUS.VERIFIED : _.startCase(_.toLower(status));
  const _loweredCaseIncomingStatus = incomingStatus.toLowerCase();

  const modifiedStatus =
    (source === CERTIFICIAL.SHARE_VIEW.DASHBOARD ||
      source === CERTIFICIAL.AGENT_VIEW.DASHBOARD ||
      source === CERTIFICIAL.AGENT_VIEW.AGENT_POLICY_LIST ||
      source === CERTIFICIAL.SHARE_VIEW.MY_POLICY_LIST ||
      source === CERTIFICIAL.REQUESTOR_VIEW.DASHBOARD ||
      source === CERTIFICIAL.SHARE_VIEW.TEMPLATE_POLICY_LIST) &&
    _loweredCaseIncomingStatus === "rejected"
      ? "Inactive"
      : (source === CERTIFICIAL.SHARE_VIEW.DASHBOARD ||
          source === CERTIFICIAL.AGENT_VIEW.AGENT_POLICY_LIST ||
          source === CERTIFICIAL.SHARE_VIEW.MY_POLICY_LIST ||
          source === CERTIFICIAL.SHARE_VIEW.TEMPLATE_POLICY_LIST) &&
        _loweredCaseIncomingStatus === "in review"
      ? "Unverified"
      : (source === CERTIFICIAL.SHARE_VIEW.MY_POLICY_LIST ||
          source === CERTIFICIAL.SHARE_VIEW.TEMPLATE_POLICY_LIST ||
          source === CERTIFICIAL.SHARE_VIEW.POLICY_SHARE_SUMMARY ||
          source === CERTIFICIAL.AGENT_VIEW.AGENT_POLICY_LIST ||
          source === CERTIFICIAL.AGENT_VIEW.DASHBOARD ||
          source === CERTIFICIAL.AGENT_VIEW.AGENT_ISSUE_COI ||
          source === CERTIFICIAL.AGENT_VIEW.AGENT_PENDING_POLICY_SHARE ||
         (source === CERTIFICIAL.SHARE_VIEW.POLICY_RESPOND_SUMMARY && isEvidenceRequest))
           && 
        _loweredCaseIncomingStatus === "verified"
      ? "Active"
      : (source === CERTIFICIAL.SHARE_VIEW.POLICY_RESPOND_SUMMARY ||
          source === CERTIFICIAL.AGENT_VIEW.AGENT_PENDING_POLICY_REQUEST) &&
        _loweredCaseIncomingStatus === "verified"
      ? "Compliant"
      : (source === CERTIFICIAL.SHARE_VIEW.DASHBOARD ||
          source === CERTIFICIAL.SHARE_VIEW.POLICY_RESPOND_SUMMARY ||
          source === CERTIFICIAL.REQUESTOR_VIEW.DASHBOARD) &&
        _loweredCaseIncomingStatus === "compliance"
      ? "Non-Compliant"
      : (source === CERTIFICIAL.SHARE_VIEW.DASHBOARD ||
          source === CERTIFICIAL.REQUESTOR_VIEW.DASHBOARD) &&
        _loweredCaseIncomingStatus === "reviewed"
      ? "Compliant"
      : source === "PendingRequest" && (sameDomainOpenRequest || 
       ( _loweredCaseIncomingStatus !== "compliance" &&
        _loweredCaseIncomingStatus !== "processing" &&
        _loweredCaseIncomingStatus !== "non compliant"))
      ? "Action Required"
      : _loweredCaseIncomingStatus === "requires review"
      ? source === CERTIFICIAL.SHARE_VIEW.DASHBOARD ||
        _loweredCaseIncomingStatus === "in review"
        ? "In Review"
        : incomingStatus
      : _loweredCaseIncomingStatus === "non compliant"
      ? "Non-Compliant"
      : incomingStatus;

  let statusColor = _getStatusColor(sameDomainOpenRequest ? modifiedStatus : incomingStatus);

  if (incomingStatus === "Invited" && source === "companyAdmin") {
    statusColor = "#3596db";
  }

  return (
    (incomingStatus && mode === "Tag" ? (
      incomingStatus && (
        <Tag color={statusColor} key={modifiedStatus} style={{ ...style }}>
          {titleMaintainer(modifiedStatus, 20)}
        </Tag>
      )
    ) : (
      <>
        <h4
          style={{
            color: "#595959",
            fontSize: 11,
            marginRight: "10px",
            ...style
          }}
          onMouseOver={onmouseover}
          onMouseLeave={onmouseleave}
          className={(className && className) || ""}
          title={className !== "linkonhover" ? modifiedStatus : null}
        >
          <Badge
            className="certificial-badge"
            color={statusColor}
            status={
              (incomingStatus === CERTIFICIAL_STATUS.POLICY_STATUS.EXPIRING &&
                CERTIFICIAL_STATUS.VERIFICATION_STATUS.PROCESSING) ||
              "default"
            }
            offset={[4, 0]}
          />
          {titleMaintainer(modifiedStatus, 25)}
        </h4>
      </>
    )) || null
  );
});
